
import { lang } from '@/lang'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { RecordRawOption } from './types'

@Component({})
export default class RecordRaw extends Vue {
  @Prop({ type: Object, required: true })
  private item!: RecordRawOption

  @Prop(Boolean) private readonly!: boolean

  private get value () {
    return this.item.value
  }

  private set value (value) {
    this.$set(this.item, 'value', value)
  }

  private get placeholder () {
    if (!this.readonly && this.item.placeholder) {
      return lang(this.item.placeholder)
    }

    return lang('RECORD_INPUT_PLACEHOLDER')
  }

  private get link () {
    if (!this.item.value) return ''

    // 以太坊地址用于email的正则表达式会死循环
    if (this.item.id === 'eth') {
      return ''
    }

    // 处理url类型
    if (['notice', 'twitter', 'github', 'url', 'avatar', 'cname'].includes(this.item.id)) {
      if (this.item.value.endsWith('.dot')) return `/details/${this.item.value}`
      if (!this.item.value.startsWith('http')) return `https://${this.item.value}`
      return this.item.value
    }

    // 处理email类型
    if (this.item.id === 'email') return `mailto:${this.value}`

    return ''
  }
}
