const contractList = {
  1284: {
    pns: '0x7d5F0398549C9fDEa03BbDde388361827cb376D5',
    controller: '0xcD0771139fEE77f6a13E87C245Bc1Df92eAca1b6',
    priceOffController: '0x4EA48B8045889A7426CcD00b3968686010B4cc62'
  },
  31337: {
    pns: '0x5FC8d32690cc91D4c39d9d3abcBD16989F875707',
    controller: '0x0165878A594ca255338adfa4d48449f69242Eb8F',
    priceOffController: '0x0165878A594ca255338adfa4d48449f69242Eb8F'
  }
}

let contract: { pns: string, controller: string, priceOffController: string } = contractList[1284]

if (process.env.NODE_ENV === 'development') {
  contract = contractList[1284]
}

if (process.env.VUE_APP_BUILD_ENV === 'daospace') {
  contract = contractList[31337]
}

export const usdtCountract:Record<number, [string, number]> = {
  137: ['0xc2132D05D31c914a87C6611C10748AEb04B58e8F', 6],
  56: ['0x55d398326f99059fF775485246999027B3197955', 18],
  31337: ['0x04C89607413713Ec9775E14b954286519d836FEf', 18]
}

export const usdtAbi = [
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'recipient', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' }
    ],
    name: 'transfer',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  }
]

export default contract

export const comet = '0x4692dBe7F7487a6E2eaC30DB494Cc55441FEa79C'
