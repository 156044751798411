
import { Vue, Component } from 'vue-property-decorator'
import FrameHeaderMobile from './components/FrameHeaderMobile/Main.vue'

@Component({
  components: {
    FrameHeaderMobile
  }
})
export default class FrameBaseMobile extends Vue {

}
