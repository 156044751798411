import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element-ui'
import './components'
// import '@iconscout/unicons/css/line.css'
import { siteConfig } from './config/site'
import { lang } from './lang'
import { eventBus } from './utils/event'
import { successMessage } from './utils/message'
import './plugins/string-proto'

Vue.config.productionTip = false
Vue.prototype.$siteConfig = siteConfig
Vue.prototype.$lang = lang
Vue.prototype.$eventBus = eventBus
Vue.prototype.$success = successMessage

const vue = new Vue({
  router,
  render: h => h(App)
})

document.addEventListener('DOMContentLoaded', function () {
  vue.$mount('#app')
})
