
import { Vue, Component } from 'vue-property-decorator'
import FrameHeaderConsole from './components/FrameHeaderConsole/Main.vue'

@Component({
  components: {
    FrameHeaderConsole
  }
})
export default class FrameBaseConsole extends Vue {

}
