
import { copyText } from '@/utils/content'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class ClipboardInput extends Vue {
  @Prop(String) private value!: string
  @Prop(String) private placeholder!: string
  @Prop(Boolean) private readonly!: boolean
  @Prop(Boolean) private textarea!: boolean
  @Prop(Boolean) private copiable!: boolean
  @Prop(String) private link!: string
  @Prop({ type: [Boolean, Object], default: true })
  private autosize!: unknown

  private get computedValue () {
    return this.value || ''
  }

  private set computedValue (value) {
    this.$emit('input', value)
  }

  private handlerCopy () {
    if (this.value) {
      copyText(this.value)
    }
  }
}
