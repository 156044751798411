import ICON_ETH from '../views/Wrapper/assets/eth.svg'
import ICON_MOONBEAM from '../views/Wrapper/assets/glimmer.png'
import ICON_BNB from '../views/Wrapper/assets/bnb.svg'
import ICON_USDT from '../views/Wrapper/assets/usdt.png'
import ICON_BUSD from '../views/Wrapper/assets/busd.webp'
import ICON_MATIC from '../views/Wrapper/assets/matic.webp'

const paymentToken: Common.MenuItem<Common.Currentcy>[] = [
  { id: 'GLMR', title: 'GLMR', icon: ICON_MOONBEAM },
  { id: 'BNB', title: 'BNB', icon: ICON_BNB },
  { id: 'MATIC', title: 'MATIC', icon: ICON_MATIC },
  { id: 'USDT_BSC', title: 'USDT on BSC', icon: ICON_BUSD },
  { id: 'USDT_POLYGON', title: 'USDT on Polygon', icon: ICON_USDT }

  // { id: 'DOT', title: 'DOT' },
  // { id: 'KSM', title: 'KSM' }
]

export default paymentToken
