import Vue from 'vue'
import { checkIsMobile, observeDevice } from '@/utils/device'

export const siteConfig: Common.SiteConfig = Vue.observable({
  isMobile: checkIsMobile(),
  lang: process.env.VUE_APP_LANG as string
})

observeDevice((info) => {
  siteConfig.isMobile = info.isMobile

  if (info.isMobile) {
    document.body.classList.add('isMobile')
    document.body.classList.remove('isConsole')
  } else {
    document.body.classList.remove('isMobile')
    document.body.classList.add('isConsole')
  }
})
