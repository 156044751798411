
import router from '@/router'
import { Vue, Component } from 'vue-property-decorator'
import { getHomeMenu } from '../config/menu'
import { getMyNameMenu } from '../config/menu_my_name'
import { MenuGroup } from '../types'
import { isBanFeature } from '@/utils/common'
import MenuItem from '../components/MenuItem.vue'
import { wallet } from '@/utils/wallet'

@Component({
  components: {
    MenuItem
  }
})
export default class ModuleA extends Vue {
  private get connected () {
    return wallet.accountId !== ''
  }

  private HomeMenuList: MenuGroup[] = [{
    getMenu: getHomeMenu,
    checkIsSelected (item: Common.MenuItem) {
      return router.currentRoute.meta?.activeMenuId === item.id
    }
  }]

  private get show () {
    return !isBanFeature()
  }

  private MyNameMenuList: MenuGroup[] = [{
    getMenu: getMyNameMenu,
    checkIsSelected (item: Common.MenuItem) {
      return router.currentRoute.meta?.activeMenuId === item.id
    }
  }]

  private get isMyNamePage (): boolean {
    return this.$route.matched[1] && this.$route.matched[1].path === '/my-names'
  }

  private get currentHomeMenuGroup () {
    return this.HomeMenuList[this.HomeMenuList.length - 1]
  }

  private get currentMyNameMenuGroup () {
    return this.MyNameMenuList[this.MyNameMenuList.length - 1]
  }

  private get homeMenuConfig () {
    if (this.currentHomeMenuGroup) {
      return this.currentHomeMenuGroup.getMenu()
    }
    return []
  }

  private get myNameMenuConfig () {
    if (this.currentMyNameMenuGroup) {
      return this.currentMyNameMenuGroup.getMenu()
    }
    return []
  }

  private checkIsSelected (item: Common.MenuItem) {
    const group = this.isMyNamePage ? this.currentMyNameMenuGroup : this.currentHomeMenuGroup
    if (!group) return false
    if (group.checkIsSelected) {
      return group.checkIsSelected(item)
    }
    return group.currentSelected === item.id
  }
}
