
import { Vue, Component, Ref } from 'vue-property-decorator'
import ConnectWalletDialog from './components/ConnectWalletDialog/ConnectWalletDialog.vue'
import { EventBus, eventBus } from './utils/event'
import { wallet } from '@/utils/wallet'
import FrameBase from '@/views/FrameBase/Main.vue'
import { verifyDomain } from '@/sdk/verify'

@Component({
  components: {
    FrameBase
  }
})
export default class App extends Vue {
  @Ref() private connectWalletDialog!: ConnectWalletDialog

  private hidePage = true

  private openConnectWalletDialog () {
    this.connectWalletDialog.open()
  }

  private setReferrer () {
    let referrer = this.$route.query.referrer || this.$route.query.utm_source || ''
    referrer = (referrer as string).toLowerCase()
    if (referrer && !verifyDomain(referrer as string)) {
      referrer = referrer.replace('.dot', '') + '.dot'
      const now = Date.parse(new Date().toString())
      const duration = now + (1000 * 60 * 60 * 2) // 2 小时有效
      window.localStorage.setItem('referrer', JSON.stringify([(referrer as string).toLowerCase(), duration]))
    }
  }

  async mounted (): Promise<void> {
    document.dispatchEvent(new Event('render-event'))
    const win:any = window
    const appContainer: any = document.querySelector('.app-container')
    if (!win.__PRERENDER_INJECTED || !win.__PRERENDER_INJECTED.isPrerender) {
      appContainer.style.opacity = '1'
    }

    await wallet.autoConnect()
    eventBus.on(EventBus.Types.CONNECT_WALLET_DIALOG_OPEN, this.openConnectWalletDialog)
    this.$once('hook:beforeDestroy', () => {
      eventBus.off(EventBus.Types.CONNECT_WALLET_DIALOG_OPEN, this.openConnectWalletDialog)
    })

    setTimeout(() => {
      this.setReferrer()
    }, 1000)
  }
}
