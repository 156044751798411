import sdk from './pnsSdk'
import api from '@/config/api'
import fetch1 from '@/utils/fetch'
import { wallet } from '@/utils/wallet'
import { isCometUser } from '@/sdk/lib/sdk'

// glmr 注册费上浮
const GLMR_REGISTRATION_FEE_GAP = 1.02

/**
 * 获得注册/续费费用
 * @param domain
 * @param isRenew 是否是查询续费价格
 * @param substrateAddress 支付的dot/ksm地址
 * @param lock 是否锁定，注册前需锁定价格
 * @param years 注册年限
 */
export async function getFee (domain: string, isRenew: boolean, years = 1, substrateAddress?: string, lock?: boolean): Promise<Record<string, Common.Fee>> {
  let currencyRate
  if (!lock) {
    const rate = await sdk.getTokenPrice()
    currencyRate = rate.toNumber() / 100000000
  } else {
    currencyRate = 0
  }
  const id = wallet.accountId || ''
  const isComet = await isCometUser('')
  const [basePricesComet, rentPriceComet] = await sdk.getPrices(isComet, id)
  const [basePrices, rentPrice] = await sdk.getPrices(false, id)
  console.log('[basePrices]: ', basePrices)
  console.log('[rentPrice]: ', rentPrice)
  const domainLength = domain.domainEllipsisSuffix().length
  const index = domainLength < basePrices.length ? domainLength - 1 : basePrices.length - 1
  const usdtRentPrice = rentPrice[index].toNumber() * years
  const usdtBasePrices = isRenew ? 0 : basePrices[index].toNumber()

  const {
    dotUsdPrice,
    ksmUsdPrice,
    bnbUsdPrice,
    maticUsdPrice,
    usdtUsdPrice,
    dotTotal,
    ksmTotal,
    glmrTotal,
    bnbTotal,
    maticTotal,
    usdtTotal
  } = await queryPrice(domain, years, isRenew, substrateAddress, lock)
  const res = {
    glmr: {
      deposit: '0',
      depositUsd: '0',
      registrationFee: isRenew ? '0' : (basePricesComet[index].toNumber() * GLMR_REGISTRATION_FEE_GAP) / currencyRate + '',
      annualFee: (rentPriceComet[index].mul(years).toNumber() * GLMR_REGISTRATION_FEE_GAP) / currencyRate + '',
      currencyRate,
      totalCurrencyAmount: (basePricesComet[index].toNumber() / currencyRate * GLMR_REGISTRATION_FEE_GAP) + (rentPriceComet[index].toNumber() / currencyRate * 1.02) + ''
    },
    dot: {
      deposit: '0',
      depositUsd: '0',
      registrationFee: isRenew ? '0' : basePrices[index].toNumber() / Number(dotUsdPrice) + '',
      annualFee: rentPrice[index].mul(years).toNumber() / Number(dotUsdPrice) + '',
      currencyRate: Number(dotUsdPrice),
      totalCurrencyAmount: dotTotal
    },
    ksm: {
      deposit: '0',
      depositUsd: '0',
      registrationFee: isRenew ? '0' : basePrices[index].toNumber() / Number(ksmUsdPrice) + '',
      annualFee: rentPrice[index].mul(years).toNumber() / Number(ksmUsdPrice) + '',
      currencyRate: Number(ksmUsdPrice),
      totalCurrencyAmount: ksmTotal
    },
    glmrOffChain: {
      deposit: '0',
      depositUsd: '0',
      registrationFee: '0',
      annualFee: '0',
      currencyRate: 0,
      totalCurrencyAmount: glmrTotal
    },
    bnb: {
      deposit: '0',
      depositUsd: '0',
      registrationFee: isRenew ? '0' : basePrices[index].toNumber() / Number(bnbUsdPrice) + '',
      annualFee: rentPrice[index].mul(years).toNumber() / Number(bnbUsdPrice) + '',
      currencyRate: Number(bnbUsdPrice),
      totalCurrencyAmount: bnbTotal
    },
    matic: {
      deposit: '0',
      depositUsd: '0',
      registrationFee: isRenew ? '0' : basePrices[index].toNumber() / Number(maticUsdPrice) + '',
      annualFee: rentPrice[index].mul(years).toNumber() / Number(maticUsdPrice) + '',
      currencyRate: Number(maticUsdPrice),
      totalCurrencyAmount: maticTotal
    },
    usdt_bsc: {
      deposit: '0',
      depositUsd: '0',
      registrationFee: isRenew ? '0' : usdtBasePrices + '',
      annualFee: usdtRentPrice + '',
      currencyRate: 1,
      totalCurrencyAmount: usdtRentPrice + usdtBasePrices + ''
    },
    usdt_polygon: {
      deposit: '0',
      depositUsd: '0',
      registrationFee: isRenew ? '0' : usdtBasePrices + '',
      annualFee: usdtRentPrice + '',
      currencyRate: 1,
      totalCurrencyAmount: usdtRentPrice + usdtBasePrices + ''
    }
  }
  console.log(res)
  return res
}

interface SubstratePrice {
  dotUsdPrice: string
  ksmUsdPrice: string
  glmrUsdPrice: string
  ksmTotal: string
  dotTotal: string
  glmrTotal: string
  bnbUsdPrice: string
  bnbTotal: string
  maticUsdPrice: string
  maticTotal: string
  usdtUsdPrice: string
  usdtTotal: string
}
export async function queryPrice (domain: string, years:number, isRenew: boolean, substrateAddress?: string, lock?: boolean): Promise<SubstratePrice> {
  const data: any = {
    network: 'mainnet',
    address: substrateAddress,
    domain: domain,
    year: years.toString(),
    pay_type: isRenew ? 'renew' : 'pay'
  }

  if (lock) {
    data.lock = lock
  }

  // const getPrice = await fetch(`${api}/pns_api/domains/registration_fee`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify(data)
  // })

  const getPrice = await fetch1.post(`${api}/pns_api/domains/registration_fee`, data, !!lock)

  const resJons = getPrice.data
  console.log('resJons', resJons)
  return {
    dotUsdPrice: resJons.data.dot_price,
    ksmUsdPrice: resJons.data.ksm_price,
    dotTotal: resJons.data.dot_required_quantity,
    ksmTotal: resJons.data.ksm_required_quantity,
    glmrUsdPrice: resJons.data.glmr_price,
    glmrTotal: resJons.data.glmr_required_quantity,
    bnbUsdPrice: resJons.data.bnb_price,
    bnbTotal: resJons.data.bnb_required_quantity,
    maticUsdPrice: resJons.data.matic_price,
    maticTotal: resJons.data.matic_required_quantity,
    usdtUsdPrice: resJons.data.usdt_price,
    usdtTotal: resJons.data.usdt_required_quantity
  }
}

export async function getAcceptor (pay: string): Promise<string> {
  // const res = await fetch(`${api}/pns_api/domains/pns_wallet_addr`, {
  //   method: 'POST',
  //   body: JSON.stringify({ pay }),
  //   headers: {
  //     'Content-Type': 'application/json'
  //   }
  // })

  const res = await fetch1.post(`${api}/pns_api/domains/pns_wallet_addr`, { pay })

  const resJons = res.data
  console.log('Receiver', resJons)
  return resJons.address
}
