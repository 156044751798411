
import { Vue, Component, Prop } from 'vue-property-decorator'
import { RecordPartOption } from './types'
import RecordPart from './RecordPart.vue'
import { getVerifyInfo } from '@/utils/useVerify'
@Component({
  components: {
    RecordPart
  }
})
export default class RecordForm extends Vue {
  @Prop({ type: Object })
  private model!: Common.DomainRecord

  @Prop(Boolean) private readonly!: boolean

  private data () {
    return {
      list: [
        {
          title: 'RECORD_FORM_ADDRESS',
          raw: [
            {
              id: 'dot',
              label: 'RECORD_FORM_ADDRESS_DOT',
              placeholder: 'RECORD_INPUT_PLACEHOLDER_DOT',
              value: this.model?.address.dot || ''
            },
            /* {
              id: 'ksm',
              label: 'RECORD_FORM_ADDRESS_KSM',
              placeholder: 'RECORD_INPUT_PLACEHOLDER_KSM',
              value: this.model?.address.ksm || ''
            }, */
            {
              id: 'eth',
              label: 'RECORD_FORM_ADDRESS_ETH',
              placeholder: 'RECORD_INPUT_PLACEHOLDER_ETH',
              value: this.model?.address.eth || ''
            },
            {
              id: 'btc',
              label: 'RECORD_FORM_ADDRESS_BTC',
              placeholder: 'RECORD_INPUT_PLACEHOLDER_BTC',
              value: this.model?.address.btc || ''
            }
          ]
        },
        {
          title: 'RECORD_FORM_CONTENT',
          raw: [
            {
              id: 'ipfs',
              label: 'RECORD_FORM_CONTENT_IPFS',
              placeholder: 'RECORD_INPUT_PLACEHOLDER_IPFS',
              value: this.model?.content.ipfs || '',
              autosize: { minRows: 3 }
            }
          ]
        },
        {
          title: 'RECORD_FORM_TEXT_RECORD',
          raw: [
            {
              id: 'email',
              label: 'RECORD_FORM_TEXT_RECORD_EMAIL',
              placeholder: 'RECORD_INPUT_PLACEHOLDER_EMAIL',
              value: this.model?.textRecord.email || ''
            },
            {
              id: 'notice',
              label: 'RECORD_FORM_TEXT_RECORD_NOTICE',
              placeholder: 'RECORD_INPUT_PLACEHOLDER_NOTICE',
              value: this.model?.textRecord.notice || ''
            },
            {
              id: 'twitter',
              label: 'RECORD_FORM_TEXT_RECORD_TWITTER',
              placeholder: 'RECORD_INPUT_PLACEHOLDER_TWITTER',
              value: this.model?.textRecord.twitter || ''
            },
            {
              id: 'github',
              label: 'RECORD_FORM_TEXT_RECORD_GITHUB',
              placeholder: 'RECORD_INPUT_PLACEHOLDER_GITHUB',
              value: this.model?.textRecord.github || ''
            },
            {
              id: 'url',
              label: 'RECORD_FORM_TEXT_RECORD_URL',
              placeholder: 'RECORD_INPUT_PLACEHOLDER_URL',
              value: this.model?.textRecord.url || ''
            },
            {
              id: 'avatar',
              label: 'RECORD_FORM_TEXT_RECORD_AVATAR',
              placeholder: 'RECORD_INPUT_PLACEHOLDER_AVATAR',
              value: this.model?.textRecord.avatar || ''
            }
          ]
        },
        {
          title: 'RECORD_FORM_TEXT_RECORD_CNAME',
          raw: [
            {
              id: 'cname',
              label: 'RECORD_FORM_TEXT_RECORD_CNAME',
              placeholder: 'RECORD_INPUT_PLACEHOLDER_CNAME',
              value: this.model?.cname.cname || ''
            }
          ]
        }
      ] as RecordPartOption[]
    }
  }

  private list!: RecordPartOption[]

  getModifyData (): Common.DomainRecord {
    const model: Common.DomainRecord = {
      address: { ...this.model.address },
      content: { ...this.model.content },
      textRecord: { ...this.model.textRecord },
      cname: { ...this.model.cname }
    }

    const compare = (obj: Record<string, string>, key: string) => {
      let part!: RecordPartOption
      switch (obj) {
        case model.address:
          part = this.list[0]
          break
        case model.content:
          part = this.list[1]
          break
        case model.textRecord:
          part = this.list[2]
          break
        case model.cname:
          part = this.list[3]
          break
      }
      const item = part.raw.find(v => v.id === key)
      if (item && item.value !== obj[key]) {
        obj[key] = item.value as string
      } else {
        obj[key] = '--no modify--'
      }
    }

    const compareObj = (obj: Record<string, string>) => {
      Object.keys(obj).forEach((key) => {
        compare(obj, key)
      })
    }

    compareObj(model.address)
    compareObj(model.content)
    compareObj(model.textRecord)
    compareObj(model.cname)

    return model
  }

  mounted (): void {
    getVerifyInfo(this.$route.params.domain)
  }
}
