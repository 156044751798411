
import { Vue, Component } from 'vue-property-decorator'
import { lang } from '@/lang'
import QRCodeItem from './QRCodeItem.vue'

@Component({
  components: {
    QRCodeItem
  }
})
export default class QRCodeDialog extends Vue {
  private visible = false
  private val = ''

  private get width () {
    if (this.$siteConfig.isMobile) {
      return 'calc(100% - 56px)'
    }

    return '286px'
  }

  private get text () {
    return {
      title: lang('QRCODE_TITLE')
    }
  }

  open (val: string): void {
    this.visible = true
    this.val = val
  }
}
