
import { lang } from '@/lang'
import { getFee, verifyDomain } from '@/sdk/domain'
import { wallet } from '@/utils/wallet'
import BN from 'bignumber.js'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { getDefaultChain, getNetworkByChainId } from '@/config/networks'
import { priceOffConfig } from '@/config/priceOff'
import { EventBus } from '@/utils/event'

@Component({})
export default class RegistForm extends Vue {
  @Prop({ type: String, required: true })
  private domain!: string

  @Prop({ type: Boolean })
  private isRenew!: boolean

  private fee!: null | Record<string, Common.Fee>

  private get text () {
    return {
      period: lang('RENEW_FORM_PERIOD'),
      periodUnit: lang('RENEW_FORM_PERIOD_UNIT'),
      periodTip: lang('RENEW_FORM_PERIOD_TIP'),
      price: lang('RENEW_FORM_PRICE'),
      total: lang('RENEW_FORM_TOTAL'),
      deposit: lang('RENEW_FORM_DEPOSIT'),
      annual: lang('RENEW_FORM_ANNUAL')
    }
  }

  public year = 1
  private gasFee = '0'
  private deposit = '0'
  private registrationPriceBase = '0'
  private registrationUsdPriceBase = '0'
  private annualPriceBase = '0'
  private annualUsdPriceBase = '0'
  public symbol = 'GLMR'

  private priceReady = false

  @Watch('symbol')
  private symbolChange (val: string, old: string) {
    this.getPrice()
    this.$emit('symbolChange', val)
  }

  @Watch('year')
  private yearChange (val: string) {
    this.getPrice()
  }

  private get formatSymbol () {
    return this.symbol.split('_')[0]
  }

  private get annualPrice () {
    return new BN(this.annualPriceBase).times(this.year).toFixed(7)
  }

  private get annualUsdPrice () {
    return new BN(this.annualUsdPriceBase).times(this.year).toFixed(7)
  }

  private get totalUsdPrice () {
    return new BN(this.annualUsdPrice).plus(this.registrationUsdPriceBase).toFixed(3)
  }

  private get totalPrice () {
    return new BN(this.annualPrice).plus(this.registrationPriceBase).toFixed(7)
  }

  private get registrationPrice () {
    return new BN(this.registrationPriceBase).toFixed(7)
  }

  private get registrationUsdPrice () {
    return new BN(this.registrationUsdPriceBase).toFixed(7)
  }

  private async getPrice () {
    if (verifyDomain(this.domain)) {
      this.$router.push(`/search?keyword=${this.domain}`)
    }

    if (!this.fee) {
      this.$emit('priceLoading')
      this.priceReady = false
      this.fee = await getFee(this.domain, this.isRenew, this.year)
    }
    this.annualPriceBase = this.fee[this.symbol.toLowerCase()].annualFee
    this.annualUsdPriceBase = new BN(this.fee[this.symbol.toLowerCase()].annualFee).times(this.fee[this.symbol.toLowerCase()].currencyRate).toString()
    this.registrationPriceBase = this.fee[this.symbol.toLowerCase()].registrationFee
    this.registrationUsdPriceBase = new BN(this.registrationPriceBase).times(this.fee[this.symbol.toLowerCase()].currencyRate).toString()
    this.$emit('priceReady')
    this.priceReady = true
  }

  private get priceOffShow () {
    return priceOffConfig.show && !this.isRenew
  }

  private get priceOffRate () {
    return priceOffConfig.offRate
  }

  private get priceOffRawPrice () {
    return new BN(this.registrationPriceBase).div(1 - priceOffConfig.offRate).toFixed(7)
  }

  private PriceOffcountdown () {
    const now = new Date()
    const targetDate = new Date(priceOffConfig.endTime)
    const leftTime = Date.parse(targetDate.toString()) - Date.parse(now.toString())
    priceOffConfig.show = leftTime > 0
  }

  private async getSymbol () {
    let currNetwork = getNetworkByChainId(await wallet.getChainId())
    if (!currNetwork) {
      currNetwork = getNetworkByChainId(getDefaultChain())
    }
    this.symbol = (currNetwork as Common.NetworkItem).nativeCurrency.symbol
    this.getPrice()
  }

  async mounted () : Promise<void> {
    await this.getPrice()
    // this.$eventBus.on(EventBus.Types.WALLET_SWITCH_CHAIN, this.getSymbol)
    // this.$once('hook:beforeDestroy', () => {
    //   this.$eventBus.off(EventBus.Types.WALLET_SWITCH_CHAIN, this.getSymbol)
    // })

    const time = setInterval(() => {
      this.PriceOffcountdown()
    }, 1000)

    // this.$once('hook:beforeDestroy', () => {
    //   clearInterval(time)
    // })
  }
}
