// 正式环境api
let api = 'https://api-rpc.pns.link'
let graph = 'https://pnsgraph-api.pns.link/graphql'

if (process.env.NODE_ENV === 'development') {
  api = 'https://api-rpc.pns.link'
  graph = 'https://pns-graph.ddns.so/subgraphs/name/graphprotocol/pns'
}

if (process.env.VUE_APP_BUILD_ENV === 'daospace') {
  api = 'https://pns-admin.daospace.one'
  graph = 'https://daospace.one/subgraphs/name/graphprotocol/pns'
}
export default api
export const graphNode = graph
