export type MsgType = 'info' | 'success' | 'warn' | 'error'

const useMessage = function (type: MsgType = 'info', msg: string, remain = false) {
  let messageList = document.getElementById('MessageList')
  if (!messageList) {
    messageList = document.createElement('div')
    messageList.setAttribute('id', 'MessageList')
    document.body.append(messageList)
  }

  const messageItem = document.createElement('div')
  messageItem.setAttribute('class', `app-message ${type}`)
  const textItem = document.createElement('div')
  textItem.setAttribute('class', 'text')
  textItem.innerText = msg

  const messageItemList = messageList.querySelectorAll('.app-message')
  messageItem.appendChild(textItem)
  messageList.append(messageItem)
  if (messageItemList.length) {
    messageItemList.forEach((item, index) => {
      (item as any).style.top = Number((item as any).style.top.replace('px', '')) + messageItem.clientHeight + 20 + 'px'
    })
  }
  setTimeout(() => {
    messageItem.setAttribute('class', `app-message active ${type}`)
    if (!remain) {
      setTimeout(() => {
        messageItem.setAttribute('class', `app-message ${type}`)
        setTimeout(() => {
          messageItem.remove()
        }, 500)
      }, 10000)
    }
  }, 100)

  return messageItem
}

export function cleanMessage (messageItem: HTMLDivElement, type: MsgType) {
  messageItem.setAttribute('class', `app-message ${type}`)
  setTimeout(() => {
    messageItem.remove()
  }, 500)
}

export default useMessage
