
import { lang } from '@/lang'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { RecordRawOption } from './types'
import { verifyAddress } from '@/sdk/domain'
import ClipboardInputEth from '@/components/ClipboardInput/ClipboardInputEth.vue'

@Component({
  components: { ClipboardInputEth }
})
export default class RecordRawEth extends Vue {
  @Prop({ type: Object, required: true })
  private item!: RecordRawOption

  @Prop(Boolean) private readonly!: boolean

  private get value () {
    return this.item.value
  }

  private set value (value) {
    this.$set(this.item, 'value', value)
  }

  private get placeholder () {
    if (!this.readonly && this.item.placeholder) {
      return lang(this.item.placeholder)
    }

    return lang('RECORD_INPUT_PLACEHOLDER')
  }
}
