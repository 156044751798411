
import { lang } from '@/lang'
import { EventBus, eventBus } from '@/utils/event'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { WalletItem, wallets } from './wallets'
import { wallet } from '@/utils/wallet'
import { errorMessage } from '@/utils/message'

@Component({})
export default class ConnectWalletDialog extends Vue {
  private visible = false

  private get width () {
    if (this.$siteConfig.isMobile) {
      return 'calc(100% - 56px)'
    }

    return '483px'
  }

  private get text () {
    return {
      title: lang('CONNECT_WALLET_DIALOG_TITLE'),
      linkText: lang('CONNECT_WALLET_DIALOG_LINK')
    }
  }

  private get wallets () {
    return wallets
  }

  open (): void {
    this.visible = true
  }

  private goToMetaMask () {
    window.open('https://metamask.io/', '_blank')
  }

  private async handlerClick (item: WalletItem) {
    try {
      const accountId = await item.onClick()
      wallet.accountId = accountId
      eventBus.emit(EventBus.Types.WALLET_CONNECT_SUCCEED, accountId)
    } catch (e) {
      // reload
      errorMessage(e.message)
      this.$eventBus.emit(EventBus.Types.WALLET_CONNECT_FAIL, e)
      throw new Error('[app-connect-wallet]:' + e.message)
    } finally {
      this.visible = false
    }
  }

  @Watch('visible')
  private onVisibleChange () {
    if (!this.visible) {
      eventBus.emit(EventBus.Types.CONNECT_WALLET_DIALOG_CLOSED)

      // 用户手动关闭连接钱包窗口
      if (!wallet.accountId || wallet.accountId === '') {
        this.$eventBus.emit(EventBus.Types.WALLET_CONNECT_FAIL, new Error('connect wallet canceled'))
      }
    }
  }
}
