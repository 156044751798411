import { siteConfig } from '@/config/site'
import { Message } from 'element-ui'

export function message (opts: Common.MessageOption): void {
  const vm = Message({
    type: opts.type,
    message: opts.message,
    duration: 3000,
    iconClass: opts.icon || 'none',
    offset: siteConfig.isMobile ? 16 : 50,
    showClose: true
  })

  if (opts.type) {
    vm.$el.classList.add(`el-message--${opts.type}`)
  }
}

export function successHappyMessage (content: string): void {
  message({
    type: 'success',
    icon: 'message-image message-image-happy',
    message: content
  })
}

export function successMessage (content: string): void {
  message({
    type: 'success',
    icon: 'message-icon uil uil-check-circle',
    message: content
  })
}

export function errorMessage (content: string): void {
  message({
    type: 'error',
    icon: 'message-icon icon icon-warn-fill',
    message: content
  })
}
