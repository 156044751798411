
import { copyText } from '@/utils/content'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { lang } from '@/lang'
import validPic from './assets/twitter_valid.svg'
import verifyInfo from '@/utils/useVerify'

@Component({})
export default class ClipboardInputTwitter extends Vue {
  @Prop(String) private value!: string
  @Prop(String) private placeholder!: string
  @Prop(Boolean) private readonly!: boolean
  @Prop(Boolean) private textarea!: boolean
  @Prop(Boolean) private copiable!: boolean
  @Prop(String) private link!: string
  @Prop({ type: [Boolean, Object], default: true })
  private autosize!: unknown

  private get text () {
    return lang('TWITTER_VALID_BTN')
  }

  private get validPic () {
    return validPic
  }

  private get isValid () {
    if (!this.computedValue) return false
    const array = this.computedValue.split('https://twitter.com/')
    if (array.length < 2) return false
    return array[1] === verifyInfo.twitter?.twitterId
  }

  private get computedValue () {
    return this.value || ''
  }

  private set computedValue (value) {
    this.$emit('input', value)
  }

  private get validPage () {
    const domain = this.$route.params.domain
    return `/twitter_valid?domain=${domain}`
  }

  private handlerCopy () {
    if (this.value) {
      copyText(this.value)
    }
  }
}
