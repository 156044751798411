import { ensureSetup, isEmptyAddr } from '@/sdk/base'
import { wallet } from '@/utils/wallet'
import sdk from './pnsSdk'
import { verifyDomain } from './verify'
import { BigNumber } from 'ethers'

// 搜索域名
export async function searchDomain (domain: string, nocheck = false): Promise<Common.Domain.BaseInfo | null> {
  domain = domain.toLowerCase()
  if (!/\.dot$/.test(domain)) {
    domain = `${domain}.dot`
  }

  if (!nocheck) {
    const checkValid = verifyDomain(domain)
    if (checkValid) {
      throw new Error(checkValid)
    }
  }

  await ensureSetup()
  const currentUser = wallet.accountId
  const ownerUser = await sdk.getOwner(domain)
  const operator = !isEmptyAddr(ownerUser) ? await sdk.getApproved(domain) : ''
  const domainParts = domain.split('.')
  const isSubDomain = domainParts.length > 2
  const parentDomain = isSubDomain ? domainParts.slice(1).join('.') : undefined
  const isMine = !isEmptyAddr(ownerUser) && ownerUser.toLowerCase() === currentUser.toLowerCase()
  console.log('currentUser', currentUser)
  const baseInfo: Common.Domain.BaseInfo = {
    domain,
    status: isEmptyAddr(ownerUser) ? 'available' : (isMine ? 'owned' : 'unavilable'),
    isMine: isMine,
    isFavor: false,
    isSubDomain,
    parentDomain,
    operator: operator,
    owner: isEmptyAddr(ownerUser) ? undefined : ownerUser
  }
  console.log('search domain baseInfo', baseInfo)
  return baseInfo
}

// 获取 Registration
export async function getOwner (domain: string): Promise<string | null> {
  await ensureSetup()
  const ownerAccount = await sdk.getOwner(domain)
  return isEmptyAddr(ownerAccount) ? null : ownerAccount
}

// 获取 Controller
export async function getOperator (domain: string): Promise<string | null> {
  await ensureSetup()
  const operator = await sdk.getApproved(domain)
  return operator
}

// 获取到期时间
export async function getExpireDate (domain: string): Promise<Date | null> {
  await ensureSetup()
  const expireSeconds = (await sdk.nameExpires(domain.domainEllipsisSuffix())).toNumber()
  if (expireSeconds) {
    return new Date(expireSeconds * 1000)
  }
  return null
}

// 获取 resolver
export async function getResolver (domain: string): Promise<string> {
  await ensureSetup()
  const recordResolver = await sdk.getKey('resolver', domain)
  const pnsAddr = await sdk.getPnsAddr()
  return recordResolver || pnsAddr
}

// 获取域名的子域名列表
export async function getSubdomains (domain: string): Promise<Common.Domain.BaseInfoBackend[]> {
  await ensureSetup()
  console.log('hash', sdk.getNamehash(domain))
  console.log('domain', domain.split('.')[0])
  return await sdk.getSubdomains(domain)
}

// 获取 record
export async function getRecord (domain: string): Promise<Common.DomainRecord> {
  await ensureSetup()
  const detail = await sdk.getDomainDetails(domain)
  console.log('detail', detail)
  const valueFormatter = (value: string) => {
    if (!value) return ''
    if (isEmptyAddr(value)) return ''
    return value
  }

  const getValue = <T extends { key: string; value: string }>(obj: T[], key: string) => {
    const item = obj.find(v => v.key === key)
    if (!item) return ''
    return valueFormatter(item.value)
  }

  return {
    address: {
      dot: getValue(detail.addrs, 'DOT'),
      ksm: getValue(detail.addrs, 'KSM'),
      eth: getValue(detail.addrs, 'ETH'),
      btc: getValue(detail.addrs, 'BTC')
    },
    content: {
      ipfs: valueFormatter(detail.content)
    },
    textRecord: {
      email: getValue(detail.textRecords, 'email'),
      notice: getValue(detail.textRecords, 'notice'),
      twitter: getValue(detail.textRecords, 'com.twitter'),
      github: getValue(detail.textRecords, 'com.github'),
      url: getValue(detail.textRecords, 'url'),
      avatar: getValue(detail.textRecords, 'avatar'),
      resolver: getValue(detail.textRecords, 'resolver')
    },
    cname: {
      cname: detail.cname || ''
    }
  }
}

export async function getAllDomains (): Promise<Common.Domain.BaseInfoBackend[]> {
  try {
    await ensureSetup(true)
  } catch (e:any) {
    console.error('[app-getAllDomains]' + e.message)
    return []
  }
  const accountId = wallet.accountId
  return await sdk.getAllDomains(accountId)
}

export async function getAllSubdomains (): Promise<Common.Domain.BaseInfoBackend[]> {
  try {
    await ensureSetup(true)
  } catch (e:any) {
    console.error('[app-getAllSubdomains]' + e.message)
    return []
  }
  const accountId = await wallet.accountId
  return await sdk.getAllSubdomains(accountId)
}

export async function getName (addr: string): Promise<BigNumber> {
  await ensureSetup()
  return await sdk.getName(addr)
}

export async function getDomains (): Promise<Common.Domain.BaseInfoBackend[]> {
  try {
    await ensureSetup(true)
  } catch (e:any) {
    console.error('[app-getDomains]' + e.message)
    return []
  }
  const accountId = wallet.accountId
  return await sdk.getDomains(accountId)
}

// getNft
export async function getNft (domain: string): Promise<any> {
  return await sdk.getKey(domain, 'nft') || ''
}
