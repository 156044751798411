
import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class CodeInput extends Vue {
  @Prop({ type: Number, default: 12 })
  private length!: number

  @Prop(String) private value!: string

  @Ref() private trigger!: HTMLInputElement

  private rawInputValue = ''

  private validInputValue = ''

  private focus = false

  public reset () {
    this.rawInputValue = ''
    this.validInputValue = ''
  }

  @Watch('rawInputValue', { immediate: true })
  private inputChange (newValue: string, oldValue: string) {
    if (newValue !== '' && !/^[a-zA-Z0-9]+$/.test(newValue)) {
      this.rawInputValue = this.validInputValue
      return
    }

    if (newValue.length > 12) {
      this.rawInputValue = newValue.substring(0, 12)
      return
    }

    this.validInputValue = this.rawInputValue.toUpperCase()
    this.$emit('input', this.rawInputValue.toUpperCase())
  }

  async setFocus (): Promise<void> {
    this.trigger.focus()
    this.focus = true
  }
}
