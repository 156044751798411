import * as sdk from './lib/sdk'
import { wallet } from '@/utils/wallet'
import { getNetworkByChainId, getNetwork, getDefaultChain } from '@/config/networks'
import { ethers } from 'ethers'
import { keccak_256 as keccak256 } from 'js-sha3'
import txMessage, { cleanMessage } from '@/plugins/tx-message/useMessage'
import { lang } from '@/lang'
import { delay } from '@/utils/common'
import contract from '@/config/contracts'
import { graphNode } from '@/config/api'

export function sha3 (data: string) {
  return '0x' + keccak256(data)
}

export function getNameHash (name: string): string {
  return sdk.getNamehash(name)
}

export async function ensureSetup (needLogin?: boolean): Promise<void> {
  // 只读操作
  const rpc = getNetwork()[0].rpcUrls[0]
  if (!needLogin) {
    await sdk.setup(wallet.defaultProvider, contract.pns, contract.controller, contract.priceOffController, graphNode, rpc)
    return
  }

  // 需要钱包权限
  try {
    if (!wallet.provider) {
      await wallet.connect()
    }
    const provider = new ethers.providers.Web3Provider(wallet.provider) as any
    await sdk.setup(provider, contract.pns, contract.controller, contract.priceOffController, graphNode, rpc)
  } catch (e:any) {
    throw new Error(e.message)
  }
}

export async function checkChain (targetChainId?: string): Promise<void> {
  // 不支持的链Id处理
  if (!targetChainId) {
    targetChainId = getDefaultChain()
  }
  const chainId = await wallet.getChainId()
  if (chainId !== targetChainId) {
    const networkItem = getNetworkByChainId(targetChainId)
    await wallet.switchChain(networkItem as Common.NetworkItem, wallet.accountId)
    await ensureSetup(true)
  }
}

// 获取自己的钱包地址
// export function getAccount (): string {
//   return wallet.accountId
// }

// 空地址
export function isEmptyAddr (addr: string): boolean {
  if (!addr) return true
  return addr === '0x0000000000000000000000000000000000000000'
}

export async function waitConfirmations (hx: string): Promise<void> {
  const message = txMessage('info', lang('TX_MESSAGE_SEND'), true)
  for (let i = 0; i < 120; i++) {
    console.log('Get transaction info times :', i + 1)
    const trx = await wallet.provider.request({ method: 'eth_getTransactionByHash', params: [hx] })
    if (!trx || !trx?.blockNumber) {
      await delay(2000)
    } else {
      cleanMessage(message, 'info')
      txMessage('success', lang('TX_MESSAGE_SUCCESS'))
      console.log('Transaction done at block:', trx.blockNumber)
      return
    }
  }

  cleanMessage(message, 'info')
}

// // 切换主链
// export async function switchChain (networkItem: Common.NetworkItem, account?: string): Promise<void> {
//   await wallet.provider.request({
//     method: 'wallet_addEthereumChain',
//     params: [networkItem, account]
//   })
// }

// 获取chain id
// export async function getChainId (): Promise<string> {
//   await setProvider()
//   const provider = await getProvider()
//   const chainId = (await provider.getNetwork()).chainId
//   return '0x' + chainId.toString(16)
// }

// export async function login (): Promise<void> {
//   if (wallet.provider) {
//     await sdk.login()
//   }
// }

// export async function logout (): Promise<void> {
//   await sdk.logout()
// }

// export async function setProvider (provider?: any): Promise<void> {
//   await sdk.setProvider(provider)
// }

// export function getProvider (): any {
//   return sdk.getProvider()
// }

// export async function setup (): Promise<void> {
//   await sdk.setup()
// }
