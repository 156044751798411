
import { Vue, Component, Prop } from 'vue-property-decorator'
import { RecordPartOption } from './types'
import RecordRaw from './RecordRaw.vue'
import RecordRawDot from './RecordRawDot.vue'
import RecordRawTwitter from './RecordRawTwitter.vue'
import RecordRawEth from './RecordRawEth.vue'

@Component({
  components: {
    RecordRaw,
    RecordRawDot,
    RecordRawTwitter,
    RecordRawEth
  }
})
export default class RecordPart extends Vue {
  @Prop({ type: Object, required: true })
  private option!: RecordPartOption

  @Prop(Boolean) private readonly!: boolean
}
