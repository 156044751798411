
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class MenuItem extends Vue {
  @Prop({ type: Object, required: true })
  private item!: Common.MenuItem

  private handleClick () {
    if (this.item.path) {
      this.$router.push(this.item.path).catch(e => console.log('[router]:' + e.message))
    }
  }
}
