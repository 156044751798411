import { paraFormat } from '@/utils/content'

export const langEN = {
  SEARCHINPUT_PLACEHOLDER: 'Search for your domain',
  SEARCHINPUT_BUTTON_TEXT: 'Search',
  CONNECT_WALLET_BUTTON_TEXT: 'Connect Wallet',
  CONNECT_WALLET_ERROR: 'Please install a wallet to continue Metamask is recommended, or open website in wallet APP',
  DISCONNECT_WALLET_BUTTON_TEXT: 'Disconnect Wallet',
  LANDING_SLOGAN_1: paraFormat([
    'Polkadot',
    'Name System.'
  ], { primaryColors: ['o', '\\.'] }),
  LANDING_MODULE_B_TITLE: 'What is PNS Project?',
  LANDING_MODULE_B_CONTENT: paraFormat([
    'PNS is an open, decentralized domain name system on the Polkadot blockchain.',
    'With PNS, every user can have their on-chain unique name, and resolves to their wallet account, smart contract address, NFT token, URL or IPFS address. PNS is the universal passport of Web3 ecosystem.'
  ]),
  LANDING_MODULE_B_TITLE_2: 'Subscribe to our mailing list',
  LANDING_MODULE_B_CONTENT_2: 'Subscribe us for newsletters and make sure to put us on the whitelist. We\'ll share with you our latest progress.',
  LANDING_MODULE_B_TIP: 'Sign up now and get noticed upon the launch.',
  LANDING_MODULE_B_EMAIL: 'Your Email here',

  NETWORK_SELECTOR_TIP: 'Choose network',
  NETWORK_SELECTOR_SUCCESS_TIP: 'Chain Switched to',
  NETWORK_SELECTOR_FAIL_TIP: 'Chain switch fail',
  NETWORK_CAN_NOT_SWITCH: 'Unable to switch network automatically, Please switch manually in wallet',

  LANDING_MODULE_C_TITLE: 'PNS Features',
  LANDING_MODULE_C_1_TITLE: 'Uniqueness',
  LANDING_MODULE_C_2_TITLE: 'Decentralized',
  LANDING_MODULE_C_3_TITLE: 'Polkadot Ecosystem Integration',
  LANDING_MODULE_C_4_TITLE: 'NFT Support',
  LANDING_MODULE_C_5_TITLE: 'Extendible Resolvers',
  LANDING_MODULE_C_6_TITLE: 'DNS Resolution',

  LANDING_MODULE_C_1_CONTENT: paraFormat(['Register your unique and personalized name ends with .dot, and also make it queryable, auditable and verifiable.'], { primaryColors: ['\\.dot'] }),
  LANDING_MODULE_C_2_CONTENT: 'PNS is built on top of permissionless blockchain system, owned and controlled by the name owner.',
  LANDING_MODULE_C_3_CONTENT: 'PNS is built for Polkadot and the parachain ecosystem. You can have multiple parachain identity binded to a single name.',
  LANDING_MODULE_C_4_CONTENT: 'Trade and exchange PNS name as NFT, and give every NFT a PNS name.',
  LANDING_MODULE_C_5_CONTENT: 'PNS names can be resolved to blockchain account, contract address, IPFS address, URL, email or social network account',
  LANDING_MODULE_C_6_CONTENT: 'PNS names can be accessed with DNS gateway and used in the browser without plugins.',

  LANDING_MODULE_D_TITLE: 'Usage Scenarios',
  LANDING_MODULE_D_1_TITLE: 'One Name for Long Address',
  LANDING_MODULE_D_2_TITLE: 'Access DApp by Readable Name',
  LANDING_MODULE_D_3_TITLE: 'Unify Multiple Chain',
  LANDING_MODULE_D_4_TITLE: 'NFT Support',
  LANDING_MODULE_D_5_TITLE: 'Manage Subdomains',

  LANDING_MODULE_D_1_CONTENT: 'No more annoying unreadable addresses. Bind clear and comprehensible name to your wallet account, add contacts, and transfer tokens.',
  LANDING_MODULE_D_2_CONTENT: 'Access DApp contract address with PNS name.',
  LANDING_MODULE_D_3_CONTENT: paraFormat([
    'Unify multiple on-chain identity with a single PNS name.',
    'Even though you have accounts on Acala, Litentry, Ethereum and more, use one name to rule them all.'
  ]),
  LANDING_MODULE_D_4_CONTENT: 'Trade and exchange PNS name as NFT.',
  LANDING_MODULE_D_5_CONTENT: 'Enrich your DID with subdomains! Each PNS name has 20 initial subdomains. Use them to store different addresses, NFTs, or anything you want.',

  LANDING_MODULE_F_TITLE: 'Join Our Community',

  LANDING_SLOGAN_2: paraFormat([
    'Find Your',
    'Polkadot Domain Here.'
  ], { primaryColors: ['\\.'] }),

  LANDING_LINK_1_TITLE: 'General',
  LANDING_LINK_2_TITLE: 'Technology',
  LANDING_LINK_3_TITLE: 'Community',
  LANDING_LINK_4_TITLE: 'Links',

  LANDING_LINK_1_ITEM_1: 'FAQ',
  LANDING_LINK_1_ITEM_2: 'Support',
  LANDING_LINK_1_ITEM_3: 'Build',

  LANDING_LINK_2_ITEM_1: 'Technology',
  LANDING_LINK_2_ITEM_2: 'Roadmap',

  LANDING_LINK_3_ITEM_1: 'Community',
  LANDING_LINK_3_ITEM_2: 'Documentation',
  LANDING_LINK_3_ITEM_3: 'Brand Assets',
  LANDING_LINK_3_ITEM_4: 'Blog',

  LANDING_LINK_4_ITEM_1: 'Twitter',
  LANDING_LINK_4_ITEM_2: 'Github',
  LANDING_LINK_4_ITEM_3: 'Youtube',
  LANDING_LINK_4_ITEM_4: 'Discord',
  LANDING_LINK_4_ITEM_5: 'Medium',
  LANDING_LINK_4_ITEM_6: 'Telegram',

  LANDING_COPYRIGHT: paraFormat(['© 2022 Polkadot Name System Project.'], { primaryColors: ['Polkadot Name System Project'] }),
  LANDING_COPYRIGHT_SUFFIX: 'All rights reserved',

  NEW_LANDING_SLOGAN_1: '<span>P</span>olkadot<br>\n<span>N</span>ame <span>S</span>ystem<span>.</span>',

  NEW_LANDING_MODULE_B_1: 'Since the official launch of PNS',
  NEW_LANDING_MODULE_B_2: 'Nearly <span>10,000</span> domains registered and\n<span>4,400+</span> people have their exclusive Web3 digital identity',
  NEW_LANDING_MODULE_B_3: 'Domains',
  NEW_LANDING_MODULE_B_4: 'Web3 digital identity',

  NEW_LANDING_MODULE_C_1: 'A DID Infrastructure for <span>Polkadot</span>',
  NEW_LANDING_MODULE_C_2: 'Unique Name',
  NEW_LANDING_MODULE_C_3: 'The globally unique <span>.dot</span> domain name replaces the meaningless, unreadable, and unmemorable long addresses. It establishes an independent and trusted digital identity.',
  NEW_LANDING_MODULE_C_4: 'An Aggregated Identity',
  NEW_LANDING_MODULE_C_5: 'PNS can bind different addresses in a multi-chain ecosystem. It brings together fragmented identities so that you can present yourself more comprehensively.',
  NEW_LANDING_MODULE_C_6: 'Convenient Exhibition',
  NEW_LANDING_MODULE_C_7: 'Every user of a PNS domain name will have a free personal Web3Profile, just enter <span><i>yourpolkaname</i>.dot.site</span> in the browser.',
  NEW_LANDING_MODULE_C_8: 'Data Integration',
  NEW_LANDING_MODULE_C_9: 'PNS links Web2/Web3 and integrates on-chain and off-chain data. It enhances the interactive experience of gaming, social, DAO, community governance, etc.',

  NEW_LANDING_MODULE_D_0: 'Use Cases',
  NEW_LANDING_MODULE_D_1: 'Web3 Name Card',
  NEW_LANDING_MODULE_D_2: 'Individuals or organizations can use PNS to integrate blockchain accounts to one .dot name.Display on-chain and off-chain data to show who you are and what you did.',
  NEW_LANDING_MODULE_D_3: 'Human Readable Identifier',
  NEW_LANDING_MODULE_D_4: 'Use a human readable and memorable name to replace your addresses. Using your PNS rather than the address in other dapps.',
  NEW_LANDING_MODULE_D_5: 'NFT',
  NEW_LANDING_MODULE_D_6: 'Artists can use PNS to build their own brands. Subdomains can link the address of their NFT for buyers to check if the one they are going to buy is the Artist’s work. You can build your NFT collections by link NFTs to PNS.',
  NEW_LANDING_MODULE_D_7: 'DAO',
  NEW_LANDING_MODULE_D_8: 'Using subdomains as member certificates of organizations. For example, Zeus from Olympus, who owned olympus.dot, can get a subdomain zeus.olympus.dot issued by olympus.dot. You can trust Zeus is from Olympus if Zeus has that subdomain.',
  NEW_LANDING_MODULE_D_9: 'Better Interaction',
  NEW_LANDING_MODULE_D_10: 'Use PNS as a unified portal for social accounts of projects or users on web3.Having a PNS help others to recognize, remember, and find you. That will enhance user interactions.',
  NEW_LANDING_MODULE_D_11: 'Under development',
  NEW_LANDING_MODULE_D_12: 'coming soon',
  NEW_LANDING_MODULE_D_13: 'under planning',

  NEW_LANDING_MODULE_E_1: 'Join Our Community',
  NEW_LANDING_MODULE_E_2: 'Join',
  NEW_LANDING_MODULE_E_3: 'Subscribe to our mailing list',

  NEW_LANDING_MODULE_F_1: 'Our Roadmap',
  NEW_LANDING_MODULE_F_2: 'PNS official launch on Moonbeam',
  NEW_LANDING_MODULE_F_3: 'Web3Profile online',
  NEW_LANDING_MODULE_F_4: 'PNS-based DNS service',
  NEW_LANDING_MODULE_F_5: 'Support more on-chain assets and social data integration',
  NEW_LANDING_MODULE_F_6: 'NFT binding to domain. Support identity authentication of social platforms.',
  NEW_LANDING_MODULE_F_7: 'Enhanced IPFS integration',

  NEW_LANDING_MODULE_G_1: 'Our Partners',

  NEW_LANDING_MODULE_H_1: 'Building Web3 Identity Ecosystem Together',
  NEW_LANDING_MODULE_H_2: 'Developers & DApps',
  NEW_LANDING_MODULE_H_3: 'We will develop convenient tools and early technical support for developers to integrate PNS. We welcome developers to build a variety of diverse Web3 applications on top of PNS.',
  NEW_LANDING_MODULE_H_4: 'Media & Community',
  NEW_LANDING_MODULE_H_5: 'We welcome Web3 communities, media and individual KOLs to collaborate with us. We are happy to share our resources with you.',
  NEW_LANDING_MODULE_H_6: 'Community Volunteers',
  NEW_LANDING_MODULE_H_7: 'We welcome community volunteers who are passionate about PNS and Web3.  we can build the Polkadot DID ecosystem together.',
  NEW_LANDING_MODULE_H_8: 'Contact Us',

  NEW_LANDING_MODULE_I_1: 'PNS helps everyone and everything in <span>Polkadot</span> know each other better.',

  NEW_LANDING_MODULE_J_1: 'Find your <span>Polkadot</span> <br>\n Name here',

  HELP_CENTER_TITLE: 'Help Center',
  HELP_CENTER_TIP: 'If your have further questions, please join our <a href="https://discord.com/invite/WfYwxNW5Jn" target="_blank">Community</a> or visit our <a href="https://medium.com/@pns.dot/pns-faq-2f53f07a97b7" target="_blank">Medium FAQs</a> pages.',

  HOME_FOOTER_1: 'Company',
  HOME_FOOTER_2: 'About us',
  HOME_FOOTER_3: 'Support',
  HOME_FOOTER_4: 'Blog',
  HOME_FOOTER_5: 'Contact us',
  HOME_FOOTER_6: 'Subscribe to our mailing list',

  MENU_HOME_NEW_NAMES: 'New names',
  MENU_HOME_ALL_MY_NAMES: 'All my names',
  MENU_HOME_FAVORITES: 'Favorites',
  MENU_HOME_ABOUT: 'About',
  MENU_MY_NAME_MY_REGISTRANT: 'My registrant',
  MENU_MY_NAME_MY_SUBDOMAIN: 'My subdomains',
  MENU_MY_NAME_MY_REFERRER: 'Referral',
  MENU_MY_NAME_CONTROLLER: 'Controller',
  MENU_BACK: 'Back',

  STATUS_PANEL_WALLET_CONNECT: 'Connect to Wallet',
  STATUS_PANEL_WALLET_DISCONNECT: 'Disconnect Wallet',
  STATUS_PANEL_WALLET_READONLY: 'Read Only',

  DOMAIN_SEARCHPANEL_EXACTLY_MATCH: 'Exactly match',
  DOMAIN_STATUS_AVAILABLE: 'Available',
  DOMAIN_STATUS_OWNED: 'Owned',
  DOMAIN_STATUS_UNAVAILABLE: 'Unavailable',

  DOMAIN_STATUS_GET_WEB3PROFILE: 'Get your Web3Profile',
  DOMAIN_STATUS_SHARE_CARD: 'Share with card',

  DOMAIN_DETAILS_TABS_REGISTET: 'Register',
  DOMAIN_DETAILS_TABS_DETAIL: 'Detail',
  DOMAIN_DETAILS_TABS_SUBDOMAINS: 'Subdomains',

  DOMAIN_NAME_DETAILS_PARENT: 'Parent',
  DOMAIN_NAME_DETAILS_OWNER: 'Owner',
  DOMAIN_NAME_DETAILS_CONTROLLER: 'Controller',
  DOMAIN_NAME_DETAILS_OPERATOR: 'Operator',
  DOMAIN_NAME_DETAILS_EXPIRATION: 'Expiration Date',
  DOMAIN_NAME_DETAILS_RESOLVER: 'Resolver',
  DOMAIN_NAME_DETAILS_REGISTRANT: 'Registrant',
  DOMAIN_NAME_DETAILS_SUBDOMAIN: 'Subdomains',
  DOMAIN_NAME_DOMAINS: 'Domains',
  DOMAIN_NAME_EXPIRE_DATE: 'Expire Date',
  DOMAIN_NAME_LOADING: 'loading...',
  DOMAIN_NAME_OWNER: 'Owner',

  DOMAIN_NAME_DETAILS_BUTTON_TRANSFER: 'Transfer',
  DOMAIN_NAME_DETAILS_BUTTON_SET: 'Set',
  DOMAIN_NAME_DETAILS_BUTTON_RENEW: 'Renew',
  DOMAIN_NAME_DETAILS_BUTTON_REMIND: 'Remind Me',

  MESSAGE_COPY_SUCCESS: 'Copy succeeded!',
  MESSAGE_COPY_ERROR: 'Copy failed!',
  MESSAGE_NOSUPPORT_CLIPBOARD: 'Clipboard not support!',

  TRANSFER_DIALOG_TITLE: 'Transfer Registration',
  TRANSFER_DIALOG_LABEL: 'Enter a address or name to transfer',
  TRANSFER_DIALOG_PLACEHOLDER: 'name or address',
  TRANSFER_DIALOG_BUTTON_CONFIRM: 'Transfer',
  TRANSFER_DIALOG_BUTTON_CANCEL: 'Cancel',

  MESSAGE_TRANSFER_SUCCESS: 'Transfer succeeded!',
  MESSAGE_TRANSFER_ERROR: 'Transfer failed!',

  SET_CONTROLLER_DIALOG_TITLE: 'Set Operator',
  SET_CONTROLLER_DIALOG_LABEL: 'Enter a new Operator address',
  SET_CONTROLLER_DIALOG_PLACEHOLDER: 'address',
  SET_CONTROLLER_DIALOG_BUTTON_CONFIRM: 'Set',
  SET_CONTROLLER_DIALOG_BUTTON_CANCEL: 'Cancel',

  MESSAGE_SET_CONTROLLER_SUCCESS: 'Set Operator succeeded!',
  MESSAGE_SET_CONTROLLER_ERROR: 'Set Operator failed!',

  RENEW_DIALOG_TITLLE: 'Renew Expiration Date',
  RENEW_DIALOG_CONFIRM: 'Renew',

  RENEW_FORM_PERIOD: 'Registration Period',
  RENEW_FORM_PERIOD_UNIT: 'years',
  RENEW_FORM_PERIOD_TIP: 'Increase registration period to avoid gas fee every year',
  RENEW_FORM_PRICE: 'Registration fee',
  RENEW_FORM_GAS: 'Estimated Gas',
  RENEW_FORM_GAS_AT_LEASE: 'at least',
  RENEW_FORM_TOTAL: 'Total (Registration fee + Annual fee)',
  RENEW_FORM_GAS_FREE: 'gas fee',
  RENEW_FORM_DEPOSIT: 'Deposit',
  RENEW_FORM_ANNUAL: 'Annual fee',

  MESSAGE_RENEW_SUCCESS: 'Renew succeeded!',
  MESSAGE_RENEW_ERROR: 'Renew failed!',

  SET_RESOLVER_DIALOG_TITLE: 'Set Resolver',
  SET_RESOLVER_DIALOG_LABEL: 'Enter a new resolver address',
  SET_RESOLVER_DIALOG_PLACEHOLDER: 'address',
  SET_RESOLVER_DIALOG_BUTTON_CONFIRM: 'Set',
  SET_RESOLVER_DIALOG_BUTTON_CANCEL: 'Cancel',

  MESSAGE_SET_RESOLVER_SUCCESS: 'Set resolver succeeded!',
  MESSAGE_SET_RESOLVER_ERROR: 'Set resolver failed!',

  CONNECT_WALLET_DIALOG_TITLE: 'Connect Your Wallet',
  CONNECT_WALLET_DIALOG_LINK: 'How to get a Wallet?',
  CONNECT_WALLET_DIALOG_TITLE_METAMASK: 'Connect to Metamask',
  CONNECT_WALLET_DIALOG_TITLE_FOXWALLET: 'Connect to FoxWallet',
  CONNECT_WALLET_DIALOG_TITLE_WALLETCONNECT: 'Connect to WalletConnect',
  CONNECT_WALLET_DISCONNECT_SUCCESS: 'Disconnected successfully',

  NAME_DETAILS_TITLE: 'Name Details',
  NAME_REGISTER_TITLE: 'Name Register',
  NAME_REGISTER_REGISTER: 'Register',
  NAME_REGISTER_USE_CODE: 'Use Redeem Code',
  NAME_REGISTER_REDEEMCODE_INVALID: 'Redeem Code Invalid',
  NAME_REGISTER_REDEEMCODE_TIME_TOO_LONG: 'Registration period must be less than or equal to one year',
  NAME_REGISTER_TOO_SHORT: 'The digits of the domain should be at least #',
  NAME_REGISTER_TOO_LONG: 'Domain length must be less than or equal to #',

  NAME_REGISTER_INVALID_CHARACTER: 'Domain contains invalid characters',
  NAME_REGISTER_REFERRER_TITTLE: 'Referrer',
  NAME_REGISTER_REFERRER_LABEL: 'domain name', // placeholder
  NAME_REGISTER_REFERRER_PLACEHOLDER: 'Fill in the inviter\'s domain name',

  REGISTER_STEPPER_1_TITLE: 'Request to register',
  REGISTER_STEPPER_2_TITLE: 'Wait a minute',
  REGISTER_STEPPER_3_TITLE: 'Complete Registration',

  REGISTER_ACTIONBAR_RETRY: 'Retry step',
  REGISTER_ACTIONBAR_MANAGE: 'Manage Domain',
  REGISTER_ACTIONBAR_TWITTER: 'Tweet Your Friends!',
  REGISTER_ACTIONBAR_SHARE: 'Share with Card!',

  REGISTER_STEPPER_1_DISC: paraFormat([
    'Your wallet will open and you will be asked to confirm a transaction required for registration.'
  ]),
  REGISTER_STEPPER_2_DISC: 'The waiting period is required to ensure another person hasn’t tried to register the same name and protect you after your request.',
  REGISTER_STEPPER_3_DISC: '',

  MESSAGE_REGISTER_SUCCESS: 'You have registed # successfully!',

  DOMAIN_RECORD_TITLE: 'Name Records',
  DOMAIN_RECORD_ACTION_TEXT: 'Edit / Add Record',

  RECORD_FORM_ADDRESS: 'Address',
  RECORD_FORM_ADDRESS_DOT: 'DOT',
  RECORD_FORM_ADDRESS_KSM: 'KSM',
  RECORD_FORM_ADDRESS_ETH: 'ETH',
  RECORD_FORM_ADDRESS_BTC: 'BTC',

  RECORD_FORM_CONTENT: 'Content',
  RECORD_FORM_CONTENT_IPFS: 'IPFS',

  RECORD_FORM_TEXT_RECORD: 'Text Record',
  RECORD_FORM_TEXT_RECORD_EMAIL: 'Email',
  RECORD_FORM_TEXT_RECORD_CNAME: 'CNAME',
  RECORD_FORM_TEXT_RECORD_NOTICE: 'Notice',
  RECORD_FORM_TEXT_RECORD_TWITTER: 'com.twitter',
  RECORD_FORM_TEXT_RECORD_GITHUB: 'com.github',
  RECORD_FORM_TEXT_RECORD_URL: 'Url',
  RECORD_FORM_TEXT_RECORD_AVATAR: 'Avatar',

  RECORD_INPUT_PLACEHOLDER: 'Not Set',
  RECORD_INPUT_PLACEHOLDER_DOT: 'Enter DOT address',
  RECORD_INPUT_PLACEHOLDER_KSM: 'Enter KSM address',
  RECORD_INPUT_PLACEHOLDER_ETH: 'Enter ETH address',
  RECORD_INPUT_PLACEHOLDER_BTC: 'Enter BTC address',

  RECORD_INPUT_PLACEHOLDER_IPFS: 'Enter IPFS content',

  RECORD_INPUT_PLACEHOLDER_EMAIL: 'Enter email',
  RECORD_INPUT_PLACEHOLDER_CNAME: 'Enter CNAME',
  RECORD_INPUT_PLACEHOLDER_NOTICE: 'Enter notice',
  RECORD_INPUT_PLACEHOLDER_TWITTER: 'Enter twitter link',
  RECORD_INPUT_PLACEHOLDER_GITHUB: 'Enter github link',
  RECORD_INPUT_PLACEHOLDER_URL: 'Enter any URL',
  RECORD_INPUT_PLACEHOLDER_AVATAR: 'Enter avatar URL',

  EDIT_RECORD_TITLE: 'Edit Record',
  EDIT_RECORD_FOOTER_INTRO: 'Add, delete, or edit one or multiple records. Confirm in one transaction.',
  EDIT_RECORD_BUTTON_CANCEL: 'Cancel',
  EDIT_RECORD_BUTTON_CONFIRM: 'Save',

  MESSAGE_EDIT_RECORD_SUCCESS: 'Edit record succeeded!',
  MESSAGE_EDIT_RECORD_ERROR: 'Edit record failed!',
  MESSAGE_ADDRESS_INVALID_ERROR: 'Address is invalid',
  MESSAGE_LABEL_INVALID_ERROR: 'Label is invalid',

  SUBDOMAIN_TITLE: 'Subdomains',
  SUBDOMAIN_BUTTON_ADD: 'Add New Subdomain',
  SUBDOMAIN_BUTTON_EDIT: 'Edit Subdomain List',
  SUBDOMAIN_EDIT_TIP: 'Delete up to ten at a time.',
  SUBDOMAIN_NO_DATA: 'No subdomains have been added',
  SUBDOMAIN_DELETE: 'Delete Subdomain',

  SUBDOMAIN_ADD_TITLE: 'Add New Subdomains',
  SUBDOMAIN_ADD_INPUT_LABEL: 'Type in a label for your subdomain',
  SUBDOMAIN_ADD_INPUT_PLACEHOLDER: 'Enter subdomain label & wallet address to distinguish with the Enter key',
  SUBDOMAIN_ADD_BTN: 'Add',
  SUBDOMAIN_ADD_UPLOAD: 'or Upload a CSV file',
  SUBDOMAIN_ADD_TIPS: 'The CSV file needs to contain the subdomain label and wallet address.Mint up to ten at a time.',

  SUBDOMAIN_DIALOG_TITLE: 'Add New Subdomain',
  SUBDOMAIN_DIALOG_LABEL: 'Type in a label for your subdomain',
  SUBDOMAIN_DIALOG_LABEL2: 'Type in a address for your subdomain owner',
  SUBDOMAIN_DIALOG_PLACEHOLDER: 'Enter subdomain label',
  SUBDOMAIN_DIALOG_PLACEHOLDER2: 'Enter a address',
  SUBDOMAIN_DIALOG_BUTTON_CANCEL: 'Cancel',
  SUBDOMAIN_DIALOG_BUTTON_CONFIRM: 'Save',

  SUBDOMAIN_DIALOG_DELETE_TITLE: 'Delete subdomain',
  SUBDOMAIN_DIALOG_DELETE_LABEL: 'You\'re deleting a subdomain:',
  SUBDOMAIN_DIALOG_BUTTON_DELETE: 'Delete',

  MESSAGE_ADD_SUBDOMAIN_SUCCESS: 'You have added subdomain!',
  MESSAGE_ADD_SUBDOMAIN_ERROR: 'Add subdomain failed!',
  MESSAGE_DELETE_SUBDOMAIN_SUCCESS: 'You have delete subdomain!',
  MESSAGE_DELETE_SUBDOMAIN_ERR: 'Delete subdomain failed!',

  USECODE_DIALOG_TITLE: 'Use Redeem Code',
  USECODE_DIALOG_INTRO: 'Please Enter 12-digit Redeem Code',
  USECODE_DIALOG_CHECK: 'Check',

  SUBSTRATE_DIALOG_TITLE: 'Select Address',
  SUBSTRATE_DIALOG_INTRO: 'You are paying in #, please select an address',
  SUBSTRATE_DIALOG_CHECK: 'Confirm',

  MY_NAME_TITLE: 'My Address',
  MY_NAME_NOT_CONNECT: 'Please connect wallet to show more info',
  MY_NAME_GOTO_POLKASCAN: 'View on Polkascan',
  MY_NAME_GOTO_ETHERSCAN: 'View on Explorer',
  MY_NAME_REVERSE_RECORD: 'Reverse Record:',
  MY_NAME_NOT_SET: 'Not set',
  MY_NAME_FIND_MORE_DOMAIN: 'Find More Domains',

  REVERRECORD_DIALOG_TITLE: 'Reverse Record',
  REVERRECORD_DIALOG_DES_1: 'The Reverse Resolution translates an address into a name. ',
  REVERRECORD_DIALOG_DES_2: 'It allows Dapps to show in their interfaces <span>#1</span> rather than the long address: <div>#2</div>',
  REVERRECORD_DIALOG_DES_3: 'If you would like to set up your reverse for a different account, please switch accounts in your dapp browser',
  REVERRECORD_DIALOG_SAVE: 'Save',
  REVERRECORD_DIALOG_CANCEL: 'Cancel',
  REVERRECORD_SAVE_SUCCESS: 'Reverse Record saving success',

  QRCODE_TITLE: 'Address QR Code',

  HEADER_MY_NAMES: 'My Names',

  LAUNCH_TIP: 'We‘ve not officially launched yet. Please subscribe our newsletter or join our community to receive updates.',

  TX_MESSAGE_SEND: 'A transaction has been send, please wait for the transaction to complete',
  TX_MESSAGE_SUCCESS: 'Transaction complete',

  TWITTER_VALID_PAGE_TITLE: 'Verify your profile via Twitter',
  TWITTER_VALID_PAGE_STEP_1: 'Step One',
  TWITTER_VALID_PAGE_STEP_2: 'Step Two',
  TWITTER_VALID_PAGE_STEP_TITLE_1: 'Post a public tweet that contains your signature.',
  TWITTER_VALID_PAGE_STEP_1_DES_1: 'Your wallet address is:',
  TWITTER_VALID_PAGE_STEP_1_DES_2: 'Input your twitter ID:',
  TWITTER_VALID_PAGE_POST: 'Post Twitter',
  TWITTER_VALID_PAGE_CONFIRM: 'Confirm',
  TWITTER_VALID_PAGE_STEP_TITLE_2: 'Paste the URL of the tweet to verify your profile.',
  TWITTER_VALID_PAGE_STEP_URL: 'Tweet URL',
  TWITTER_VALID_BTN: 'Verify via Twitter',

  VALID_BTN: 'Verify Address',

  REFERRER_PAGE_TITLE: 'Referral balance',
  REFERRER_PAGE_RULE: 'Withdraw Rules',
  REFERRER_PAGE_LABEL_ADDRESS: 'Withdrawal address',
  REFERRER_PAGE_LABEL_LINK: 'Referral link',
  REFERRER_PAGE_LABEL_CURRENCY: 'Select Currency',
  REFERRER_PAGE_LABEL_AMOUNT: 'Amount',
  REFERRER_PAGE_LABEL_RATIO: 'Invite new users to register to get a # registration fee',
  REFERRER_PAGE_LABEL_WITHDRAW_BTN: 'Withdraw',
  REFERRER_PAGE_RECORD_TAB_REFERRAL: 'Referral record',
  REFERRER_PAGE_RECORD_TAB_WITHDRAW: 'Withdrawals record',
  REFERRER_PAGE_RECORD_LIST_REGISTRANT: 'registrant',
  REFERRER_PAGE_RECORD_LIST_DOMAIN: 'domain name',
  REFERRER_PAGE_RECORD_LIST_TIME: 'creation time',
  REFERRER_PAGE_RECORD_LIST_AMOUNT: 'amount',
  REFERRER_PAGE_RECORD_LIST_BALANCE: 'balance',
  REFERRER_PAGE_RECORD_LIST_REWARD: 'reward',
  REFERRER_PAGE_RECORD_LIST_STATE: 'state',
  REFERRER_PAGE_RECORD_LIST_ADDRESS: 'address',

  RECORD_NFT_TITLE: 'Bind NFT',
  RECORD_NFT_LABEL: 'Click the Edit button to add a bound NFT',
  RECORD_NFT_EDIT_BTN: 'Edit/Add NFT',

  BIND_NFT_TITLE: 'Set Nft Info',
  RECORD_NFT_BTN: 'Save'

}

export type LangMap = Record<keyof typeof langEN, string>
