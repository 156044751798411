import Vue from 'vue'
// marker:1
import CodeInput from './CodeInput/CodeInput.vue'
import RecordForm from './RecordForm/RecordForm.vue'
import ClipboardInput from './ClipboardInput/ClipboardInput.vue'
import Stepper from './Stepper/Stepper.vue'
import ConnectWalletDialog from './ConnectWalletDialog/ConnectWalletDialog.vue'
import MobileNavBar from './MobileNavBar/MobileNavBar.vue'
import CurrencySelector from './CurrencySelector/CurrencySelector.vue'
import InputNumber from './InputNumber/InputNumber.vue'
import RegistForm from './RegistForm/RegistForm.vue'
import Dialog from './Dialog/Dialog.vue'
import TextAction from './TextAction/TextAction.vue'
import ClipboardText from './ClipboardText/ClipboardText.vue'
import Panel from './Panel/Panel.vue'
import Tabs from './Tabs/Tabs.vue'
import Loading from './Loading/Loading.vue'
import SearchInput from './SearchInput/SearchInput.vue'
import LangSelector from './LangSelector/LangSelector.vue'
import Icon from './Icon/Icon.vue'
import QRCode from './QRCode/QRCode.vue'

// marker:2
Vue.component('AppCodeInput', CodeInput)
Vue.component('AppRecordForm', RecordForm)
Vue.component('AppClipboardInput', ClipboardInput)
Vue.component('AppStepper', Stepper)
Vue.component('AppConnectWalletDialog', ConnectWalletDialog)
Vue.component('AppMobileNavBar', MobileNavBar)
Vue.component('AppCurrencySelector', CurrencySelector)
Vue.component('AppInputNumber', InputNumber)
Vue.component('AppRegistForm', RegistForm)
Vue.component('AppDialog', Dialog)
Vue.component('AppTextAction', TextAction)
Vue.component('AppClipboardText', ClipboardText)
Vue.component('AppPanel', Panel)
Vue.component('AppTabs', Tabs)
Vue.component('AppLoading', Loading)
Vue.component('AppSearchInput', SearchInput)
Vue.component('AppLangSelector', LangSelector)
Vue.component('AppIcon', Icon)
Vue.component('AppQRCode', QRCode)
