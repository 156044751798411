import { RouteConfig } from 'vue-router'
import { defineAsyncComponent } from 'vue'
import AsyncComponentFail from '@/components/AsyncComponentFail/index.vue'

export const routes: RouteConfig[] = [
  {
    name: 'components',
    path: '/components(/:id)?',
    component: () => import('@/views/Components/Main.vue')
  },
  {
    name: 'landing',
    path: '/',
    // component: () => import('@/views/Landing/Main.vue'),
    // component: () => import('@/views/NewLanding/Main.vue'),
    component: defineAsyncComponent({
      loader: () => import('@/views/NewLanding/Main.vue'),
      errorComponent: AsyncComponentFail,
      timeout: 3000
    }),
    meta: {
      useGhostTheme: true
    }
  },
  {
    name: 'help-center',
    path: '/help',
    component: () => import('@/views/HelpCenter/Main.vue'),
    meta: {
      useGhostTheme: true
    }
  },
  {
    name: 'help-center',
    path: '/help/detail',
    component: () => import('@/views/HelpCenter/HelpDetail.vue'),
    meta: {
      useGhostTheme: true
    }
  },
  {
    name: 'wrapper',
    path: '/wrapper',
    // component: () => import('@/views/Wrapper/Main.vue'),
    component: defineAsyncComponent({
      loader: () => import('@/views/Wrapper/Main.vue'),
      errorComponent: AsyncComponentFail,
      timeout: 1000
    }),
    redirect: '/new-names',
    children: [
      {
        name: 'new-names',
        path: '/new-names',
        component: () => import('@/views/NewNames/Main.vue'),
        redirect: '/search',
        children: [
          {
            name: 'search',
            path: '/search',
            component: () => import('@/views/SearchResult/Main.vue'),
            meta: {
              activeMenuId: 'home#new-names'
            }
          },
          {
            name: 'details',
            path: '/details/:domain',
            component: () => import('@/views/DomainDetailsFrame/Main.vue'),
            meta: {
              activeMenuId: 'home#new-names'
            }
          },
          {
            name: 'referrer',
            path: '/referrer',
            component: () => import('@/views/Referrer/Main.vue'),
            meta: {
              activeMenuId: 'my-name#referrer'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'wrapper2',
    path: '/my-name-wrapper',
    component: () => import('@/views/Wrapper/Main.vue'),
    redirect: '/my-names',
    children: [
      {
        name: 'my-names',
        path: '/my-names',
        component: () => import('@/views/MyNames/Main.vue'),
        redirect: '/registrant',
        children: [
          {
            name: 'registrant',
            path: '/registrant',
            component: () => import('@/views/MyNames/MyRegistrant/Main.vue'),
            meta: {
              activeMenuId: 'my-name#my-registrant'
            }
          },
          {
            name: 'subdomain',
            path: '/subdomain',
            component: () => import('@/views/MyNames/MySubdomain/Main.vue'),
            meta: {
              activeMenuId: 'my-name#my-subdomain'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'twitter-valid',
    path: '/twitter_valid',
    component: () => import('@/views/TwitterValid/Main.vue')
  }
]
