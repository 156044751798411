
import { Vue, Component, Prop } from 'vue-property-decorator'
import { langConfig } from './config'
import { isDev } from '@/utils/common'

@Component({})
export default class LangSelector extends Vue {
  @Prop({ type: Boolean, default: false })
  private small!: boolean

  private get langConfig () {
    return langConfig
  }

  private get current () {
    return this.$siteConfig.lang
  }

  private get showLabel () {
    const currentLang = this.langConfig.find(item => item.id === this.current)
    if (currentLang) {
      return this.small ? currentLang.shortLabel : currentLang.label
    }
    return '-'
  }

  private handlerCommand (lang: string) {
    const item = this.langConfig.find(v => v.id === lang)
    if (!item) return
    if (lang === this.$siteConfig.lang) return
    this.$siteConfig.lang = lang
  }
}
