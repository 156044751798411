
import { lang } from '@/lang'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { wallet } from '@/utils/wallet'
import { EventBus, eventBus } from '@/utils/event'
import { isBanFeature } from '@/utils/common'

@Component({})
export default class ConnectWallet extends Vue {
  private get connected () {
    return wallet.accountId !== ''
  }

  private get buttonText () {
    if (this.connected) {
      // return shortAddr(wallet.accountId)
      return lang('HEADER_MY_NAMES')
    }

    return lang('CONNECT_WALLET_BUTTON_TEXT')
  }

  private get show () {
    return !isBanFeature()
  }

  private get currChain () {
    return wallet.currChain
  }

  private get provider () {
    return wallet.provider
  }

  private async handleClick () {
    if (this.connected) {
      this.$router.push('/registrant').catch(e => console.log)
    } else {
      await wallet.connect()
    }
  }

  private disconnect () {
    wallet.disConnect()
  }

  private async handlAccountsChanged (accounts : string[]) {
    // console.log('handlAccountsChanged accounts[0]', accounts[0])
    // if (!accounts[0]) {
    //   wallet.disConnect()
    // } else {
    //   wallet.accountId = accounts[0]
    // }
    // eventBus.emit(EventBus.Types.DOMAIN_BASEINFO_REFETCH)
    // alert('handlAccountsChanged')
    // location.reload()
    await wallet.disConnect()
  }

  private handlChainChanged (chainId : string) {
    // (window as any).location.reload()
    // eventBus.emit(EventBus.Types.WALLET_SWITCH_CHAIN, chainId)
  }

  private unBindEvent () :void {
    // if ((window as any).ethereum) {
    //   console.log('unBindEvent');
    //   (window as any).ethereum.removeListener('accountsChanged', this.handlAccountsChanged);
    //   (window as any).ethereum.removeListener('chainChanged', this.handlChainChanged)
    // }
  }

  // @Watch('currChain')
  // private chainChange () {
  //   eventBus.emit(EventBus.Types.DOMAIN_BASEINFO_REFETCH, true)
  // }

  @Watch('provider')
  private providerChange (val: any, oldVal: any) {
    console.log('bindEvent')

    if (oldVal) {
      oldVal.removeListener('accountsChanged', this.handlAccountsChanged)
      oldVal.removeListener('chainChanged', this.handlChainChanged)
    }

    if (!val) return

    val.on('accountsChanged', this.handlAccountsChanged)
    // val.on('chainChanged', this.handlChainChanged)
  }

  created () : void{
    // window.onbeforeunload = function () {
    //   wallet.disConnect()
    // }

    this.$once('hook:beforeDestroy', () => {
      this.unBindEvent()
    })
  }
}
