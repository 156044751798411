
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component({})
export default class Tabs extends Vue {
  @Prop({ type: Array, default: () => [] })
  private tabs!: Common.MenuItem[]

  @Prop(String) private value!: string

  @Emit('input')
  private handlerSelect (item: Common.MenuItem) {
    return item.id
  }

  private get showTab () {
    return this.tabs.length > 1
  }
}
