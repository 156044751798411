import ICON_METAMASK from './assets/metamask.svg'
import ICON_WALLETCONNECT from './assets/walletConnect.png'
import ICON_FOXWALLET from './assets/foxwallet.png'
import { lang } from '@/lang'
import { wallet } from '@/utils/wallet'
import WalletConnectProvider2 from '@walletconnect/ethereum-provider'
import { signInWithEthereum } from '@/service/SIWE'

export interface WalletItem {
  icon: string;
  onClick: (sign?:boolean) => Promise<string>;
  getTitle: (sign?:boolean) => string;
}

export const wallets: WalletItem[] = [
  {
    icon: ICON_METAMASK,
    getTitle: () => lang('CONNECT_WALLET_DIALOG_TITLE_METAMASK'),
    async onClick (sign = true): Promise<string> {
      if (!(window as any).ethereum) {
        window.open('https://metamask.io/download.html', '_blank')
        throw new Error('MetaMask was not install !')
      }

      let list = await (window as any).ethereum.request({ method: 'eth_accounts' })
      console.log('[Account list]', list)
      if (!list.length) {
        await (window as any).ethereum.enable()
        list = await (window as any).ethereum.request({ method: 'eth_requestAccounts' })

        if (!list.length) {
          throw new Error('Please unlock your wallet to continue')
        }
        console.log('[Unlocked account list]', list)
      }

      wallet.provider = (window as any).ethereum
      if (sign) {
        await signInWithEthereum()
      }
      window.localStorage.setItem('lastLoginType', 'MetaMask')
      return list[0]
    }
  },
  {
    icon: ICON_FOXWALLET,
    getTitle: () => lang('CONNECT_WALLET_DIALOG_TITLE_FOXWALLET'),
    async onClick (sign = true): Promise<string> {
      window.open('https://foxwallet.com/download')
      return ''
      // if (!(window as any).ethereum) {
      //   window.open('https://metamask.io/download.html', '_blank')
      //   throw new Error('MetaMask was not install !')
      // }
      //
      // let list = await (window as any).ethereum.request({ method: 'eth_accounts' })
      // console.log('[Account list]', list)
      // if (!list.length) {
      //   await (window as any).ethereum.enable()
      //   list = await (window as any).ethereum.request({ method: 'eth_requestAccounts' })
      //
      //   if (!list.length) {
      //     throw new Error('Please unlock your wallet to continue')
      //   }
      //   console.log('[Unlocked account list]', list)
      // }
      //
      // wallet.provider = (window as any).ethereum
      // if (sign) {
      //   await signInWithEthereum()
      // }
      // window.localStorage.setItem('lastLoginType', 'MetaMask')
      // return list[0]
    }
  },
  {
    icon: ICON_WALLETCONNECT,
    getTitle: () => lang('CONNECT_WALLET_DIALOG_TITLE_WALLETCONNECT'),
    async onClick (sign = true): Promise<string> {
      const walletConnectProvider = new WalletConnectProvider2({
        rpc: {
          1284: 'https://rpc.api.moonbeam.network',
          1287: 'https://rpc.api.moonbase.moonbeam.network',
          43113: 'https://api.avax-test.network/ext/bc/C/rpc',
          1: 'https://rpc.ankr.com/eth',
          56: 'https://rpc.ankr.com/bsc',
          43114: 'https://rpc.ankr.com/avalanche',
          137: 'https://rpc.ankr.com/polygon',
          42161: 'https://rpc.ankr.com/arbitrum',
          199: 'https://rpc.ankr.com/bttc',
          42220: 'https://rpc.ankr.com/celo',
          250: 'https://rpc.ankr.com/fantom',
          100: 'https://rpc.ankr.com/gnosis',
          1666600000: 'https://rpc.ankr.com/harmony',
          4689: 'https://rpc.ankr.com/iotex',
          10: 'https://rpc.ankr.com/optimism',
          245022934: 'https://rpc.ankr.com/solana',
          57: 'https://rpc.ankr.com/syscoin',
          1231: 'https://rpc.ankr.com/http/tron'
        },
        chainId: 1284,
        qrcode: true
      })
      await walletConnectProvider.enable()
      const list: any = await walletConnectProvider.request({ method: 'eth_accounts' })
      wallet.provider = walletConnectProvider
      if (sign) {
        await signInWithEthereum()
      }
      window.localStorage.setItem('lastLoginType', 'WalletConnect')
      return list[0]
    }
  }
]
