import { checkChain, ensureSetup, sha3, waitConfirmations } from '@/sdk/base'
import { wallet } from '@/utils/wallet'
import { toChecksumAddress } from 'web3-utils'
import sdk from './pnsSdk'
import api from '@/config/api'
import { getFee, getAcceptor } from '@/sdk/queryFee'
import { substrateTransfer } from '@/sdk/subStrate'
import { Contract, providers, BigNumber } from 'ethers'
import { usdtCountract, usdtAbi } from '@/config/contracts'
import fetch from '@/utils/fetch'
import { getDefaultChain } from '@/config/networks'

/**
 * 注册域名, 立即返回交易hash
 * 需要设置resolver
 * @param domain
 * @param year
 */
export async function registerDomainSimple (domain: string, year: number): Promise<string> {
  await ensureSetup(true)
  await checkChain()
  const account = wallet.accountId
  const duration = year * 86400 * 365
  const keysHash = [sha3('ETH')]
  const recordsVal = [toChecksumAddress(account)]
  console.log(keysHash, recordsVal)
  const res = await sdk.registerWithConfig(domain.domainEllipsisSuffix(), account, duration, 0, keysHash, recordsVal)
  return (res as any).hash
}

// 用兑换码注册，立即返回hash
export async function registDomainByRedeemCodeAnySimple (domain: string, code: string): Promise<string> {
  await ensureSetup(true)
  await checkChain()
  const account = await wallet.accountId
  const duration = 86400 * 365
  const label = domain.domainEllipsisSuffix()
  // const url = `${api}/pns_api/domains/redeem_name_redeem_code`
  // const api = process.env.NODE_ENV === 'development'
  //   ? 'https://pns-api.test-pns-link.com/pns_api/domains/redeem_name_redeem_code'
  // //   : 'https://api-rpc.pns.link/pns_api/domains/redeem_name_redeem_code'
  // const getCode = await fetch(url, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify({ label, shortcode: code.toUpperCase(), address: account })
  // })

  const getCode = await fetch.post(`${api}/pns_api/domains/redeem_name_redeem_code`, { label, shortcode: code.toUpperCase(), address: account })

  const resJons = getCode.data

  if (resJons.result === 'error') {
    throw new Error(resJons.message || 'Redeem code is unavailable')
  }

  return resJons.hash
}

/**
 * dot/ksm支付，立即返回块hash
 * @param domain 域名
 * @param payToken 支付币种
 * @param year 注册年限
 * @param paymentAddress 支付dot/ksm钱包地址
 */
export async function registDomainBySubstrateSimple (
  domain: string,
  payToken: 'dot' | 'ksm',
  year: number,
  paymentAddress: string
): Promise<any> {
  await ensureSetup(true)
  // 锁定价格
  const fee = await getFee(domain, false, year, paymentAddress, true)
  const targetFee = fee[payToken]
  const transferAmount = targetFee.totalCurrencyAmount as string
  // const transferAmount = payToken === 'ksm' ? '0.1' : '1.0'
  const acceptor = await getAcceptor(payToken)
  const msgParams = {
    types: {
      EIP712Domain: [],
      Data: [
        { name: 'Message', type: 'string' }
      ]
    },
    domain: {},
    primaryType: 'Data',
    message: {
      Message: `I'm registering a domain name from app.pns.link:${domain}`
    }
  }
  // eth 签名
  const signature = await wallet.provider.request({
    method: 'eth_signTypedData_v3',
    params: [wallet.accountId, JSON.stringify(msgParams)]
  })
  console.log(signature)
  // if (signature) return

  const blockHash = await substrateTransfer(paymentAddress, acceptor, transferAmount, payToken)
  // const verifyTransfer = await fetch(`${api}/pns_api/domains/transaction_verify`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify({
  //     name: domain.toLowerCase().domainEllipsisSuffix(),
  //     pay: payToken,
  //     from: paymentAddress,
  //     owner: wallet.accountId,
  //     amount: transferAmount,
  //     duration: (year * 86400 * 365).toString(),
  //     block_hash: blockHash,
  //     signature: signature,
  //     pay_type: 'pay'
  //   })
  // })

  const verifyTransfer = await fetch.post(`${api}/pns_api/domains/transaction_verify`, {
    name: domain.toLowerCase().domainEllipsisSuffix(),
    pay: payToken,
    from: paymentAddress,
    owner: wallet.accountId,
    amount: transferAmount,
    duration: (year * 86400 * 365).toString(),
    block_hash: blockHash,
    signature: signature,
    pay_type: 'pay'
  })

  const resJons = verifyTransfer.data
  console.log(resJons)
  if (resJons.result !== 'ok') {
    throw new Error(resJons.message)
  }

  return resJons.hash
}

export async function transfer (from: string, to: string, amountHex: string) {
  const tx = await wallet.provider.request({
    method: 'eth_sendTransaction',
    params: [
      {
        from,
        to,
        value: amountHex
      }
    ]
  })

  return tx
}

export function amountToWei (amount: string, decimals: number, hex = false): string {
  let size = 0
  const amountArray = amount.split('.')
  if (amountArray.length > 1) {
    if (Number(amountArray[1].length) !== 0) {
      size = amountArray[1].length
    }
  }

  const amountToInt = BigNumber.from(Math.round(Number(amount) * 10 ** size).toString())
  const amountToWei = amountToInt.mul(BigNumber.from((10 ** (decimals - size)).toString()).toString())
  return hex ? amountToWei.toHexString() : amountToWei.toString()
}

/**
 * 用于其他链注册, 支持：Bsc和Polygon
 * @param domain
 * @param year
 * @param symbol
 */
export async function NonMoonbeamRegister (domain: string, year: number, symbol: string): Promise<string> {
  // 判断并切换链
  await ensureSetup(true)
  if (process.env.VUE_APP_BUILD_ENV === 'daospace') {
    await checkChain('0x' + (31337).toString(16))
  } else if (symbol === 'BNB') {
    await checkChain('0x' + (56).toString(16))
  } else if (symbol === 'MATIC') {
    await checkChain('0x' + (137).toString(16))
  } else {
    throw new Error('[NonMoonbeamRegister]: Not supported chain')
  }
  const account = wallet.accountId + ''

  // 锁定并获取价格
  const fee = await getFee(domain, false, year, account, true)

  const msgParams = {
    types: {
      EIP712Domain: [],
      Data: [
        { name: 'Message', type: 'string' }
      ]
    },
    domain: {},
    primaryType: 'Data',
    message: {
      Message: `I'm registering a domain name from app.pns.link:${domain}`
    }
  }

  const targetFee = fee[symbol.toLowerCase()]
  const transferAmount = targetFee.totalCurrencyAmount as string
  const transferAmountHex = amountToWei(transferAmount, 18, true)
  const acceptor = await getAcceptor('eth')

  // eth 签名
  const signature = await wallet.provider.request({
    method: 'eth_signTypedData_v3',
    params: [wallet.accountId, JSON.stringify(msgParams)]
  })

  // 转账
  const txHash = await transfer(account, acceptor, transferAmountHex)
  // const verifyTransfer = await fetch(`${api}/pns_api/domains/transaction_verify`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify({
  //     name: domain.toLowerCase().domainEllipsisSuffix(),
  //     pay: symbol.toLowerCase(),
  //     from: account,
  //     owner: wallet.accountId,
  //     amount: transferAmount,
  //     duration: (year * 86400 * 365).toString(),
  //     block_hash: txHash,
  //     signature: signature,
  //     pay_type: 'pay'
  //   })
  // })

  await waitConfirmations(txHash)
  const verifyTransfer = await fetch.post(`${api}/pns_api/domains/transaction_verify`, {
    name: domain.toLowerCase().domainEllipsisSuffix(),
    pay: symbol.toLowerCase(),
    from: account,
    owner: wallet.accountId,
    amount: transferAmount,
    duration: (year * 86400 * 365).toString(),
    block_hash: txHash,
    signature: signature,
    pay_type: 'pay'
  })

  const resJons = verifyTransfer.data
  if (resJons.result !== 'ok') {
    throw new Error(resJons.message)
  }

  return txHash
}

/**
 * usdt注册
 * @param domain
 * @param year
 * @param symbol
 */
export async function usdtRegister (domain: string, year: number, symbol: string): Promise<string> {
  // 判断并切换链
  await ensureSetup(true)
  let usdtContractInfo: [string, number]
  if (process.env.VUE_APP_BUILD_ENV === 'daospace') {
    await checkChain('0x' + (31337).toString(16))
    usdtContractInfo = usdtCountract[31337]
  } else if (symbol === 'USDT_BSC') {
    await checkChain('0x' + (56).toString(16))
    usdtContractInfo = usdtCountract[56]
  } else if (symbol === 'USDT_POLYGON') {
    await checkChain('0x' + (137).toString(16))
    usdtContractInfo = usdtCountract[137]
  } else {
    throw new Error('[NonMoonbeamRegister]: Not supported chain')
  }

  const account = wallet.accountId + ''

  // 锁定并获取价格
  const fee = await getFee(domain, false, year, account, true)

  const msgParams = {
    types: {
      EIP712Domain: [],
      Data: [
        { name: 'Message', type: 'string' }
      ]
    },
    domain: {},
    primaryType: 'Data',
    message: {
      Message: `I'm registering a domain name from app.pns.link:${domain}`
    }
  }
  const targetFee = fee[symbol.toLowerCase()]
  const transferAmount = targetFee.totalCurrencyAmount as string
  const acceptor = await getAcceptor('eth')

  // eth 签名
  const signature = await wallet.provider.request({
    method: 'eth_signTypedData_v3',
    params: [wallet.accountId, JSON.stringify(msgParams)]
  })

  // 转账
  const transferAmountToWei = amountToWei(transferAmount, usdtContractInfo[1])
  const usdtContract = new Contract(usdtContractInfo[0], usdtAbi, new providers.Web3Provider(wallet.provider).getSigner())
  const tx = await usdtContract.transfer(acceptor, transferAmountToWei)

  // const verifyTransfer = await fetch(`${api}/pns_api/domains/transaction_verify`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify({
  //     name: domain.toLowerCase().domainEllipsisSuffix(),
  //     pay: symbol.toLowerCase(),
  //     from: account,
  //     owner: wallet.accountId,
  //     amount: transferAmount,
  //     duration: (year * 86400 * 365).toString(),
  //     block_hash: tx.hash,
  //     signature: signature,
  //     pay_type: 'pay'
  //   })
  // })

  await waitConfirmations(tx.hash)
  const verifyTransfer = await fetch.post(`${api}/pns_api/domains/transaction_verify`, {
    name: domain.toLowerCase().domainEllipsisSuffix(),
    pay: symbol.toLowerCase(),
    from: account,
    owner: wallet.accountId,
    amount: transferAmount,
    duration: (year * 86400 * 365).toString(),
    block_hash: tx.hash,
    signature: signature,
    pay_type: 'pay'
  })

  const resJons = verifyTransfer.data
  if (resJons.result !== 'ok') {
    throw new Error(resJons.message)
  }

  return tx.hash
}
