
import { siteConfig } from '@/config/site'
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component({})
export default class Dialog extends Vue {
  @Prop(String) private title!: string

  @Prop({
    type: String,
    default () {
      return siteConfig.isMobile ? '320px' : '425px'
    }
  }) private width!: string

  @Prop(Boolean) private value!: string

  @Emit('input')
  private handlerVisibleChange (visible: boolean) {
    return visible
  }
}
