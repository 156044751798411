
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class FrameHeader extends Vue {
  private opacity = 0

  // 是否使用白色主题
  private get useLightTheme () {
    // 通过路由配置设置
    if (this.$route.meta?.useGhostTheme) {
      if (this.opacity === 1) {
        return true
      }
      return false
    }
    return true
  }

  private get headerClasses () {
    const list: string[] = []
    if (this.useLightTheme) {
      list.push('header-theme-light')
    }
    return list
  }

  private get headerStyles () {
    const styles: Record<string, unknown> = {}
    if (this.$route.meta?.useGhostTheme) {
      styles.background = `rgba(255, 255, 255, ${this.opacity})`
    }
    return styles
  }

  private computedOpacity () {
    this.opacity = Math.max(0, Math.min(1, Math.abs(window.scrollY) / 60))
  }

  mounted (): void {
    window.addEventListener('scroll', this.computedOpacity)
    window.addEventListener('resize', this.computedOpacity)
    window.addEventListener('orientationchange', this.computedOpacity)
    const unwatch = this.$watch('useLightTheme', this.computedOpacity)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('scroll', this.computedOpacity)
      window.removeEventListener('resize', this.computedOpacity)
      window.removeEventListener('orientationchange', this.computedOpacity)
      unwatch()
    })
    this.computedOpacity()
  }
}
