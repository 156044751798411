import { lang } from '@/lang'
import { errorMessage, successMessage } from './message'
import Clipboard from 'clipboard'

export function paraFormat (str: string[], opts?: {
  primaryColors?: string[];
}): string {
  let text = str.map(v => `<div>${v}</div>`).join('')
  if (opts?.primaryColors) {
    const re = RegExp(opts.primaryColors.join('|'), 'g')
    text = text.replace(re, str => `<span class="text-primary">${str}</span>`)
  }
  return text
}

export function copyText (text: string): void {
  if (!Clipboard.isSupported()) {
    errorMessage(lang('MESSAGE_NOSUPPORT_CLIPBOARD'))
    return
  }

  const btn = document.createElement('button')
  btn.dataset.clipboardText = text
  const clipboard = new Clipboard(btn)

  clipboard.on('success', (e) => {
    successMessage(lang('MESSAGE_COPY_SUCCESS'))
    e.clearSelection()
    clipboard.destroy()
  })

  clipboard.on('error', () => {
    errorMessage(lang('MESSAGE_COPY_ERROR'))
    clipboard.destroy()
  })

  btn.click()
}

export function shortAddr (val: string, length = 5): string {
  return val.substr(0, length) + '...' + val.substr(38)
}
