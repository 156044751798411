
import { Vue, Component } from 'vue-property-decorator'
import FrameLogo from '../FrameLogo.vue'
import FrameHeader from '../FrameHeader.vue'
import ConnectWallet from './ConnectWallet.vue'
import SearchInput from './SearchInput.vue'
import SocialMediaConsole from './SocialMediaConsole.vue'
import { wallet } from '@/utils/wallet'

@Component({
  components: {
    FrameLogo,
    FrameHeader,
    ConnectWallet,
    SearchInput,
    SocialMediaConsole
  }
})
export default class FrameHeaderConsole extends Vue {
  private async disconnect () {
    await wallet.disConnect()
  }
}
