/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  ManagerOwnable,
  ManagerOwnableInterface,
} from "../ManagerOwnable";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "manager",
        type: "address",
      },
      {
        indexed: true,
        internalType: "bool",
        name: "role",
        type: "bool",
      },
    ],
    name: "ManagerChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "oldRoot",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newRoot",
        type: "address",
      },
    ],
    name: "RootOwnershipTransferred",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "addr",
        type: "address",
      },
    ],
    name: "isManager",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "root",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "manager",
        type: "address",
      },
      {
        internalType: "bool",
        name: "role",
        type: "bool",
      },
    ],
    name: "setManager",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferRootOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b50600080546001600160a01b031916331790556103a4806100326000396000f3fe608060405234801561001057600080fd5b506004361061004c5760003560e01c80632e5f2c3714610051578063a5e90eee14610066578063ebf0c71714610079578063f3ae241514610099575b600080fd5b61006461005f366004610310565b6100bc565b005b610064610074366004610332565b61018e565b6000546040516001600160a01b0390911681526020015b60405180910390f35b6100ac6100a7366004610310565b6102ba565b6040519015158152602001610090565b6000546001600160a01b0316331461011b5760405162461bcd60e51b815260206004820152601260248201527f63616c6c6572206973206e6f7420726f6f74000000000000000000000000000060448201526064015b60405180910390fd5b600080546040516001600160a01b03808516939216917f767270f8d0f8796a4a3702f6e3e1e2da4e625a3dd9de09288fc319b23a9cfded91a3600080547fffffffffffffffffffffffff0000000000000000000000000000000000000000166001600160a01b0392909216919091179055565b6000546001600160a01b031633146101e85760405162461bcd60e51b815260206004820152601260248201527f63616c6c6572206973206e6f7420726f6f7400000000000000000000000000006044820152606401610112565b6001600160a01b03821660009081526001602052604090205460ff16151581151514156102575760405162461bcd60e51b815260206004820152601160248201527f73616d6520726f6c65206772616e7465640000000000000000000000000000006044820152606401610112565b604051811515906001600160a01b038416907f7da21a82304da61f5e2f4e156225cd8a75452a5f09f2579d11f0e5054103fb7290600090a36001600160a01b03919091166000908152600160205260409020805460ff1916911515919091179055565b6001600160a01b03811660009081526001602052604081205460ff16806102ee57506000546001600160a01b038381169116145b92915050565b80356001600160a01b038116811461030b57600080fd5b919050565b60006020828403121561032257600080fd5b61032b826102f4565b9392505050565b6000806040838503121561034557600080fd5b61034e836102f4565b91506020830135801515811461036357600080fd5b80915050925092905056fea26469706673582212205fa13fd43419cec3d11471d61de34b8c2057427be8f4e941df41e723e8ec3abd64736f6c63430008090033";

export class ManagerOwnable__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0]);
    } else {
      super(...args);
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<ManagerOwnable> {
    return super.deploy(overrides || {}) as Promise<ManagerOwnable>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): ManagerOwnable {
    return super.attach(address) as ManagerOwnable;
  }
  connect(signer: Signer): ManagerOwnable__factory {
    return super.connect(signer) as ManagerOwnable__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): ManagerOwnableInterface {
    return new utils.Interface(_abi) as ManagerOwnableInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ManagerOwnable {
    return new Contract(address, _abi, signerOrProvider) as ManagerOwnable;
  }
}
