import ICON_ETH from '../views/Wrapper/assets/eth.svg'
import ICON_MOONBEAM from '../views/Wrapper/assets/moonbeam.svg'

export function getNetwork (): Common.NetworkItem[] {
  return process.env.VUE_APP_BUILD_ENV === 'daospace' ? [
    // 这里添加测试链信息，一般修改 chainId 和 rpcUrls就可以
    {
      chainId: '0x' + (31337).toString(16),
      chainName: 'Testnet',
      nativeCurrency: {
        name: 'Glimmer',
        symbol: 'GLMR',
        decimals: 18
      },
      rpcUrls: ['https://hardhat.daospace.one'],
      blockExplorerUrls: ['https://moonbeam.moonscan.io']
    }
  ] : [
    {
      chainId: '0x' + (1284).toString(16),
      chainName: 'Moonbeam',
      nativeCurrency: {
        name: 'Glimmer',
        symbol: 'GLMR',
        decimals: 18
      },
      rpcUrls: ['https://rpc.ankr.com/moonbeam'],
      blockExplorerUrls: ['https://moonbeam.moonscan.io']
    }
  ]
}

const chainToPay:Common.NetworkItem[] = [
  {
    chainId: '0x' + (31337).toString(16),
    chainName: 'Testnet',
    nativeCurrency: {
      name: 'Glimmer',
      symbol: 'GLMR',
      decimals: 18
    },
    rpcUrls: ['https://hardhat.daospace.one'],
    blockExplorerUrls: ['https://moonbeam.moonscan.io']
  },
  {
    chainId: '0x' + (1284).toString(16),
    chainName: 'Moonbeam',
    nativeCurrency: {
      name: 'Glimmer',
      symbol: 'GLMR',
      decimals: 18
    },
    rpcUrls: ['https://rpc.ankr.com/moonbeam'],
    blockExplorerUrls: ['https://moonbeam.moonscan.io']
  },
  {
    chainId: '0x' + (56).toString(16),
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18
    },
    rpcUrls: ['https://rpc.ankr.com/bsc'],
    blockExplorerUrls: ['https://bscscan.com/']
  },
  {
    chainId: '0x' + (137).toString(16),
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrls: ['https://rpc.ankr.com/polygon'],
    blockExplorerUrls: ['https://polygonscan.com/']
  }
]

export function getDefaultChain (): string {
  const list = getNetwork()
  return list[0].chainId as string // '0x504'
}

export function getNetworkByChainId (chainId : string): Common.NetworkItem | undefined {
  return chainToPay.find(item => item.chainId === chainId)
}

export function isSupportChain (chainId : string): boolean {
  const target = getNetworkByChainId(chainId)
  return !!target
}

export const icons = {
  Moonbeam: ICON_MOONBEAM,
  Testnet: ICON_ETH
}
