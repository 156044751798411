import { paraFormat } from '@/utils/content'
import { LangMap } from './EN'

export const langCN: LangMap = {
  SEARCHINPUT_PLACEHOLDER: '搜索您的域名',
  SEARCHINPUT_BUTTON_TEXT: '搜索',
  CONNECT_WALLET_BUTTON_TEXT: '连接钱包',
  CONNECT_WALLET_ERROR: '请安装钱包（推荐使用Metamask）后继续操作，或者在钱包APP中打开网站',
  DISCONNECT_WALLET_BUTTON_TEXT: '断开钱包',
  LANDING_SLOGAN_1: paraFormat(['Polkadot', 'Name System.'], { primaryColors: ['o', '\\.'] }),
  LANDING_MODULE_B_TITLE: '什么是PNS项目？',
  LANDING_MODULE_B_CONTENT: paraFormat([
    '新一代基于Polkadot生态的去中心化、安全、透明、开放多链域名系统。',
    '使用PNS，每个用户都可以拥有其链上唯一的域名，并解析为其钱包帐户、智能合约地址、NFT令牌、URL或IPFS地址。PNS是Web3生态系统的通用通行证。'
  ]),
  LANDING_MODULE_B_TITLE_2: '加入我们的订阅列表',
  LANDING_MODULE_B_CONTENT_2: '请加入我们的订阅列表，以确保随时了解到PNS的最新进展！',
  LANDING_MODULE_B_TIP: '当我们正式上线时将会及时通知您',
  LANDING_MODULE_B_EMAIL: '您的邮箱',

  NETWORK_SELECTOR_TIP: '选择网络',
  NETWORK_SELECTOR_SUCCESS_TIP: '网络成功切换至',
  NETWORK_SELECTOR_FAIL_TIP: '网络切换失败',
  NETWORK_CAN_NOT_SWITCH: '无法自动切换网络，请在钱包应用里手动切换',

  LANDING_MODULE_C_TITLE: 'PNS功能',
  LANDING_MODULE_C_1_TITLE: '化繁为简',
  LANDING_MODULE_C_2_TITLE: '数字身份',
  LANDING_MODULE_C_3_TITLE: '去中心化',
  LANDING_MODULE_C_4_TITLE: '集成NFT',
  LANDING_MODULE_C_5_TITLE: '可扩展解析器',
  LANDING_MODULE_C_6_TITLE: 'DNS解析',

  LANDING_MODULE_C_1_CONTENT: paraFormat(['不再需要记忆冗长且繁多的网址，一个.dot域名即可集合所有长链接。'], { primaryColors: ['\\.dot'] }),
  LANDING_MODULE_C_2_CONTENT: 'PNS可集成不同区块链下的账户信息，构建数字世界的信任钥匙，保障信息和资产的安全。',
  LANDING_MODULE_C_3_CONTENT: '配置完成PNS Pallat，每个节点都成为PNS的服务器，并可提供去中心化的DNS服务。',
  LANDING_MODULE_C_4_CONTENT: 'PNS域名具有NFTs属性，可绑定NFT地址，便捷展现NFT相关内容，域名本身亦可作为NFT进行交易。',
  LANDING_MODULE_C_5_CONTENT: 'PNS名称可以解析为区块链帐户、合同地址、IPFS地址、URL、电子邮件或社交网络帐户',
  LANDING_MODULE_C_6_CONTENT: 'PNS名称可以通过DNS网关访问，并在浏览器中使用，无需插件。',

  LANDING_MODULE_D_TITLE: '使用场景',
  LANDING_MODULE_D_1_TITLE: '一个名字代表一个长地址',
  LANDING_MODULE_D_2_TITLE: '通过可读名称访问DApp',
  LANDING_MODULE_D_3_TITLE: '统一多链',
  LANDING_MODULE_D_4_TITLE: 'NFT支持',
  LANDING_MODULE_D_5_TITLE: '管理子域名',

  LANDING_MODULE_D_1_CONTENT: '不再有烦人的无法读取的地址。将清晰易懂的姓名绑定到您的钱包帐户、添加联系人和转账代币。',
  LANDING_MODULE_D_2_CONTENT: '使用PNS名称访问DApp合同地址。',
  LANDING_MODULE_D_3_CONTENT: paraFormat([
    '使用单个PNS名称统一多个链上标识。',
    '即使您在Acala、Litentry、Ethereum等网站上拥有帐户，也要使用一个名称来管理所有帐户。'
  ]),
  LANDING_MODULE_D_4_CONTENT: '交易和交换PNS名称为NFT。',
  LANDING_MODULE_D_5_CONTENT: '每个域名都可以设置子域名，目前一个域名可以设置20个子域名，除不能增加下一级子域名外，其他功能和主域名一样。',

  LANDING_MODULE_F_TITLE: '加入我们的社区',

  LANDING_SLOGAN_2: paraFormat([
    '在这里找到',
    '您的波卡域名。'
  ], { primaryColors: ['。'] }),

  LANDING_LINK_1_TITLE: '常用',
  LANDING_LINK_2_TITLE: '技术',
  LANDING_LINK_3_TITLE: '社区',
  LANDING_LINK_4_TITLE: '链接',

  LANDING_LINK_1_ITEM_1: 'FAQ',
  LANDING_LINK_1_ITEM_2: '支持',
  LANDING_LINK_1_ITEM_3: '构建',

  LANDING_LINK_2_ITEM_1: '技术',
  LANDING_LINK_2_ITEM_2: '路线图',

  LANDING_LINK_3_ITEM_1: '社区',
  LANDING_LINK_3_ITEM_2: '文档',
  LANDING_LINK_3_ITEM_3: '品牌资产',
  LANDING_LINK_3_ITEM_4: '博客',

  LANDING_LINK_4_ITEM_1: 'Twitter',
  LANDING_LINK_4_ITEM_2: 'Github',
  LANDING_LINK_4_ITEM_3: 'Youtube',
  LANDING_LINK_4_ITEM_4: 'Discord',
  LANDING_LINK_4_ITEM_5: 'Medium',
  LANDING_LINK_4_ITEM_6: 'Telegram',

  LANDING_COPYRIGHT: paraFormat(['© 2022 波卡域名系统项目'], { primaryColors: ['波卡域名系统项目'] }),
  LANDING_COPYRIGHT_SUFFIX: '版权所有',

  NEW_LANDING_SLOGAN_1: '波卡域名系统',

  NEW_LANDING_MODULE_B_1: 'PNS正式上线以来',
  NEW_LANDING_MODULE_B_2: '现在已经有将近 <span>10000</span> 域名被注册，有 <span>4400+</span> 人拥有了他们专属的 Web3 数字身份',
  NEW_LANDING_MODULE_B_3: '域名',
  NEW_LANDING_MODULE_B_4: '拥有者',

  NEW_LANDING_MODULE_C_1: '构建服务于整个<span>波卡</span>生态的DID基础设施',
  NEW_LANDING_MODULE_C_2: '唯一姓名',
  NEW_LANDING_MODULE_C_3: '全局唯一的<span>.dot </span>域名，代替了无意义、不可读、不可记忆的冗长地址，建立自主可信的数字身份。',
  NEW_LANDING_MODULE_C_4: '聚合身份',
  NEW_LANDING_MODULE_C_5: 'PNS 可以绑定多链生态下的不同地址，让碎片化的身份聚合起来，更全面立体地展示你自己。',
  NEW_LANDING_MODULE_C_6: '便捷展示',
  NEW_LANDING_MODULE_C_7: '每一个 PNS 域名的用户，都将拥有免费的个人 Web3Profile，只需要在浏览器中输入<span><i>yourpolkaname.dot</i>.site</span> 。',
  NEW_LANDING_MODULE_C_8: '数据集成',
  NEW_LANDING_MODULE_C_9: '链接 Web2 与 Web3，集成链上链下数据，助力游戏、社交、DAO 及社区治理等。',

  NEW_LANDING_MODULE_D_0: '使用场景',
  NEW_LANDING_MODULE_D_1: 'Web3 名片',
  NEW_LANDING_MODULE_D_2: '个人和组织可以通过 PNS 把不同的区块链钱包整合在唯一的 .dot 域名下。展示链上链下的信息，更加立体的展示你是谁，你做过什么',
  NEW_LANDING_MODULE_D_3: '便捷地址',
  NEW_LANDING_MODULE_D_4: '用一个人类可读、可记忆的名字来代替你的地址。在其他 Dapp 中可以直接使用 PNS 域名，不用再输入冗长的地址。',
  NEW_LANDING_MODULE_D_5: 'NFT',
  NEW_LANDING_MODULE_D_6: '艺术家可以利用 PNS 来建立自己的品牌。子域名可以绑定他们创作的 NFT，来提供给买家来验证他们买的是否是该艺术家发行的作品。也可以通过域名来建立 NFT 的作品集。',
  NEW_LANDING_MODULE_D_7: 'DAO',
  NEW_LANDING_MODULE_D_8: '利用子域名作为组织成员的凭证。Zeus 在 Olympus（拥有 olympus.dot）中，可以获得由 olympus.dot 创建的 zeus.abcdao.dot。当 Zeus 拥有这个域名时，你可以相信他是 Olympus 的成员。',
  NEW_LANDING_MODULE_D_9: '增强交互',
  NEW_LANDING_MODULE_D_10: 'PNS 可以作为项目方或个人用户在 Web3 上的统一社交账号门户，可以帮助他人认知你、记忆你和找到你。这将增强用户之间的交互。',
  NEW_LANDING_MODULE_D_11: '开发中',
  NEW_LANDING_MODULE_D_12: '即将上线',
  NEW_LANDING_MODULE_D_13: '计划中',

  NEW_LANDING_MODULE_E_1: '加入我们的社区',
  NEW_LANDING_MODULE_E_2: '加入',
  NEW_LANDING_MODULE_E_3: '加入我们的邮件列表，及时获取最新消息',

  NEW_LANDING_MODULE_F_1: '项目进展',
  NEW_LANDING_MODULE_F_2: '正式版在Moonbeam上线',
  NEW_LANDING_MODULE_F_3: 'Web3profile 上线',
  NEW_LANDING_MODULE_F_4: '基于 PNS 的 DNS 服务器',
  NEW_LANDING_MODULE_F_5: '支持更多链上的资产、社交数据集成',
  NEW_LANDING_MODULE_F_6: '支持 NFT 的域名绑定，社交平台身份认证',
  NEW_LANDING_MODULE_F_7: '增强 IPFS 集成',

  NEW_LANDING_MODULE_G_1: '我们的伙伴',

  NEW_LANDING_MODULE_H_1: '共建web3数字身份生态',
  NEW_LANDING_MODULE_H_2: '开发者 & DApp',
  NEW_LANDING_MODULE_H_3: '我们将为开发者开放集成 PNS 的便捷工具及早期技术支持，欢迎各位开发者在 PNS 的基础上建立各种多样化的 web3 应用。',
  NEW_LANDING_MODULE_H_4: '媒体 & 社区',
  NEW_LANDING_MODULE_H_5: '欢迎任何对 PNS 有兴趣的社区、媒体和个人 KOL 和我们联系，我们非常欢迎与各位媒体和社区共享资源。',
  NEW_LANDING_MODULE_H_6: '社区志愿者',
  NEW_LANDING_MODULE_H_7: '欢迎对 PNS 抱有热情且对web3世界有所了解的社区志愿者加入，让我们一起共建波卡 DID 的生态，发出我们自己的声音。',
  NEW_LANDING_MODULE_H_8: '联系我们',

  NEW_LANDING_MODULE_I_1: 'PNS 让 <span>Polkadot</span> 上的每个人和事更了解彼此。',

  NEW_LANDING_MODULE_J_1: '搜索你的<span>波卡</span>域名',

  HELP_CENTER_TITLE: '帮助中心',
  HELP_CENTER_TIP: '如果有其他疑问请加入, 我们的 <a href="https://discord.com/invite/WfYwxNW5Jn" target="_blank">社区</a> 或者访问 <a href="https://medium.com/@pns.dot/pns-faq-2f53f07a97b7" target="_blank">Medium FAQs</a> 页面.',

  HOME_FOOTER_1: '公司介绍',
  HOME_FOOTER_2: '关于我们',
  HOME_FOOTER_3: '帮助中心',
  HOME_FOOTER_4: '博客',
  HOME_FOOTER_5: '联系我们',
  HOME_FOOTER_6: '订阅我们的邮件，及时获取最新资讯',

  MENU_HOME_NEW_NAMES: '新的域名',
  MENU_HOME_ALL_MY_NAMES: '我的域名',
  MENU_HOME_FAVORITES: '我的收藏',
  MENU_HOME_ABOUT: '关于',
  MENU_MY_NAME_MY_REGISTRANT: '我的注册',
  MENU_MY_NAME_MY_SUBDOMAIN: '我的子域名',
  MENU_MY_NAME_MY_REFERRER: '我的推荐',
  MENU_MY_NAME_CONTROLLER: '控制器',
  MENU_BACK: '返回',

  STATUS_PANEL_WALLET_CONNECT: '连接钱包',
  STATUS_PANEL_WALLET_DISCONNECT: '断开钱包',
  STATUS_PANEL_WALLET_READONLY: '只读',

  DOMAIN_SEARCHPANEL_EXACTLY_MATCH: '完全匹配',
  DOMAIN_STATUS_AVAILABLE: '可注册',
  DOMAIN_STATUS_OWNED: '已拥有',
  DOMAIN_STATUS_UNAVAILABLE: '已被注册',

  DOMAIN_STATUS_GET_WEB3PROFILE: '访问我的 Web3 profile',
  DOMAIN_STATUS_SHARE_CARD: '使用卡片分享',

  DOMAIN_DETAILS_TABS_REGISTET: '注册',
  DOMAIN_DETAILS_TABS_DETAIL: '详情',
  DOMAIN_DETAILS_TABS_SUBDOMAINS: '子域名',

  DOMAIN_NAME_DETAILS_PARENT: '父域名',
  DOMAIN_NAME_DETAILS_OWNER: '拥有者',
  DOMAIN_NAME_DETAILS_CONTROLLER: '控制者',
  DOMAIN_NAME_DETAILS_OPERATOR: '操作者',
  DOMAIN_NAME_DETAILS_EXPIRATION: '到期时间',
  DOMAIN_NAME_DETAILS_RESOLVER: '解析者',
  DOMAIN_NAME_DETAILS_REGISTRANT: '我注册的域名',
  DOMAIN_NAME_DETAILS_SUBDOMAIN: '我的子域名',
  DOMAIN_NAME_DOMAINS: '域名',
  DOMAIN_NAME_EXPIRE_DATE: '到期时间',
  DOMAIN_NAME_LOADING: '获取中...',
  DOMAIN_NAME_OWNER: '拥有者',

  DOMAIN_NAME_DETAILS_BUTTON_TRANSFER: '变更',
  DOMAIN_NAME_DETAILS_BUTTON_SET: '设置',
  DOMAIN_NAME_DETAILS_BUTTON_RENEW: '续费',
  DOMAIN_NAME_DETAILS_BUTTON_REMIND: '提醒我',

  MESSAGE_COPY_SUCCESS: '复制成功！',
  MESSAGE_COPY_ERROR: '复制失败!',
  MESSAGE_NOSUPPORT_CLIPBOARD: '此设备不支持复制到剪贴板！',

  TRANSFER_DIALOG_TITLE: '变更Owner',
  TRANSFER_DIALOG_LABEL: '输入接受者的地址或域名',
  TRANSFER_DIALOG_PLACEHOLDER: '波卡地址或域名',
  TRANSFER_DIALOG_BUTTON_CONFIRM: '变更',
  TRANSFER_DIALOG_BUTTON_CANCEL: '取消',

  MESSAGE_TRANSFER_SUCCESS: '变更成功！',
  MESSAGE_TRANSFER_ERROR: '变更失败！',

  SET_CONTROLLER_DIALOG_TITLE: '设置Operator',
  SET_CONTROLLER_DIALOG_LABEL: '输入一个新的Operator地址',
  SET_CONTROLLER_DIALOG_PLACEHOLDER: 'Operator地址',
  SET_CONTROLLER_DIALOG_BUTTON_CONFIRM: '设置',
  SET_CONTROLLER_DIALOG_BUTTON_CANCEL: '取消',

  MESSAGE_SET_CONTROLLER_SUCCESS: '设置Operator成功！',
  MESSAGE_SET_CONTROLLER_ERROR: '设置控Operator失败！',

  RENEW_DIALOG_TITLLE: '续订到期日',
  RENEW_DIALOG_CONFIRM: '续订',

  RENEW_FORM_PERIOD: '注册期限',
  RENEW_FORM_PERIOD_UNIT: '年',
  RENEW_FORM_PERIOD_TIP: '延长注册期限，以避免每年的燃料费',
  RENEW_FORM_PRICE: '注册费用',
  RENEW_FORM_GAS: '预估燃料费',
  RENEW_FORM_GAS_AT_LEASE: '至少',
  RENEW_FORM_TOTAL: '预估总费用 (注册费 + 年费)',
  RENEW_FORM_GAS_FREE: '燃料费',
  RENEW_FORM_DEPOSIT: '押金',
  RENEW_FORM_ANNUAL: '年费',

  MESSAGE_RENEW_SUCCESS: '续费成功!',
  MESSAGE_RENEW_ERROR: '续费失败!',

  SET_RESOLVER_DIALOG_TITLE: '设置Resolver',
  SET_RESOLVER_DIALOG_LABEL: '输入一个新的Resolver地址',
  SET_RESOLVER_DIALOG_PLACEHOLDER: 'Resolver地址',
  SET_RESOLVER_DIALOG_BUTTON_CONFIRM: '设置',
  SET_RESOLVER_DIALOG_BUTTON_CANCEL: '取消',

  MESSAGE_SET_RESOLVER_SUCCESS: '设置Resolver成功！',
  MESSAGE_SET_RESOLVER_ERROR: '设置Resolver失败！',

  CONNECT_WALLET_DIALOG_TITLE: '链接您的钱包',
  CONNECT_WALLET_DIALOG_LINK: '如何拥有一个钱包？',
  CONNECT_WALLET_DIALOG_TITLE_METAMASK: '链接到Metamask',
  CONNECT_WALLET_DIALOG_TITLE_FOXWALLET: '链接到FoxWallet',
  CONNECT_WALLET_DIALOG_TITLE_WALLETCONNECT: '链接到WalletConnect',
  CONNECT_WALLET_DISCONNECT_SUCCESS: '成功断开链接',

  NAME_DETAILS_TITLE: '域名详情',
  NAME_REGISTER_TITLE: '注册域名',
  NAME_REGISTER_REGISTER: '注册',
  NAME_REGISTER_USE_CODE: '使用兑换码',
  NAME_REGISTER_REDEEMCODE_INVALID: '无效的兑换码',
  NAME_REGISTER_REDEEMCODE_TIME_TOO_LONG: '注册时间不能大于1年',
  NAME_REGISTER_TOO_SHORT: '注册的域名长度必须大于等于#',
  NAME_REGISTER_TOO_LONG: '注册的域名长度必须小于#',

  NAME_REGISTER_INVALID_CHARACTER: '注册的域名包含非法字符',
  NAME_REGISTER_REFERRER_TITTLE: '推荐',
  NAME_REGISTER_REFERRER_LABEL: '域名', // placeholder
  NAME_REGISTER_REFERRER_PLACEHOLDER: '输入推荐者的域名',

  REGISTER_STEPPER_1_TITLE: '申请注册',
  REGISTER_STEPPER_2_TITLE: '等待片刻',
  REGISTER_STEPPER_3_TITLE: '完成注册',

  REGISTER_ACTIONBAR_RETRY: '重新开始',
  REGISTER_ACTIONBAR_MANAGE: '管理域名',
  REGISTER_ACTIONBAR_TWITTER: 'Tweet分享给朋友',
  REGISTER_ACTIONBAR_SHARE: '使用卡片分享',

  REGISTER_STEPPER_1_DISC: paraFormat([
    '您的钱包将打开，您将被要求确认注册所需的一笔交易。'
  ]),
  REGISTER_STEPPER_2_DISC: '需要等待一段时间，以确保其他人没有尝试注册相同的姓名，并在您提出请求后保护您。',
  REGISTER_STEPPER_3_DISC: '',

  MESSAGE_REGISTER_SUCCESS: '注册 # 成功！',

  DOMAIN_RECORD_TITLE: '域名详情',
  DOMAIN_RECORD_ACTION_TEXT: '编辑/新增记录',

  RECORD_FORM_ADDRESS: '地址',
  RECORD_FORM_ADDRESS_DOT: 'DOT',
  RECORD_FORM_ADDRESS_KSM: 'KSM',
  RECORD_FORM_ADDRESS_ETH: 'ETH',
  RECORD_FORM_ADDRESS_BTC: 'BTC',

  RECORD_FORM_CONTENT: '内容',
  RECORD_FORM_CONTENT_IPFS: 'IPFS',

  RECORD_FORM_TEXT_RECORD: '文本记录',
  RECORD_FORM_TEXT_RECORD_EMAIL: 'Email',
  RECORD_FORM_TEXT_RECORD_CNAME: 'CNAME',
  RECORD_FORM_TEXT_RECORD_NOTICE: 'Notice',
  RECORD_FORM_TEXT_RECORD_TWITTER: 'com.twitter',
  RECORD_FORM_TEXT_RECORD_GITHUB: 'com.github',
  RECORD_FORM_TEXT_RECORD_URL: 'Url',
  RECORD_FORM_TEXT_RECORD_AVATAR: 'Avatar',

  RECORD_INPUT_PLACEHOLDER: '未填写',
  RECORD_INPUT_PLACEHOLDER_DOT: '填写DOT地址',
  RECORD_INPUT_PLACEHOLDER_KSM: '填写KSM地址',
  RECORD_INPUT_PLACEHOLDER_ETH: '填写ETH地址',
  RECORD_INPUT_PLACEHOLDER_BTC: '填写BTC地址',

  RECORD_INPUT_PLACEHOLDER_IPFS: '填写IPFS内容',

  RECORD_INPUT_PLACEHOLDER_EMAIL: '填写电子邮箱',
  RECORD_INPUT_PLACEHOLDER_CNAME: '填写CNAME',
  RECORD_INPUT_PLACEHOLDER_NOTICE: '填写通知',
  RECORD_INPUT_PLACEHOLDER_TWITTER: '填写twitter链接',
  RECORD_INPUT_PLACEHOLDER_GITHUB: '填写github链接',
  RECORD_INPUT_PLACEHOLDER_URL: '填写任何链接',
  RECORD_INPUT_PLACEHOLDER_AVATAR: '填写头像链接',

  EDIT_RECORD_TITLE: '编辑记录',
  EDIT_RECORD_FOOTER_INTRO: '添加、删除或编辑一条或多条记录。在一次交易中确认。',
  EDIT_RECORD_BUTTON_CANCEL: '取消',
  EDIT_RECORD_BUTTON_CONFIRM: '保存',

  MESSAGE_EDIT_RECORD_SUCCESS: '编辑记录成功！',
  MESSAGE_EDIT_RECORD_ERROR: '编辑记录失败！',

  SUBDOMAIN_TITLE: '子域名',
  SUBDOMAIN_BUTTON_ADD: '新增子域名',
  SUBDOMAIN_BUTTON_EDIT: '编辑子域名列表',
  SUBDOMAIN_NO_DATA: '还没有添加子域名',
  SUBDOMAIN_EDIT_TIP: '一次删除最多10条子域名',
  SUBDOMAIN_DELETE: '删除子域名',

  SUBDOMAIN_ADD_TITLE: '添加子域名',
  SUBDOMAIN_ADD_INPUT_LABEL: '输入子域名',
  SUBDOMAIN_ADD_INPUT_PLACEHOLDER: '输入子域名和接收地址，按回车键确定',
  SUBDOMAIN_ADD_BTN: '添加',
  SUBDOMAIN_ADD_UPLOAD: '上次CSV文件',
  SUBDOMAIN_ADD_TIPS: 'CSV文件必须包含子域名和接收地址，并且一次最多10条',

  SUBDOMAIN_DIALOG_TITLE: '新增子域名',
  SUBDOMAIN_DIALOG_LABEL: '为您的子域名键入标签',
  SUBDOMAIN_DIALOG_LABEL2: '为您的子域名键入拥有者',
  SUBDOMAIN_DIALOG_PLACEHOLDER: '键入子域名标签',
  SUBDOMAIN_DIALOG_PLACEHOLDER2: '键入地址',
  SUBDOMAIN_DIALOG_BUTTON_CANCEL: '取消',
  SUBDOMAIN_DIALOG_BUTTON_CONFIRM: '保存',

  SUBDOMAIN_DIALOG_DELETE_TITLE: '删除子域名',
  SUBDOMAIN_DIALOG_DELETE_LABEL: '你正在删除子域名：',
  SUBDOMAIN_DIALOG_BUTTON_DELETE: '删除',

  MESSAGE_ADD_SUBDOMAIN_SUCCESS: '您已成功新增子域名！',
  MESSAGE_ADD_SUBDOMAIN_ERROR: '新增子域名失败！',
  MESSAGE_ADDRESS_INVALID_ERROR: '无效的地址',
  MESSAGE_LABEL_INVALID_ERROR: '包含无效的字符',
  MESSAGE_DELETE_SUBDOMAIN_SUCCESS: '您已成功删除子域名！',
  MESSAGE_DELETE_SUBDOMAIN_ERR: '删除子失败',

  USECODE_DIALOG_TITLE: '使用兑换码',
  USECODE_DIALOG_INTRO: '请输入12位兑换码',
  USECODE_DIALOG_CHECK: '检查',

  SUBSTRATE_DIALOG_TITLE: '选择钱包地址',
  SUBSTRATE_DIALOG_INTRO: '你正在使用 # 支付, 请选择一个钱包地址',
  SUBSTRATE_DIALOG_CHECK: '确定',

  MY_NAME_TITLE: '我的地址',
  MY_NAME_NOT_CONNECT: '链接钱包后展示更多信息',
  MY_NAME_GOTO_POLKASCAN: '在Polkascan查看',
  MY_NAME_GOTO_ETHERSCAN: '在区块浏览器查看',
  MY_NAME_REVERSE_RECORD: 'Reverse Record:',
  MY_NAME_NOT_SET: '未设置',
  MY_NAME_FIND_MORE_DOMAIN: '获取更多域名',

  REVERRECORD_DIALOG_TITLE: 'Reverse Record',
  REVERRECORD_DIALOG_DES_1: 'The Reverse Resolution translates an address into a name. ',
  REVERRECORD_DIALOG_DES_2: 'It allows Dapps to show in their interfaces <span>#1</span> rather than the long address: <div>#2</div>',
  REVERRECORD_DIALOG_DES_3: 'If you would like to set up your reverse for a different account, please switch accounts in your dapp browser',
  REVERRECORD_DIALOG_SAVE: '保存',
  REVERRECORD_DIALOG_CANCEL: '取消',
  REVERRECORD_SAVE_SUCCESS: 'Reverse Record 保存成功',

  QRCODE_TITLE: '地址二维码',

  HEADER_MY_NAMES: '我的域名',

  LAUNCH_TIP: '我们尚未正式发布，您可以订阅我们的邮箱列表或者加入我们的社区以保持联系',

  TX_MESSAGE_SEND: '有一笔交易已经发出，请等待交易完成。',
  TX_MESSAGE_SUCCESS: '交易完成',

  TWITTER_VALID_PAGE_TITLE: 'Twitter账号验证',
  TWITTER_VALID_PAGE_STEP_1: '步骤 1',
  TWITTER_VALID_PAGE_STEP_2: '步骤 2',
  TWITTER_VALID_PAGE_STEP_TITLE_1: '发布一篇包含签名的推文',
  TWITTER_VALID_PAGE_STEP_1_DES_1: '你的钱包地址:',
  TWITTER_VALID_PAGE_STEP_1_DES_2: '输入你的Twitter ID:',
  TWITTER_VALID_PAGE_POST: '发布推文',
  TWITTER_VALID_PAGE_CONFIRM: '验证',
  TWITTER_VALID_PAGE_STEP_TITLE_2: '把推文地址粘贴到此处用以验证',
  TWITTER_VALID_PAGE_STEP_URL: '推文地址',
  TWITTER_VALID_BTN: '验证Twitter账号',

  VALID_BTN: '验证地址',

  REFERRER_PAGE_TITLE: '推荐账户',
  REFERRER_PAGE_RULE: '提现规则',
  REFERRER_PAGE_LABEL_ADDRESS: '提款地址',
  REFERRER_PAGE_LABEL_LINK: '推荐链接',
  REFERRER_PAGE_LABEL_CURRENCY: '选择货币',
  REFERRER_PAGE_LABEL_AMOUNT: '提款数量',
  REFERRER_PAGE_LABEL_RATIO: '邀请其他用户注册域名可以获得 # 注册费返利',
  REFERRER_PAGE_LABEL_WITHDRAW_BTN: '提款',
  REFERRER_PAGE_RECORD_TAB_REFERRAL: '推荐记录',
  REFERRER_PAGE_RECORD_TAB_WITHDRAW: '提款记录',
  REFERRER_PAGE_RECORD_LIST_REGISTRANT: '注册',
  REFERRER_PAGE_RECORD_LIST_DOMAIN: '域名',
  REFERRER_PAGE_RECORD_LIST_TIME: '时间',
  REFERRER_PAGE_RECORD_LIST_AMOUNT: '数量',
  REFERRER_PAGE_RECORD_LIST_BALANCE: '账户',
  REFERRER_PAGE_RECORD_LIST_REWARD: '返利',
  REFERRER_PAGE_RECORD_LIST_STATE: '状态',
  REFERRER_PAGE_RECORD_LIST_ADDRESS: '地址',

  RECORD_NFT_TITLE: '绑定NFT',
  RECORD_NFT_LABEL: '点击按钮绑定NFT',
  RECORD_NFT_EDIT_BTN: '编辑/添加 NFT',

  BIND_NFT_TITLE: '设置NFT信息',
  RECORD_NFT_BTN: '保存'
}
