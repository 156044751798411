
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class Panel extends Vue {
  @Prop(String) private title!: string
  @Prop(Boolean) private hideHeader!: boolean

  private get showHeader () {
    if (this.hideHeader) return false
    if (this.title) return true
    return false
  }
}
