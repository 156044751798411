import { web3Accounts, web3Enable, web3FromSource } from '@polkadot/extension-dapp'
import { errorMessage } from '@/utils/message'
import { ApiPromise, WsProvider } from '@polkadot/api'
import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types'
import { Keyring } from '@polkadot/keyring'

const substrateRPC = {
  dot: 'wss://rpc.polkadot.io',
  ksm: 'wss://kusama-rpc.polkadot.io'
}

const substrateDecimal = {
  dot: 10 ** 10,
  ksm: 10 ** 12
}

export async function substrateLogin (): Promise<InjectedAccountWithMeta[]> {
  const extensions = await web3Enable('connect wallet')

  if (extensions.length === 0) {
    console.error('[substrateLogin]:No extension installed')
    errorMessage('No polkadot extension installed')
    throw new Error('No polkadot extension installed')
  }

  return await web3Accounts()
}

export async function substrateTransfer (from: string, to: string, amount: number | string, payToken: 'dot' | 'ksm'):Promise<string> {
  const rpc = substrateRPC[payToken]
  if (!rpc) {
    throw new Error('Invalid payment token')
  }

  const keyring = new Keyring()
  const commonAddressFrom = keyring.encodeAddress(from)
  const commonAddressTo = keyring.encodeAddress(to)
  const wsProvider = new WsProvider(rpc)
  const api = await ApiPromise.create({ provider: wsProvider })
  const accountList = await substrateLogin()

  const accountMeta = accountList.filter((item) => {
    return item.address === commonAddressFrom
  })

  if (!accountMeta.length) {
    throw new Error('Invalid payment address')
  }

  const injector = await web3FromSource(accountMeta[0].meta.source)
  const transferExtrinsic = api.tx.balances.transfer(commonAddressTo,
    Number(amount) * substrateDecimal[payToken])

  // const transferExtrinsic = api.tx.balances.transfer(commonAddressTo, 1)

  return new Promise((resolve, reject) => {
    transferExtrinsic.signAndSend(from, { signer: injector.signer },
      (res) => {
        if (res.status.isInBlock) {
          const hash = res.status.asInBlock.toString()
          console.log(`Completed at block hash #${hash}`)
          resolve(hash)
        } else {
          console.log(`Current status: ${res.status.type}`)
        }
      }).catch((error: any) => {
      console.log(':( transaction failed', error)
      reject(error)
    })
  })
}
