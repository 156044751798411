
import { copyText } from '@/utils/content'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class ClipboardText extends Vue {
  @Prop(String) private text!: string

  private handlerCopy () {
    copyText(this.text)
  }
}
