
import { Vue, Component, Prop } from 'vue-property-decorator'
import Unicons from './Unicons.vue'

@Component({
  components: {
    Unicons,
    IcoIcons: () => import('./IcoIcons.vue')
  }
})
export default class Icon extends Vue {
  @Prop({ type: String, default: 'Unicons' })
  private type!: Common.IconType

  @Prop(String) private name!: string

  @Prop(Boolean) private rotate!: boolean
}
