export const Chains: any = {
  1284: {
    name: "Moonbeam",
    chain: "MOON",
    network: "mainnet",
    rpc: ["https://rpc.api.moonbeam.network"],
    faucets: [],
    nativeCurrency: {
      name: "Glimmer",
      symbol: "GLMR",
      decimals: 18,
    },
    infoURL: "https://moonbeam.network/networks/moonbeam/",
    shortName: "mbeam",
    chainId: 1284,
    networkId: 1284,
  }
};

export interface IContractAddrs {
  pns: string;
  controller: string;
  priceOffController: string;
}

export interface IContractAddrsMap {
  [index: number]: IContractAddrs;
}

export const ContractAddrMap: IContractAddrsMap = {
  1284: {
    pns: "0x7d5F0398549C9fDEa03BbDde388361827cb376D5",
    controller: "0xcD0771139fEE77f6a13E87C245Bc1Df92eAca1b6",
    priceOffController: '0x4EA48B8045889A7426CcD00b3968686010B4cc62'
  },
  31337: {
    pns: "0x5FC8d32690cc91D4c39d9d3abcBD16989F875707",
    controller: "0x0165878A594ca255338adfa4d48449f69242Eb8F",
    priceOffController: "0x0165878A594ca255338adfa4d48449f69242Eb8F",
  }
};

export const PaymentAddrs: any = {
  eth: "0x0b23E3588c906C3F723C58Ef4d6baEe7840A977c",
  avax: "0x0b23E3588c906C3F723C58Ef4d6baEe7840A977c",
  movr: "0x0b23E3588c906C3F723C58Ef4d6baEe7840A977c",
  bsc: "0x0b23E3588c906C3F723C58Ef4d6baEe7840A977c",
  dot: "",
  ksm: "",
};

// export const RPC_URL = "https://api.avax-test.network/ext/bc/C/rpc";
export const RPC_URL = "https://rpc.api.moonbeam.network";

// export const PriceOracleAddr = "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419"; // ETH / USD on mainnet
// export const PriceOracleAddr = "0x5498BB86BC934c8D34FDA08E81D444153d0D06aD"; // AVAX / USD on fuji
// export const PriceOracleAddr = "0x3f8BFbDc1e79777511c00Ad8591cef888C2113C1"; // MOVR / USD on MOVR
export const PriceOracleAddr = "0xdb4890df1f92Cf3915f4d8230655185902a61061"; // GLMR / USD on GLMR

export const GraphUrl: any = {
  31337: "https://ensgraph.daospace.one/subgraphs/name/graphprotocol/pns",
  1284: "https://moonbeamgraph.test-pns-link.com/subgraphs/name/graphprotocol/pns"
};

export const PnsApi = "https://api-rpc.pns.link";

export const CometAddr = '0x4692dBe7F7487a6E2eaC30DB494Cc55441FEa79C'
