import { Web3Provider } from '@ethersproject/providers'
import api from '@/config/api'
import { wallet } from '@/utils/wallet'
import fetch from '@/utils/fetch'
import { setWalletAuth } from '@/service/auth'

export async function createSiweMessage (address: string, statement: string): Promise<string> {
  const domain = window.location.host
  const origin = window.location.origin
  const res = await fetch.get(`${api}/pns_api/sessions/nonce`)
  const nonce: any = res.data.nonce;
  (window as any).nonce = nonce
  return `${domain} wants you to sign in with your Ethereum account:
${address}

${statement}

URI: ${origin}
Version: 1
Chain ID: 1284
Nonce: ${nonce}
Issued At: ${new Date().toISOString()}`
}

export async function signInWithEthereum (customProvider?: any): Promise<string> {
  const provider = customProvider || wallet.provider
  if (!provider) {
    throw new Error('Please connect wallet to continue')
  }
  const web3Provider = new Web3Provider(provider)
  const signer = web3Provider.getSigner()
  const loginAddress = await signer.getAddress()
  const message = await createSiweMessage(
    loginAddress,
    'Sign in with Ethereum to the app.'
  )
  const signature = await signer.signMessage(message)
  const res = await fetch.post(`${api}/pns_api/sessions/sign_in`, { message, signature }, false, {
    headers: {
      'Content-Type': 'application/json'
    }
  })

  if (res.data.result !== 'ok') {
    throw new Error(res.data.message)
  }

  setWalletAuth(loginAddress, res.data.auth_token)

  return res.data.auth_token
}
