import { siteConfig } from '@/config/site'
import { langEN, LangMap } from './EN'
import { langCN } from './CN'

const allLang: Record<string, LangMap> = {
  EN: langEN,
  CN: langCN
}

export function lang (key: keyof LangMap): string {
  const currentLang = allLang[siteConfig.lang]
  return currentLang[key]
}
