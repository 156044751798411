
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component({})
export default class InputNumber extends Vue {
  @Prop(Number) private value!: number
  @Prop(Number) private min!: number
  @Prop(Number) private max!: number

  @Prop({ type: Boolean, default: true })
  private stepStrictly!: boolean

  @Emit('input')
  private handlerInput (value: number) {
    return value
  }
}
