export function checkIsMobile (): boolean {
  return Math.min(screen.width, window.innerWidth) <= 750
}

export function observeDevice (func: (info: {
  isMobile: boolean;
}) => unknown): void {
  const handler = () => {
    func({
      isMobile: checkIsMobile()
    })
  }

  window.addEventListener('resize', handler)
  window.addEventListener('orientationchange', handler)
  handler()
}
