
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class TextAction extends Vue {
  @Prop(String) private text!: string
  @Prop(String) private suffixIcon!: string
  @Prop(String) private suffixType!: string
  @Prop(Boolean) private reverse!: boolean
}
