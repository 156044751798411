
import { Vue, Component, Prop } from 'vue-property-decorator'
import paymentToken from '@/config/paymentToken'

@Component({})
export default class CurrencySelector extends Vue {
  @Prop(String) private value!: string

  @Prop(Boolean) private isRenew!: boolean

  // 币种选项配置
  private get items (): Common.MenuItem<Common.Currentcy>[] {
    return paymentToken
  }

  // 当前选中币种
  private get selectedItem () {
    const index = this.items.findIndex(item => item.id === this.value)
    const item = this.items[index >= 0 ? index : 0]
    return item
  }

  private get selected () {
    return this.selectedItem?.id || ''
  }

  private set selected (id: string) {
    this.$emit('input', id)
  }

  private get selectedLabel () {
    return this.selectedItem?.title || ''
  }

  private handlerCommand (symbol: string) {
    this.selected = symbol
  }
}
