/* eslint-disable no-extend-native */

Object.defineProperties(String.prototype, {
  domainEllipsisSuffix: {
    enumerable: false,
    value (this: string) {
      return this.replace(/\.dot$/, '')
    }
  },

  domainComplementSuffix: {
    enumerable: false,
    value (this: string) {
      if (!/\.dot$/.test(this)) {
        return `${this}.dot`
      }
      return this
    }
  }
})
