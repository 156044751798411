
import { lang } from '@/lang'
import { Vue, Component } from 'vue-property-decorator'
import { isBanFeature } from '@/utils/common'
import MenuItem from '../components/MenuItem.vue'

@Component({
  components: {
    MenuItem
  }
})
export default class ModuleB extends Vue {
  private get show () {
    return !isBanFeature()
  }

  private goToHome () {
    window.open('https://www.pns.link')
  }

  private get menuConfig (): Common.MenuItem {
    return {
      id: 'home#about',
      icon: 'smile',
      title: lang('MENU_HOME_ABOUT')
    }
  }
}
