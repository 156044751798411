
import { lang } from '@/lang'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { RecordRawOption } from './types'
import polkadotFormat, { FormatItem } from './polkadot-format'
import { Keyring } from '@polkadot/keyring'
import ClipboardInputDot from '@/components/ClipboardInput/ClipboardInputDot.vue'

@Component({
  components: { ClipboardInputDot }
})
export default class RecordRawDot extends Vue {
  @Prop({ type: Object, required: true })
  private item!: RecordRawOption

  @Prop(Boolean) private readonly!: boolean

  private selectFormat = this.polkadotFormat[1] // 默认显示polkadot格式

  private get shotAddress () {
    if (!this.item.value) return ''

    try {
      const keyring = new Keyring()
      const raw = keyring.encodeAddress(this.item.value, this.selectFormat.ss58Format)
      return raw.substr(0, 22) + '...' + raw.substr(-4)
    } catch (e) {
      return this.item.value
    }
  }

  private get value () {
    try {
      const keyring = new Keyring()
      const raw = keyring.encodeAddress(this.item.value as string, this.selectFormat.ss58Format)
      return raw
    } catch (e) {
      return this.item.value
    }
  }

  private set value (value) {
    this.$set(this.item, 'value', value)
  }

  private get placeholder () {
    if (!this.readonly && this.item.placeholder) {
      return lang(this.item.placeholder)
    }

    return lang('RECORD_INPUT_PLACEHOLDER')
  }

  private get polkadotFormat () {
    return polkadotFormat
  }

  private handlerCommand (item: FormatItem) {
    this.selectFormat = item
  }
}
