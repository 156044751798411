export interface LangItem extends Common.Option<'EN' | 'CN'> {
  shortLabel: string;
  siteLink: string;
}

export const langConfig: LangItem[] = [
  {
    id: 'EN',
    label: 'English',
    shortLabel: 'EN',
    siteLink: 'https://pns-landing.vercel.app/'
  },
  {
    id: 'CN',
    label: '简体中文',
    shortLabel: 'ZH',
    siteLink: 'https://pns-landing-cn.vercel.app/'
  }
]
