import { lang } from '@/lang'

export function getMyNameMenu (): Common.MenuItem[] {
  return [
    {
      id: 'my-name#my-registrant',
      icon: 'at',
      title: lang('MENU_MY_NAME_MY_REGISTRANT'),
      path: '/registrant'
    },
    {
      id: 'my-name#my-subdomain',
      icon: 'search',
      title: lang('MENU_MY_NAME_MY_SUBDOMAIN'),
      path: '/subdomain'
    },
    {
      id: 'my-name#referrer',
      icon: 'referrer',
      title: lang('MENU_MY_NAME_MY_REFERRER'),
      path: '/referrer'
    }
    /*
    {
      id: 'home#favorites',
      icon: 'heart',
      iconType: 'IcoIcons',
      title: lang('MENU_HOME_FAVORITES')
    }
    */
  ]
}
