import { lang } from '@/lang'

export function getHomeMenu (): Common.MenuItem[] {
  return [
    {
      id: 'home#new-names',
      icon: 'search',
      title: lang('MENU_HOME_NEW_NAMES'),
      path: '/search'
    },
    {
      id: 'home#all-my-names',
      icon: 'at',
      title: lang('MENU_HOME_ALL_MY_NAMES'),
      path: '/registrant'
    },
    {
      id: 'my-name#referrer',
      icon: 'referrer',
      title: lang('MENU_MY_NAME_MY_REFERRER'),
      path: '/referrer'
    }
    /*
    {
      id: 'home#favorites',
      icon: 'heart',
      iconType: 'IcoIcons',
      title: lang('MENU_HOME_FAVORITES')
    }
    */
  ]
}
