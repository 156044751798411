
import { lang } from '@/lang'
import { getNetwork, icons, getNetworkByChainId, getDefaultChain, isSupportChain } from '@/config/networks'
import { successMessage, errorMessage } from '@/utils/message'
import { Vue, Component } from 'vue-property-decorator'
import { wallet } from '@/utils/wallet'
import { isBanFeature } from '@/utils/common'
import { EventBus, eventBus } from '@/utils/event'

@Component({})
export default class ModuleD extends Vue {
  private disabled = false

  private get show () {
    return !isBanFeature()
  }

  private icons = icons

  private selectedItem: Common.NetworkItem = {
    chainId: '',
    chainName: '',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: [],
    blockExplorerUrls: []
  }

  private get networkList (): Common.NetworkItem[] {
    return getNetwork()
  }

  private get tipText () {
    return this.selectedItem.chainName ? this.selectedItem.chainName : lang('NETWORK_SELECTOR_TIP')
  }

  private async handlerCommand (networkName: string) {
    const item = this.networkList.find(v => v.chainName === networkName)
    if (!item) return

    this.disabled = true
    try {
      await wallet.switchChain(item)
      successMessage(`${lang('NETWORK_SELECTOR_SUCCESS_TIP')} ${item.chainName}`)
      this.selectedItem = item
      this.disabled = false
    } catch (e) {
      console.error('[app-switchChain]' + e.message)
      errorMessage(lang('NETWORK_SELECTOR_FAIL_TIP'))
      this.disabled = false
    }
  }

  private async setSelectedItem () {
    const chainId = getDefaultChain()
    const selected = getNetworkByChainId(chainId) as Common.NetworkItem
    console.log('selected', selected)
    this.selectedItem = selected
  }

  async mounted (): Promise<void> {
    await this.setSelectedItem()

    // this.$eventBus.on(EventBus.Types.WALLET_SWITCH_CHAIN, this.setSelectedItem)
    // this.$eventBus.on(EventBus.Types.WALLET_CONNECT_SUCCEED, this.setSelectedItem)
    // this.$once('hook:beforeDestroy', () => {
    //   this.$eventBus.off(EventBus.Types.WALLET_SWITCH_CHAIN, this.setSelectedItem)
    //   this.$eventBus.off(EventBus.Types.WALLET_CONNECT_SUCCEED, this.setSelectedItem)
    // })
  }
}
