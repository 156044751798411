
import { Vue, Component } from 'vue-property-decorator'
import { lang } from '@/lang'
import { isBanFeature } from '@/utils/common'

@Component({})
export default class ModuleE extends Vue {
  private get isMyNamePage (): boolean {
    console.log('this.$route.matched', this.$route.matched)
    return this.$route.matched[1] && this.$route.matched[1].path === '/my-names'
  }

  private get show () {
    return !isBanFeature()
  }

  private get text (): string {
    return lang('MENU_BACK')
  }

  private goback ():void {
    window.history.back()
  }
}
