
import { Vue, Component, Emit } from 'vue-property-decorator'
import { isBanFeature } from '@/utils/common'
import SearchButton from './SearchButton.vue'

@Component({
  components: {
    SearchButton
  }
})
export default class SearchInput extends Vue {
  private value = ''

  @Emit('confirm')
  private handlerConfirm () {
    if (isBanFeature()) {
      return
    }

    if (this.value === this.$route.query.keyword) return

    if (this.value) {
      this.$router.push({
        name: 'search',
        query: {
          keyword: this.value
        }
      })
        .catch(e => console.log('[router]:' + e.message))
    }
    return this.value
  }

  private get placeholder () {
    return this.$lang('SEARCHINPUT_PLACEHOLDER')
  }
}
