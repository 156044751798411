import { render, staticRenderFns } from "./CodeInput.vue?vue&type=template&id=1370106a&scoped=true&"
import script from "./CodeInput.vue?vue&type=script&lang=ts&"
export * from "./CodeInput.vue?vue&type=script&lang=ts&"
import style0 from "./CodeInput.vue?vue&type=style&index=0&id=1370106a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1370106a",
  null
  
)

export default component.exports