
import { lang } from '@/lang'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { RecordRawOption } from './types'
import { verifyAddress } from '@/sdk/domain'
import ClipboardInputTwitter from '@/components/ClipboardInput/ClipboardInputTwitter.vue'

@Component({
  components: { ClipboardInputTwitter }
})
export default class RecordRawTwitter extends Vue {
  @Prop({ type: Object, required: true })
  private item!: RecordRawOption

  @Prop(Boolean) private readonly!: boolean

  private get value () {
    return this.item.value
  }

  private set value (value) {
    this.$set(this.item, 'value', value)
  }

  private get placeholder () {
    if (!this.readonly && this.item.placeholder) {
      return lang(this.item.placeholder)
    }

    return lang('RECORD_INPUT_PLACEHOLDER')
  }

  private get link () {
    if (!this.item.value) return ''

    if (!this.item.value.startsWith('http')) return `https://${this.item.value}`

    return this.item.value
  }

  mounted () :void {
    if (this.$route.query.twitter && !this.readonly) {
      this.value = this.$route.query.twitter as string
    }
  }
}
