
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import QRCodeDialog from './QRCodeDialog.vue'

@Component({
  components: {
    QRCodeDialog
  }
})
export default class QRCode extends Vue {
  @Prop(String) private text!: string

  @Ref() private dialog!: QRCodeDialog

  private handleClick (): void {
    this.dialog.open(this.text)
  }
}
