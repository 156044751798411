
import { Component, Vue } from 'vue-property-decorator'
import { lang } from '@/lang'

@Component({
  components: {}
})
export default class ModuleF extends Vue {
  private get text () {
    return lang('LANDING_MODULE_F_TITLE')
  }

  private qrCodeActive = false

  private transfer = true

  private handleWechat () {
    if (this.qrCodeActive) {
      this.transfer = true
      setTimeout(() => {
        this.qrCodeActive = false
      }, 300)
    } else {
      this.qrCodeActive = true
      setTimeout(() => {
        this.transfer = false
      }, 200)
    }
  }
}
