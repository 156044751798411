// 验证域名格式
import { isAddress, toChecksumAddress } from 'web3-utils'
import { decodeAddress, encodeAddress } from '@polkadot/keyring'
import { hexToU8a, isHex } from '@polkadot/util'
import { maxLength, minLength } from '@/config/domainLength'

export function verifyDomain (domain: string): string {
  // 字符串包含.dot
  const name = domain.endsWith('.' + 'dot') ? domain : `${domain}.dot`

  // 最短7位,含.dot
  if (name.length < (minLength + 4)) {
    return 'too short'
  }

  // 最长32位,含.dot
  if (name.length > (maxLength + 4)) {
    return 'too long'
  }

  if (!checkDomain(name, { allowUnicode: false, subdomain: false })) {
    return 'invalid character'
  }
  return ''
}

export function checkDomain (value: string, opts: any) {
  if (typeof value !== 'string') return false
  if (!(opts instanceof Object)) opts = {}
  value = value.toLowerCase()

  if (value.endsWith('.')) {
    value = value.slice(0, value.length - 1)
  }

  if (value.length > 253) {
    return false
  }

  const validChars = /^([a-z0-9-._*]+)$/g
  if (!validChars.test(value)) {
    return false
  }

  const sldMap: any = {
    'ac.cn': true,
    'com.cn': true,
    'edu.cn': true,
    'gov.cn': true,
    'mil.cn': true,
    'net.cn': true,
    'org.cn': true
  }
  const sldRegex = /(.*)\.(([a-z0-9]+)(\.[a-z0-9]+))/
  const matches = value.match(sldRegex)
  let tld = null
  let labels = null

  if (matches && matches.length > 2) {
    if (sldMap[matches[2]]) {
      tld = matches[2]
      labels = matches[1].split('.')
    }
  }

  if (!labels) {
    labels = value.split('.')
    if (labels.length <= 1) return false

    tld = labels.pop()
    const tldRegex = /^(?:xn--)?(?!^\d+$)[a-z0-9]+$/gi
  }

  if (opts.subdomain === false && labels.length > 1) return false

  const isValid = labels.every(function (label: string, index: number) {
    if (opts.wildcard && index === 0 && label === '*' && labels.length > 1) {
      return true
    }

    let validLabelChars = /^([a-zA-Z0-9-_]+)$/g
    if (index === labels.length - 1) {
      validLabelChars = /^([a-zA-Z0-9-]+)$/g
    }

    const doubleDashCount = (label.match(/--/g) || []).length
    const xnDashCount = (label.match(/xn--/g) || []).length
    if (doubleDashCount !== xnDashCount) {
      return false
    }

    const isValid = (
      validLabelChars.test(label) &&
      label.length < 64 &&
      !label.startsWith('-') &&
      !label.endsWith('-')
    )

    return isValid
  })

  return isValid
}

export function checkEthAddress (address: string): boolean {
  return isAddress(address)
}

export function checksumEthAddress (address: string): string {
  return toChecksumAddress(address)
}

export function isSubstrateAddress (address: string) {
  try {
    encodeAddress(isHex(address) ? hexToU8a(address) : decodeAddress(address))
    return true
  } catch (error) {
    return false
  }
}

export const verifyAddress:Common.Verification = {
  ETH: (addr: string): boolean => {
    return checkEthAddress(addr)
  },
  BTC: (addr: string): boolean => {
    return /^(1|3)[a-zA-Z\d]{24,33}$/.test(addr) || /^[^0OlI]{25,34}$/.test(addr)
  },
  DOT: (addr: string): boolean => {
    return isSubstrateAddress(addr)
  },
  KSM: (addr: string): boolean => {
    return isSubstrateAddress(addr)
  },
  cname: (str: string): boolean => {
    return /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/.test(str)
  },
  'text.email': (str: string): boolean => { // 校验2个后缀的邮箱
    return /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/.test(str)
  },
  'text.url': (str: string): boolean => {
    return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'*+,;=.]+$/.test(str)
  },
  'text.avatar': (str: string): boolean => {
    return true
  },
  'text.description': (str: string): boolean => {
    return true
  },
  'text.notice': (str: string): boolean => {
    return true
  },
  'text.keywords': (str: string): boolean => {
    return true
  },
  'text.com.twitter': (str: string): boolean => {
    return verifyAddress['text.url'](str) && str.includes('twitter')
  },
  'text.com.github': (str: string): boolean => {
    return verifyAddress['text.url'](str) && str.includes('github')
  }
}
