export interface FormatItem {
  chainName: string;
  shortName: string;
  ss58Format: number | undefined;
}

const format: FormatItem[] = [
  { chainName: 'Allow use on any chain', shortName: 'Any chain', ss58Format: undefined },
  { chainName: 'Polkadot Relay Chain', shortName: 'Polkadot', ss58Format: 0 },
  { chainName: 'Kusama Relay Chain', shortName: 'Kusama', ss58Format: 2 },
  { chainName: 'Acala', shortName: 'Acala', ss58Format: 10 },
  { chainName: 'Astar', shortName: 'Astar', ss58Format: 5 },
  { chainName: 'Basilisk', shortName: 'Basilisk', ss58Format: 10041 },
  { chainName: 'Bifrost', shortName: 'Basilisk', ss58Format: 6 },
  { chainName: 'Centrifuge Chain', shortName: 'Centrifuge', ss58Format: 36 },
  { chainName: 'Edgeware', shortName: 'Edgeware', ss58Format: 7 },
  { chainName: 'Equilibrium Network', shortName: 'Equilibrium', ss58Format: 68 },
  { chainName: 'Genshiro Network', shortName: 'Genshiro', ss58Format: 67 },
  { chainName: 'Hydradx', shortName: 'Hydradx', ss58Format: 63 },
  { chainName: 'Karura', shortName: 'Karura', ss58Format: 8 },
  { chainName: 'Kulupu', shortName: 'Kulupu', ss58Format: 16 },
  { chainName: 'Parallel', shortName: 'Parallel', ss58Format: 172 },
  { chainName: 'Picasso', shortName: 'Picasso', ss58Format: 49 },
  { chainName: 'Polkadex', shortName: 'Polkadex', ss58Format: 88 },
  { chainName: 'Polymesh', shortName: 'Polymesh', ss58Format: 12 },
  { chainName: 'Sora Network', shortName: 'Sora', ss58Format: 69 },
  { chainName: 'Xxnetwork', shortName: 'Xxnetwork', ss58Format: 55 }
]

export default format
