import Vue from 'vue'
import './index.scss'
import {
  Button,
  Dialog,
  Drawer,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Input,
  InputNumber,
  Checkbox
} from 'element-ui'
import locale from 'element-ui/lib/locale'
import langEN from 'element-ui/lib/locale/lang/en'
import langCN from 'element-ui/lib/locale/lang/zh-CN'
import { siteConfig } from '@/config/site'

locale.use(siteConfig.lang === 'CN' ? langCN : langEN)

Vue.use(Button)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Input)
Vue.use(Dialog)
Vue.use(InputNumber)
Vue.use(Drawer)
Vue.use(Checkbox)
