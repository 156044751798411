
import { copyText } from '@/utils/content'
import { Vue, Component, Prop } from 'vue-property-decorator'
import validPic from './assets/dot_valid.svg'
import { errorMessage, successMessage } from '@/utils/message'
import { wallet } from '@/utils/wallet'
import { web3Accounts, web3Enable, web3FromSource } from '@polkadot/extension-dapp'
import { Keyring } from '@polkadot/keyring'
import { stringToHex } from '@polkadot/util'
import verifyInfo, { verifyDot, getVerifyInfo } from '@/utils/useVerify'
import { lang } from '@/lang'

@Component({})
export default class ClipboardInputDot extends Vue {
  @Prop(String) private value!: string
  @Prop(String) private placeholder!: string
  @Prop(Boolean) private readonly!: boolean
  @Prop(Boolean) private textarea!: boolean
  @Prop(Boolean) private copiable!: boolean
  @Prop(String) private link!: string
  @Prop({ type: [Boolean, Object], default: true })
  private autosize!: unknown

  private get text () {
    return lang('VALID_BTN')
  }

  private get validPic () {
    return validPic
  }

  private get computedValue () {
    return this.value || ''
  }

  private set computedValue (value) {
    this.$emit('input', value)
  }

  private handlerCopy () {
    if (this.value) {
      copyText(this.value)
    }
  }

  private loading = false

  private get walletAddress () {
    return wallet.accountId
  }

  private get isValid () {
    let checkValue = ''
    const keyring = new Keyring()
    try {
      checkValue = keyring.encodeAddress(this.computedValue)
    } catch (e) {}

    if (verifyInfo.dot !== null) {
      return verifyInfo.dot && checkValue === keyring.encodeAddress((verifyInfo.dot as any).walletDot)
    }

    return false
  }

  private async valid () {
    try {
      this.loading = true
      if (!this.walletAddress || this.walletAddress === '') {
        await wallet.connect()
      }

      const msgParams = {
        types: {
          EIP712Domain: [],
          Data: [
            { name: 'Message', type: 'string' }
          ]
        },
        domain: {},
        primaryType: 'Data',
        message: {
          Message: 'I\'m verifying my DOT address'
        }
      }

      // eth 签名
      const signEth = await wallet.provider.request({
        method: 'eth_signTypedData_v3',
        params: [this.walletAddress, JSON.stringify(msgParams)]
      })

      // 链接dot 钱包
      const extensions = await web3Enable('connect wallet')
      if (extensions.length === 0) {
        console.error('[app-polkadot-extension]:No extension installed')
        errorMessage('No polkadot extension installed')
        return
      }
      const addressList = await web3Accounts()
      console.log('addressList', addressList)

      // dot 钱包没有 账号
      if (!addressList.length) {
        console.error('[app-polkadot-extension]:extension must have at least one account')
        errorMessage('Polkadot extension must have at least one account')
      }

      // 查询用户填写的地址是否存在于钱包当中
      const keyring = new Keyring()
      const targetDotAddress = addressList.find((account) => {
        return account.address === keyring.encodeAddress(this.computedValue)
      })
      console.log('targetDotAddress', targetDotAddress)
      if (!targetDotAddress) {
        console.error('[app-polkadot-extension]:Account was not exist in extension')
        errorMessage('Account was not exist in polkadot extension, Make sure you have imported it into the extension')
        return
      }

      // Dot 签名
      const injector = await web3FromSource(targetDotAddress.meta.source)
      const signRaw = injector?.signer?.signRaw
      const { signature } = await signRaw({
        address: targetDotAddress.address,
        data: stringToHex('I\'m verifying my DOT address'),
        type: 'bytes'
      })

      console.log('targetDotAddress.address', targetDotAddress.address)
      console.log('signature', signature)
      const valid = await verifyDot(
        this.$route.params.domain,
        this.walletAddress,
        this.computedValue,
        signEth,
        signature
      )

      if (valid.result === 'ok') {
        successMessage('verify success')
        await getVerifyInfo(this.$route.params.domain)
        console.log('verifyInfo', verifyInfo)
      } else if (valid.result === 'error') {
        if (valid.message) {
          errorMessage(valid.message)
        }
      } else {
        console.error('verify fail')
      }
    } catch (e) {
      console.error(e)
      errorMessage(e.message)
    } finally {
      this.loading = false
    }
  }
}
