
import { Vue, Component } from 'vue-property-decorator'
import ModuleA from './modules/ModuleA.vue'
import ModuleB from './modules/ModuleB.vue'
import ModuleC from './modules/ModuleC.vue'
import ModuleD from './modules/ModuleD.vue'
import ModuleE from './modules/ModuleE.vue'
import ModuleF from './modules/ModuleF.vue'

@Component({
  components: {
    ModuleA,
    ModuleB,
    ModuleC,
    ModuleD,
    ModuleE,
    ModuleF
  }
})
export default class WrapperAside extends Vue {

}
