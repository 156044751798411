
import { lang } from '@/lang'
import { wallet } from '@/utils/wallet'
import { shortAddr } from '@/utils/content'
import { Vue, Component } from 'vue-property-decorator'
import { isBanFeature } from '@/utils/common'

@Component({})
export default class ModuleC extends Vue {
  private get isConnect () {
    return wallet.accountId !== ''
  }

  private get show () {
    return !isBanFeature()
  }

  private get title () {
    return this.isConnect ? shortAddr(wallet.accountId) : lang('STATUS_PANEL_WALLET_CONNECT')
  }

  private async handlerClick () {
    if (wallet.accountId !== '') return
    await wallet.connect()
  }

  private handlerDisconnect () {
    wallet.disConnect()
  }
}
