import Vue from 'vue'
import api from '@/config/api'
import axios from 'axios'
import { checksumEthAddress } from '@/sdk/domain'

interface Response {
  result: string,
  data?: any,
  message?: string
}

interface VerifyInfo {
  twitter: null | {
    domain: string,
    wallet: string,
    twitterId: string,
    signature: string,
    twitterAddress: string
  },
  dot: null | {
    domain: string,
    walletEth: string,
    walletDot: string,
    signatureEth: string,
    signatureDot: string
  },
  eth: null | {
    domain: string,
    wallet: string,
    signature: string
  }
}

const verifyInfo = Vue.observable({
  twitter: null,
  dot: null,
  eth: null
} as VerifyInfo)

export default verifyInfo

export async function getVerifyInfo (domain: string): Promise<VerifyInfo> {
  const res: any = await axios.post(`${api}/pns_api/domains/verify_status`, { domain })

  if (res.data.result === 'ok') {
    verifyInfo.twitter = res.data.data.twitter
    verifyInfo.dot = res.data.data.dot
    verifyInfo.eth = res.data.data.eth
    return verifyInfo
  } else {
    throw new Error('fetch verify status error')
  }
}

export async function verifyTwitter (
  domain: string,
  wallet: string,
  twitterId: string,
  signature: string,
  twitterAddress: string): Promise<Response> {
  wallet = checksumEthAddress(wallet)
  const res: any = await axios.post(`${api}/pns_api/domains/verify_twitter`, { domain, wallet, twitterId, signature, twitterAddress })
  return res.data
}

export async function verifyDot (
  domain: string,
  walletEth: string,
  walletDot: string,
  signatureEth: string,
  signatureDot: string
): Promise<Response> {
  walletEth = checksumEthAddress(walletEth)
  const res: any = await axios.post(`${api}/pns_api/domains/verify_dot_wallet`, { domain, walletEth, walletDot, signatureEth, signatureDot })
  return res.data
}

export async function verifyEth (
  domain: string,
  wallet: string,
  signature: string,
  userId?: string,
  chat?: string
): Promise<Response> {
  wallet = checksumEthAddress(wallet)
  console.log({
    domain,
    wallet,
    signature,
    userId,
    chat
  })
  const res: any = await axios.post(`${api}/pns_api/domains/verify_eth_wallet`, { domain, wallet, signature, user_id: userId, chat })
  return res.data
}
