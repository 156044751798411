/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type { RootOwnable, RootOwnableInterface } from "../RootOwnable";

const _abi = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "oldRoot",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newRoot",
        type: "address",
      },
    ],
    name: "RootOwnershipTransferred",
    type: "event",
  },
  {
    inputs: [],
    name: "root",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferRootOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b50600080546001600160a01b031916331790556101c0806100326000396000f3fe608060405234801561001057600080fd5b50600436106100365760003560e01c80632e5f2c371461003b578063ebf0c71714610050575b600080fd5b61004e61004936600461015a565b61006f565b005b600054604080516001600160a01b039092168252519081900360200190f35b6000546001600160a01b031633146100e7576040517f08c379a000000000000000000000000000000000000000000000000000000000815260206004820152601260248201527f63616c6c6572206973206e6f7420726f6f740000000000000000000000000000604482015260640160405180910390fd5b600080546040516001600160a01b03808516939216917f767270f8d0f8796a4a3702f6e3e1e2da4e625a3dd9de09288fc319b23a9cfded91a3600080547fffffffffffffffffffffffff0000000000000000000000000000000000000000166001600160a01b0392909216919091179055565b60006020828403121561016c57600080fd5b81356001600160a01b038116811461018357600080fd5b939250505056fea26469706673582212201c9d632d8fdff208a2df429a7af32a5ad494ba233d106c993dbbe97d02524cf964736f6c63430008090033";

export class RootOwnable__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0]);
    } else {
      super(...args);
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<RootOwnable> {
    return super.deploy(overrides || {}) as Promise<RootOwnable>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): RootOwnable {
    return super.attach(address) as RootOwnable;
  }
  connect(signer: Signer): RootOwnable__factory {
    return super.connect(signer) as RootOwnable__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): RootOwnableInterface {
    return new utils.Interface(_abi) as RootOwnableInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): RootOwnable {
    return new Contract(address, _abi, signerOrProvider) as RootOwnable;
  }
}
