import { EventEmitter } from 'events'

export class EventBus extends EventEmitter {
  static Types = {
    // 全局搜索框架关键词变化
    GLOBAL_KEYWORD_CHANGE: 'GLOBAL_KEYWORD_CHANGE',
    // 请求重新加载域名信息
    DOMAIN_BASEINFO_REFETCH: 'DOMAIN_BASEINFO_REFETCH',
    // 打开全局连接钱包弹窗
    CONNECT_WALLET_DIALOG_OPEN: 'CONNECT_WALLET_DIALOG_OPEN',
    // 关闭全局连接钱包弹窗
    CONNECT_WALLET_DIALOG_CLOSED: 'CONNECT_WALLET_DIALOG_CLOSED',
    // 钱包连接成功
    WALLET_CONNECT_SUCCEED: 'WALLET_CONNECT_SUCCEED',
    // 钱包连接失败
    WALLET_CONNECT_FAIL: 'WALLET_CONNECT_FAIL',
    // 切换网络
    WALLET_SWITCH_CHAIN: 'WALLET_SWITCH_CHAIN',
    // 执行 Reverse Record Confirm
    REVERSE_RECORD_CONFIRM: 'REVERSE_RECORD_CONFIRM',
    // 页头搜索框keywork改变
    HEADER_SEARCH_KEYWORD_CHANGE: 'HEADER_SEARCH_KEYWORD_CHANGE',
    // 进入批量添加subdomain
    SUBDOMAIN_ADD_BATCH: 'SUBDOMAIN_ADD_BATCH'
  }
}

export const eventBus = new EventBus()
