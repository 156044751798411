
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class Stepper extends Vue {
  @Prop({ type: Array, default: () => [] })
  private stepper!: Common.Step[]

  private statusMap: Record<number, string> = {}

  private errorMap: Record<number, string> = {}

  private getStatus (index: number) {
    return this.statusMap[index]
  }

  private setStatus (index: number, status: string) {
    const map = { ...this.statusMap }
    map[index] = status
    this.statusMap = map
  }

  private getError (index: number) {
    console.log('this.errorMap', this.errorMap)
    return this.errorMap[index] ? `Error: ${this.errorMap[index]}` : ''
  }

  private setError (index: number, status: string) {
    const map = { ...this.errorMap }
    map[index] = status
    this.errorMap = map
  }

  private async execStepper () {
    this.statusMap = {}
    this.errorMap = {}

    let stepData:any = null

    for (let i = 0; i < this.stepper.length; i++) {
      const item = this.stepper[i]
      this.setStatus(i, 'loading')

      if (item.content) {
        try {
          stepData = await item.content(stepData)
        } catch (e) {
          this.setStatus(i, 'error')
          this.setError(i, e.data ? (e.data.message ? e.data.message : (e.message ? e.message : 'transition fail')) : e.message)
          this.$emit('stepError')
          return
        }
      }

      if (this.getStatus(i) === 'error') {
        break
      }

      this.setStatus(i, 'finished')
    }

    this.$emit('stepFinish')
  }

  async mounted (): Promise<void> {
    await this.execStepper()
  }
}
