
import MobileNavBar from '@/components/MobileNavBar/MobileNavBar.vue'
import FrameHeader from '../FrameHeader.vue'
import FrameLogo from '../FrameLogo.vue'
import ActionButton from './ActionButton.vue'
import { Vue, Component, Ref } from 'vue-property-decorator'

@Component({
  components: {
    FrameHeader,
    FrameLogo,
    ActionButton
  }
})
export default class FrameHeaderMobile extends Vue {
  @Ref() private mobileNavBar!: MobileNavBar

  private openMobileNavBar () {
    this.mobileNavBar.open()
  }

  private handlerSearch () {
    const searchPath = '/search'
    if (this.$route.path !== searchPath) {
      this.$router.push({
        path: searchPath,
        query: {
          keyword: this.$route.query.keyword || ''
        }
      }).catch(e => console.log('[router]:' + e.message))
    }
  }
}
