import { wallet } from '@/utils/wallet'
import { checkChain, ensureSetup, sha3, waitConfirmations } from '@/sdk/base'
import sdk from './pnsSdk'
import { checksumEthAddress, verifyAddress } from './verify'
import { getOwner } from './queryDomainInfo'
import { delay } from '@/utils/common'
import { getFee, getAcceptor } from '@/sdk/queryFee'
import { substrateTransfer } from '@/sdk/subStrate'
import api from '@/config/api'
import { getKey } from '@/sdk/lib/sdk'
import { transfer, amountToWei } from '@/sdk/register'
import { usdtCountract, usdtAbi } from '@/config/contracts'
import { Contract, providers } from 'ethers'
import fetch from '@/utils/fetch'

// 设置 Registration
export async function setRegistration (domain: string, target: string): Promise<void> {
  if (target.endsWith('.dot')) {
    const ownerWallet = await getOwner(target)
    const currentUser = wallet.accountId
    if (!ownerWallet) throw new Error(`Given domain ${target} is not exist`)
    if (ownerWallet.toLowerCase() === currentUser) throw new Error(`Can not transfer '${target}' to yourself`)
    target = ownerWallet
  }

  target = checksumEthAddress(target.toLowerCase())
  await ensureSetup(true)
  await checkChain()
  const res = await sdk.transferName(domain, target)
  await waitConfirmations((res as any).hash)
}

// 设置 删除domain
export async function deleteDomain (domain: string): Promise<void> {
  await ensureSetup(true)
  await checkChain()
  const res = await sdk.burn(domain)
  await waitConfirmations((res as any).hash)
}

// 设置 Controller
export async function setOperator (domain: string, addr: string): Promise<void> {
  await ensureSetup(true)
  await checkChain()
  const res = await sdk.approve(domain, addr)
  await waitConfirmations((res as any).hash)
}

// 域名续费
export async function renewDomain (domain: string, year: number): Promise<void> {
  await ensureSetup(true)
  await checkChain()
  const duration = year * 86400 * 365
  const label = domain.domainEllipsisSuffix()
  const res = await sdk.renew(label, duration)
  await waitConfirmations((res as any).hash)
}

// 域名续费: dot/ksm
export async function SubstrateRenewDomain (
  domain: string,
  payToken: 'dot' | 'ksm',
  year: number,
  paymentAddress: string
): Promise<void> {
  await ensureSetup(true)
  const fee = await getFee(domain, true, year, paymentAddress, true)
  const targetFee = fee[payToken]
  const transferAmount = targetFee.totalCurrencyAmount as string
  const acceptor = await getAcceptor(payToken)
  const msgParams = {
    types: {
      EIP712Domain: [],
      Data: [
        { name: 'Message', type: 'string' }
      ]
    },
    domain: {},
    primaryType: 'Data',
    message: {
      Message: `I'm renewing a domain name from app.pns.link:${domain}`
    }
  }
  const signature = await wallet.provider.request({
    method: 'eth_signTypedData_v3',
    params: [wallet.accountId, JSON.stringify(msgParams)]
  })
  const blockHash = await substrateTransfer(paymentAddress, acceptor, transferAmount, payToken)
  const verifyTransfer = await fetch.post(`${api}/pns_api/domains/transaction_verify`, {
    name: domain.toLowerCase().domainEllipsisSuffix(),
    pay: payToken,
    from: paymentAddress,
    owner: wallet.accountId,
    amount: transferAmount,
    duration: (year * 86400 * 365).toString(),
    block_hash: blockHash,
    signature: signature,
    pay_type: 'renew'
  })

  const resJons = verifyTransfer.data
  console.log(resJons)
  if (resJons.result !== 'ok') {
    throw new Error(resJons.message)
  }

  await waitConfirmations(resJons.hash)
}

// 域名续费: bnb/matic
export async function otherChainRenewDomain (
  domain: string,
  payToken: 'BNB' | 'MATIC',
  year: number
): Promise<void> {
  await ensureSetup(true)
  // 判断并切换链
  if (process.env.VUE_APP_BUILD_ENV === 'daospace') {
    await checkChain('0x' + (31337).toString(16))
  } else if (payToken === 'BNB') {
    await checkChain('0x' + (56).toString(16))
  } else if (payToken === 'MATIC') {
    await checkChain('0x' + (137).toString(16))
  } else {
    throw new Error('[otherChainRenewDomain]: Not supported chain')
  }

  const paymentAddress = wallet.accountId + ''

  const fee = await getFee(domain, true, year, paymentAddress, true)

  const targetFee = fee[payToken.toLowerCase()]
  const transferAmount = targetFee.totalCurrencyAmount as string
  const acceptor = await getAcceptor('eth')
  const msgParams = {
    types: {
      EIP712Domain: [],
      Data: [
        { name: 'Message', type: 'string' }
      ]
    },
    domain: {},
    primaryType: 'Data',
    message: {
      Message: `I'm renewing a domain name from app.pns.link:${domain}`
    }
  }
  const signature = await wallet.provider.request({
    method: 'eth_signTypedData_v3',
    params: [wallet.accountId, JSON.stringify(msgParams)]
  })

  const amountToWeiHex = amountToWei(transferAmount, 18, true)
  const blockHash = await transfer(paymentAddress, acceptor, amountToWeiHex)
  await waitConfirmations(blockHash)
  const verifyTransfer = await fetch.post(`${api}/pns_api/domains/transaction_verify`, {
    name: domain.toLowerCase().domainEllipsisSuffix(),
    pay: payToken.toLowerCase(),
    from: paymentAddress,
    owner: wallet.accountId,
    amount: transferAmount,
    duration: (year * 86400 * 365).toString(),
    block_hash: blockHash,
    signature: signature,
    pay_type: 'renew'
  })

  const resJons = verifyTransfer.data
  console.log(resJons)
  if (resJons.result !== 'ok') {
    throw new Error(resJons.message)
  }
}

// 域名续费: usdt
export async function usdtRenewDomain (
  domain: string,
  payToken: 'USDT_BSC' | 'USDT_POLYGON',
  year: number
): Promise<void> {
  await ensureSetup(true)
  // 判断并切换链
  let usdtContractInfo: any
  if (process.env.VUE_APP_BUILD_ENV === 'daospace') {
    await checkChain('0x' + (31337).toString(16))
    usdtContractInfo = usdtCountract[31337]
  } else if (payToken === 'USDT_BSC') {
    await checkChain('0x' + (56).toString(16))
    usdtContractInfo = usdtCountract[56]
  } else if (payToken === 'USDT_POLYGON') {
    await checkChain('0x' + (137).toString(16))
    usdtContractInfo = usdtCountract[137]
  } else {
    throw new Error('[usdtRenewDomain]: Not supported chain')
  }

  const paymentAddress = wallet.accountId + ''

  const fee = await getFee(domain, true, year, paymentAddress, true)

  const targetFee = fee[payToken.toLowerCase()]
  const transferAmount = targetFee.totalCurrencyAmount as string
  const acceptor = await getAcceptor('eth')
  const msgParams = {
    types: {
      EIP712Domain: [],
      Data: [
        { name: 'Message', type: 'string' }
      ]
    },
    domain: {},
    primaryType: 'Data',
    message: {
      Message: `I'm renewing a domain name from app.pns.link:${domain}`
    }
  }
  const signature = await wallet.provider.request({
    method: 'eth_signTypedData_v3',
    params: [wallet.accountId, JSON.stringify(msgParams)]
  })

  // 转账
  const transferAmountToWei = amountToWei(transferAmount, usdtContractInfo[1])
  const usdtContract = new Contract(usdtContractInfo[0], usdtAbi, new providers.Web3Provider(wallet.provider).getSigner())
  const tx = await usdtContract.transfer(acceptor, transferAmountToWei)
  await waitConfirmations(tx.hash)

  const verifyTransfer = await fetch.post(`${api}/pns_api/domains/transaction_verify`, {
    name: domain.toLowerCase().domainEllipsisSuffix(),
    pay: payToken.toLowerCase(),
    from: paymentAddress,
    owner: wallet.accountId,
    amount: transferAmount,
    duration: (year * 86400 * 365).toString(),
    block_hash: tx.hash,
    signature: signature,
    pay_type: 'renew'
  })

  const resJons = verifyTransfer.data
  console.log(resJons)
  if (resJons.result !== 'ok') {
    throw new Error(resJons.message)
  }
}

// 设置 resolver
export async function setResolver (domain: string, addr: string): Promise<void> {
  await ensureSetup(true)
  await checkChain()
  await sdk.setKeysByHash(domain, [sha3('resolver')], [addr])
  await delay(2000)
}

// 设置 record
export async function setRecord (domain: string, data: Common.DomainRecord): Promise<boolean> {
  console.log(data)
  const hasModify = (value: string) => value !== '--no modify--'
  const recordFilter = (item: Common.RecordValue) => hasModify(item.value)

  const textRecord: Common.RecordValue[] = [
    { key: 'text.email', value: data.textRecord.email },
    { key: 'text.notice', value: data.textRecord.notice },
    { key: 'text.com.twitter', value: data.textRecord.twitter },
    { key: 'text.com.github', value: data.textRecord.github },
    { key: 'text.url', value: data.textRecord.url },
    { key: 'text.avatar', value: data.textRecord.avatar }
  ].filter(recordFilter)

  const address: Common.RecordValue[] = [
    { key: 'DOT', value: data.address.dot },
    { key: 'KSM', value: data.address.ksm },
    { key: 'ETH', value: data.address.eth },
    { key: 'BTC', value: data.address.btc }
  ].filter(recordFilter)

  let content = data.content.ipfs
  let cname = data.cname.cname

  if (textRecord.length === 0 && address.length === 0 && !hasModify(content) && !hasModify(cname)) {
    return false
  }

  if (!hasModify(content)) {
    /* eslint-disable-next-line */
    content = undefined as any
  }

  if (!hasModify(cname)) {
    cname = undefined as any
  }

  const keys = []
  const value = []
  textRecord.map(item => {
    if (!verifyAddress[item.key](item.value)) {
      throw new Error('Invalid ' + item.key.replace('text.', ''))
    }
    keys.push(item.key)
    value.push(item.value)
  })

  address.map(item => {
    if (!verifyAddress[item.key](item.value)) {
      throw new Error('Invalid address:' + item.key)
    }
    keys.push(item.key)
    value.push(item.value)
  })

  if (content) {
    keys.push('contenthash')
    value.push(content)
  }

  if (cname) {
    if (verifyAddress.cname(cname)) {
      keys.push('cname')
      value.push(cname)
    } else {
      throw new Error('Invalid address:cname')
    }
  }

  console.log('edited keys:', keys)
  console.log('edited value:', value)
  console.log('target domain', domain)
  await ensureSetup(true)
  await checkChain()
  const keysHash = keys.map(key => {
    return sha3(key)
  })
  const res = await sdk.setKeysByHash(domain, keysHash, value)
  res.wait()
  await waitConfirmations((res as any).hash)
  return true
}

// 创建子域名
export async function createSubdomain (domain: string, sub: string, target: string): Promise<void> {
  await ensureSetup(true)
  await checkChain()
  const res = await sdk.mintSubdomain(target, domain, sub)
  res.wait()
  await waitConfirmations((res as any).hash)
  await delay(5000)
}

// 反向解释
export async function setName (domain: string): Promise<void> {
  await ensureSetup(true)
  await checkChain()
  const account = await wallet.accountId
  const res = await sdk.setName(account, domain)
  await waitConfirmations((res as any).hash)
}

// 批量删除
export async function batchBurn (domains: string[]): Promise<void> {
  await ensureSetup(true)
  await checkChain()
  const res = await sdk.batchBurn(domains)
  // await waitConfirmations((res as any).hash)
  const calls: any[] = res.map((item: any) => {
    return item ? waitConfirmations(item.hash as string) : () => Promise.resolve()
  })

  await Promise.all(calls)
}

// 批量mint
export async function batchMintSubdomain (domain: string, subdomains: {label:string, owner: string}[]): Promise<void> {
  await ensureSetup(true)
  await checkChain()
  const res = await sdk.batchMintSubdomain(domain, subdomains)
  // await waitConfirmations((res as any).hash)

  const calls: any[] = res.map((item: any) => {
    return item ? waitConfirmations(item.hash as string) : () => Promise.resolve()
  })

  await Promise.all(calls)
}

// 绑定nft
export async function setNft (domain: string, type: string, chainId: number, contract: string, tokenId: string): Promise<void> {
  await ensureSetup(true)
  await checkChain()
  const value = [`${type}:${chainId}:${contract}:${tokenId}`]
  const keysHash = [sha3('nft')]
  const res = await sdk.setKeysByHash(domain, keysHash, value)
  await waitConfirmations((res as any).hash)
}
