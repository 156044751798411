
import { copyText } from '@/utils/content'
import { Vue, Component, Prop } from 'vue-property-decorator'
import validPic from './assets/eth_valid.svg'
import { errorMessage, successMessage } from '@/utils/message'
import { wallet } from '@/utils/wallet'
import verifyInfo, { verifyEth, getVerifyInfo } from '@/utils/useVerify'
import { lang } from '@/lang'

@Component({})
export default class ClipboardInputEth extends Vue {
  @Prop(String) private value!: string
  @Prop(String) private placeholder!: string
  @Prop(Boolean) private readonly!: boolean
  @Prop(Boolean) private textarea!: boolean
  @Prop(Boolean) private copiable!: boolean
  @Prop(String) private link!: string
  @Prop({ type: [Boolean, Object], default: true })
  private autosize!: unknown

  private get text () {
    return lang('VALID_BTN')
  }

  private get validPic () {
    return validPic
  }

  private get computedValue () {
    return this.value || ''
  }

  private set computedValue (value) {
    this.$emit('input', value)
  }

  private handlerCopy () {
    if (this.value) {
      copyText(this.value)
    }
  }

  private loading = false

  private get walletAddress () {
    return wallet.accountId
  }

  private get alwaysShowVerifyButton () {
    return this.$route.query.chat && this.$route.query.userid
  }

  private get isValid () {
    if (!verifyInfo.eth) return false
    if (!this.computedValue) return false

    return this.computedValue.toLowerCase() === verifyInfo.eth.wallet.toLowerCase()
  }

  private async valid () {
    this.loading = true
    try {
      // 判断是否链接钱包，没登陆填出链接钱包提示框
      if (!this.walletAddress || this.walletAddress === '') {
        await wallet.connect()
      }

      if (this.walletAddress.toLowerCase() !== this.computedValue.toLowerCase()) {
        errorMessage('The input address does not match the currently connected address.')
        return
      }

      const msgParams = {
        types: {
          EIP712Domain: [],
          Data: [
            { name: 'Message', type: 'string' }
          ]
        },
        domain: {},
        primaryType: 'Data',
        message: {
          Message: 'I\'m verifying my ETH address'
        }
      }

      // eth 签名
      const signEth = await wallet.provider.request({
        method: 'eth_signTypedData_v3',
        params: [this.walletAddress, JSON.stringify(msgParams)]
      })

      const chat = this.$route.query.chat as string | undefined
      const userId = this.$route.query.userid as string | undefined

      const valid = await verifyEth(
        this.$route.params.domain,
        this.walletAddress,
        signEth,
        userId,
        chat
      )

      if (valid.result === 'ok') {
        successMessage('verify success')
        await getVerifyInfo(this.$route.params.domain)
      } else if (valid.result === 'error') {
        if (valid.message) {
          errorMessage(valid.message)
        }
      } else {
        console.error('verify fail')
      }
    } catch (e) {
      console.error(e)
      errorMessage(e.message)
    } finally {
      this.loading = false
    }
  }
}
