import Vue from 'vue'
import { EventBus, eventBus } from './event'
import { ensureSetup } from '@/sdk/base'
import { ethers } from 'ethers'
import { getNetworkByChainId, getDefaultChain } from '@/config/networks'
import { wallets } from '@/components/ConnectWalletDialog/wallets'
import { burnWalletAuth, getWalletAuth } from '@/service/auth'

export class Wallet {
  private $data!: Common.WalletData

  constructor () {
    // default chain is moonbeam 0x504
    const defaultChain = getDefaultChain()
    const defaultRpc = getNetworkByChainId(defaultChain)?.rpcUrls[0]
    this.$data = Vue.observable({
      accountId: '',
      provider: null,
      currChain: defaultChain,
      defaultProvider: new ethers.providers.StaticJsonRpcProvider(defaultRpc)
    })
  }

  get accountId (): string {
    return this.$data.accountId
  }

  set accountId (val: string) {
    this.$data.accountId = val
  }

  get provider (): any {
    return this.$data.provider
  }

  set provider (val: any) {
    this.$data.provider = val
  }

  get defaultProvider (): any {
    return this.$data.defaultProvider
  }

  set defaultProvider (val: any) {
    this.$data.defaultProvider = val
  }

  get currChain (): string {
    return this.$data.currChain
  }

  set currChain (val: string) {
    this.$data.currChain = val
  }

  async getChainId () : Promise<string> {
    if (this.provider) {
      const chainId = await this.provider.request({ method: 'eth_chainId' })
      return '0x' + chainId.toString(16).replace('0x', '')
    }

    return wallet.currChain
  }

  async switchChain (networkItem: Common.NetworkItem, account?: string): Promise<void> {
    if (!this.provider) {
      await this.connect(false, false)
    }

    await wallet.provider.request({
      method: 'wallet_addEthereumChain',
      params: [networkItem, account]
    })

    const chainId = await wallet.getChainId()
    if (networkItem.chainId !== chainId) {
      throw new Error('Switch network fail')
    }

    this.currChain = chainId
  }

  async switchDefaultChain (networkItem: Common.NetworkItem): Promise<void> {
    this.currChain = networkItem.chainId.toString()
    wallet.defaultProvider = new ethers.providers.StaticJsonRpcProvider(getNetworkByChainId(this.currChain)?.rpcUrls[0])
  }

  async autoConnect () {
    const loginType = localStorage.getItem('lastLoginType')
    if (!loginType) return
    let newLoginAddress = ''
    if (loginType === 'MetaMask') {
      newLoginAddress = await wallets[0].onClick(false)
      const history = getWalletAuth(newLoginAddress)
      if (history) {
        wallet.accountId = newLoginAddress
        eventBus.emit(EventBus.Types.DOMAIN_BASEINFO_REFETCH)
      }
    } else if (loginType === 'WalletConnect') {
      newLoginAddress = await wallets[1].onClick(false)
      const history = getWalletAuth(newLoginAddress)
      if (history) {
        wallet.accountId = newLoginAddress
        eventBus.emit(EventBus.Types.DOMAIN_BASEINFO_REFETCH)
      }
    }
  }

  async connect (slice = false, refetch = true): Promise<void> {
    return new Promise((resolve, reject) => {
      const handlerSuccess = () => {
        if (refetch) {
          eventBus.emit(EventBus.Types.DOMAIN_BASEINFO_REFETCH)
        }

        resolve()
      }

      const handlerFail = (e: any) => {
        console.log(e)
        reject(e)
      }

      const uninstall = () => {
        eventBus.off(EventBus.Types.WALLET_CONNECT_SUCCEED, handlerSuccess)
      }

      eventBus.emit(EventBus.Types.CONNECT_WALLET_DIALOG_OPEN)

      eventBus.once(EventBus.Types.WALLET_CONNECT_SUCCEED, handlerSuccess)

      eventBus.once(EventBus.Types.WALLET_CONNECT_FAIL, handlerFail)

      eventBus.once(EventBus.Types.CONNECT_WALLET_DIALOG_CLOSED, uninstall)
    })
  }

  async disConnect () {
    if (!wallet.provider) return

    await ensureSetup()
    if (wallet.provider && wallet.provider.disconnect) {
      await wallet.provider.disconnect()
    }

    localStorage.removeItem('lastLoginType')
    burnWalletAuth(wallet.accountId)
    wallet.accountId = ''
    wallet.provider = null
  }
}

export const wallet = new Wallet()
