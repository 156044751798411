
import { Vue, Component } from 'vue-property-decorator'
import WrapperAside from '@/views/Wrapper/WrapperAside.vue'

@Component({
  components: {
    WrapperAside
  }
})
export default class MobileNavBar extends Vue {
  private visible = false

  open (): void {
    this.visible = true
  }
}
