
import { lang } from '@/lang'
import { EventBus } from '@/utils/event'
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class SearchInput extends Vue {
  private keyword = ''

  private get placeholder () {
    return lang('SEARCHINPUT_PLACEHOLDER')
  }

  private handlerConfirm () {
    if (this.keyword === this.$route.query.keyword) return

    if (this.keyword) {
      this.$router.push({
        name: 'search',
        query: {
          keyword: this.keyword
        }
      })
    }
    this.$eventBus.emit(EventBus.Types.HEADER_SEARCH_KEYWORD_CHANGE, this.keyword)
    this.keyword = ''
  }
}
