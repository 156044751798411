
import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import QRCode from 'qrcode'

@Component({})
export default class QRCodeItem extends Vue {
  @Prop(String) private val!: string

  @Ref() private qrcodeCanvas!: HTMLCanvasElement

  async mounted (): Promise<void> {
    await this.$nextTick()
    QRCode.toCanvas(
      this.qrcodeCanvas,
      this.val,
      {
        width: 200,
        margin: 0
      },
      function (error: any) {
        if (error) console.error('[app-qrcode]:' + JSON.stringify(error))
      })
  }
}
