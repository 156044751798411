import { toChecksumAddress } from 'web3-utils'

export function setWalletAuth (address: string, authToken: string) {
  address = toChecksumAddress(address)
  const authStorage = window.localStorage.getItem('wa') || '' // wallet auth

  if (!authStorage) {
    window.localStorage.setItem('wa', JSON.stringify([[address, authToken]]))
    return
  }

  try {
    const jsonStorage: [string, string][] = JSON.parse(authStorage)
    const filterStorage = jsonStorage.filter((item) => {
      return address !== item[0]
    })

    const newStorage = [[address, authToken], ...filterStorage]
    window.localStorage.setItem('wa', JSON.stringify(newStorage))
  } catch (e) {
    window.localStorage.setItem('wa', JSON.stringify([[address, authToken]]))
  }
}

export function getWalletAuth (address: string) {
  address = toChecksumAddress(address)
  const authStorage = window.localStorage.getItem('wa') || ''
  if (!authStorage) {
    return null
  }

  try {
    const jsonStorage: [string, string][] = JSON.parse(authStorage)
    const target = jsonStorage.find((item) => {
      return address === item[0]
    })
    return target || null
  } catch (e) {
    return null
  }
}

export function burnWalletAuth (address: string) {
  if (!address) {
    // burn all history
    window.localStorage.removeItem('wa')
    return
  }

  try {
    address = toChecksumAddress(address)
  } catch (e) {
    return
  }

  const authStorage = window.localStorage.getItem('wa') || ''
  if (!authStorage) return

  try {
    const jsonStorage: [string, string][] = JSON.parse(authStorage)
    const filterStorage = jsonStorage.filter((item) => {
      return address !== item[0]
    })

    window.localStorage.setItem('wa', JSON.stringify(filterStorage))
  } catch (e) {
    // burn all history
    window.localStorage.removeItem('wa')
  }
}
