import axios, { AxiosStatic } from 'axios'
import { wallet } from '@/utils/wallet'
import api from '@/config/api'
import { getWalletAuth } from '@/service/auth'

class Cache {
  store!: Map<string, { time: number, data: any }>

  constructor () {
    this.store = new Map()
  }

  get (key: string) {
    const history = this.store.get(key)
    if (!history) return null

    const now = Date.parse(new Date().toString())
    if (now - history.time > 2000) return null
    return history.data
  }

  set (key: string, value: any) {
    this.store.set(key, {
      time: Date.parse(new Date().toString()),
      data: value
    })
  }
}

class Fetch {
  private axios: AxiosStatic
  private cache: Cache

  constructor () {
    this.axios = axios
    this.cache = new Cache()
  }

  private async getAuth (): Promise<string> {
    const loginId = wallet.accountId
    const isLogin = wallet.accountId && wallet.accountId !== ''

    if (isLogin) {
      const auth = getWalletAuth(loginId as string)
      if (auth) {
        return auth[1]
      } else {
        // await wallet.connect()
        throw new Error('please connect wallet to continue')
      }
    } else {
      // await wallet.connect()
      throw new Error('please connect wallet to continue')
    }
  }

  fetch (fetchFunction: (url: string, data: any, config?: any) => any, url: string, data: any, config?: any) {
    let tryTimes = 1
    let res
    console.log('[fetch]:data', data)
    const execute: any = async () => {
      console.log(`[fetch]:${url}, try times: ${tryTimes}`)
      try {
        res = await fetchFunction(url, data, config)
        return res
      } catch (e) {
        if (tryTimes === 3) {
          console.log('[fetch]: fetch error')
          console.log(data)
          throw e
        }
        tryTimes++
        await execute()
      }
    }

    return execute()
  }

  async post (url: string, data: Record<string, any> = {}, auth = true, opt?: any): Promise<any> {
    if (auth) {
      const authToken = await this.getAuth()
      data = { ...data, auth_token: authToken }
    }

    // return await this.axios.post(url, data)
    return await this.fetch(this.axios.post, url, data, opt)
  }

  async get (url: string, data: Record<string, string> = {}): Promise<any> {
    const key = url + JSON.stringify(data)
    const cache = this.cache.get(key)
    if (cache) {
      console.log('[fetch]: use cache', url)
      return cache
    }

    const res = await this.fetch(this.axios.get, url, { params: data })
    this.cache.set(key, res)
    return res
  }

  async upload (file: File, data: Record<string, string> = {}): Promise<any> {
    const authToken = await this.getAuth()
    const formData = new FormData()
    formData.append('data', file)
    for (const key in data) {
      formData.append(key, data[key])
    }
    formData.append('auth_token', authToken)
    // return await this.axios.post(`${api}/upload/image`, formData, {
    //   headers: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // })
    return await this.fetch(this.axios.post, `${api}/upload/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      transformRequest: []
    })
  }
}

const fetch = new Fetch()
export default fetch
