export function isDev (): boolean {
  return process.env.NODE_ENV === 'development'
}

export function isBanFeature (): boolean {
  return false
}

export function delay (ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms)
  })
}

export function padStart (num: number, s = '0', ln = 2): string {
  let str = String(num)
  while (str.length < ln) {
    str = `${s}${str}`
  }
  return str
}

export function dateFormat (date: Date): string {
  const year = padStart(date.getFullYear(), '0', 4)
  const month = padStart(date.getMonth() + 1)
  const day = padStart(date.getDate())
  const hour = padStart(date.getHours())
  const min = padStart(date.getMinutes())
  return `${year}.${month}.${day} at ${hour}:${min} (UTC+08:00)`
}
